import React from 'react';
import './AdminAdoptionModal.css';

// Modal 컴포넌트
function AdminAdoptionModal({ isVisible, onClose, data  }) {
  if (!isVisible) return null;

  const keyTranslations = {
    carerName: '신청자 이름',
    carerBirth: '생년월일',
    isAdult: '성인인가?',
    carerAddress: '신주소',
    carerHousingType: '주거 형태',
    carerHouseWidth: '주거 면적',
    isMarried: '결혼 여부',
    carerPhone: '전화번호',
    carerEmail: '이메일',
    carerSnsAddress: 'SNS 주소',
    carerSns: 'SNS',
    carerJob: '직업',
    carerEarnings: '수입',
    carerFamilyMember: '가족 구성원',
    reasonForAdoption: '입양 사유',
    carerComment: '추가 코멘트',
    agreeForFilming: '촬영 동의',
    agreeToTermsOfUse: '이용약관 동의',
    agreePersonalInformation: '개인정보 동의',
    animalName: '동물 이름',
    imageUrl: '이미지',
    animalNumber: '동물 번호',
  
    animalid: '동물 ID',
    animaladptionstatus: '입양 상태',
    breeds: '품종',
    gender: '성별',
    age: '나이',
    isNeutered: '중성화 여부',
    healthInfo: '건강 정보',
    addtionInfo: '추가 정보',
    foundlocation: '발견 장소',
    weight: '체중',
    animalNm: '동물 이름',
    animalType: '대분류',
    birthDate: '생년월일'
  };

  const renderValue = (key, value) => {
    if (key === 'imageUrl' || key === 'imageurl') {
      return <img src={value} alt="이미지" style={{Width: '100%', height: '20%', maxHeight:"300px", maxWidth:"200px"}} />;
    }
    return value;
  };

  return (
    <div className="admin-adoption-modal-overlay" onClick={onClose}>
      <div className="admin-adoption-modal-content" onClick={e => e.stopPropagation()}>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <strong>{keyTranslations[key] || key}: </strong>
            {renderValue(key, value)}
          </div>
        ))}
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
}

export default AdminAdoptionModal;