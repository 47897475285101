import React from "react";
import TalentSlider from "./TalentSlider";
import Objective from "../Objective/objective";
import { objectivedummydata } from "../Objective/objectivedummydata";
import "./TalentSlider.css";

const TalentComponents = () => {
  return (
    <>
      <TalentSlider />
      <div style={{ marginTop: 0, backgroundColor: "#ffffff" }}>
        <Objective data={objectivedummydata} />
      </div>
    </>
  );
};

export default TalentComponents;
