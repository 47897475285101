import React, { useState } from "react";
import "./AdoptionInputComponent.css";
import AdoptionNameField from "./AdoptionField/AdoptionNameField";
import AdoptionBirthDateField from "./AdoptionField/AdoptionBirthDateField";
import AdoptionAddressField from "./AdoptionField/AdoptionAddressField";
import AdoptionIsMarried from "./AdoptionField/AdoptionIsMarriedField";
import AdoptionPhoneField from "./AdoptionField/AdoptionPhoneField";
import AdoptionEmailField from "./AdoptionField/AdoptionEmailField";
import AdoptionSnsField from "./AdoptionField/AdoptionSnsField";
import AdoptionJobField from "./AdoptionField/AdoptionJobField";
import AdoptionCarerEarningField from "./AdoptionField/AdoptionCarerEarningField";
import AdoptionCarerFamilyMemberField from "./AdoptionField/AdoptionCarerFamilyMemberField";
import AdoptionCarerHousingTypeField from "./AdoptionField/AdoptionCarerHousingTypeField";
import AdoptionCarerHouseWidthField from "./AdoptionField/AdoptionCarerHouseWidthField";
import AdoptionCarerHouseImageField from "./AdoptionField/AdoptionCarerHouseImageField";
import AdoptionReasonForAdoptionField from "./AdoptionField/AdoptionReasonForAdoptionField";
import AdoptionCarerCommentField from "./AdoptionField/AdoptionCarerCommentField";
import AdoptionConsentsComponent from "./AdoptionConsentsComponent";
import AdoptionUseFormValidation from "./AdoptionUseFormValidation";

const AdoptionInputComponent = ({ onPrev, onNext, selectedAnimalNumber }) => {
  const [adoptionData, setAdoptionData] = useState({
    carerName: "",
    isAdult: null,
    carerBirth: "",
    carerAddress: "",
    carerHousingType: "다가구",
    carerHouseWidth: "",
    isMarried: "기혼",
    carerPhone: "",
    carerEmail: "",
    carerSnsAddress: "",
    carerSns: "",
    carerJob: "",
    carerEarnings: "",
    carerFamilyMember: "",
    reasonForAdoption: "",
    carerComment: "",
    agreeForFilming: null,
    agreeToTermsOfUse: null,
    agreePersonalInformation: null,
    animalNumber:
      selectedAnimalNumber || "67c3e917-fae8-4d44-b0c2-35b24b56dc31",
  });
  const [carerHouseImages, setCarerHouseImages] = useState([]);

  // 유효성 검사 훅 사용
  const { validateFields, missingFields, isConsentChecked } =
    AdoptionUseFormValidation(adoptionData, carerHouseImages);

  const handleNext = () => {
    let alertMessage = "";

    // 1. 입력 필드 값 확인
    if (!validateFields) {
      if (missingFields.length > 0) {
        alertMessage = `${missingFields.join(", ")} 필드를 입력해주세요.`;
        alert(alertMessage); // 비어있는 필드를 알려주는 alert
      } else if (!isConsentChecked) {
        alert("모든 동의 항목을 동의해주세요.");
      }
      return; // 유효성 검사가 실패하면 종료
    }

    // 2. 모든 조건이 만족되면 부모로 formData를 전달
    const formDataToSend = new FormData();
    // JSON 데이터를 Blob으로 추가
    formDataToSend.append(
      "adoption",
      new Blob([JSON.stringify(adoptionData)], { type: "application/json" })
    );

    // 이미지 파일 추가
    if (carerHouseImages && carerHouseImages.length > 0) {
      Array.from(carerHouseImages).forEach((file) => {
        formDataToSend.append("carerHouseImage", file); // 'carerHouseImage'는 서버에서 받는 이름과 동일해야 함
      });
    }
    onNext(formDataToSend);
  };

  return (
    <div className="adoption-input-container">
      <hr />
      <div className="adoption-form-container">
        <h1>보호자 정보</h1>
        <form>
          {/* MARK: 이름 */}
          <AdoptionNameField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 생년월일 */}
          <AdoptionBirthDateField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 주소 */}
          <AdoptionAddressField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 주거 형태 */}
          <AdoptionCarerHousingTypeField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 평수 */}
          <AdoptionCarerHouseWidthField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 결혼 여부 */}
          <AdoptionIsMarried
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 연락처 */}
          <AdoptionPhoneField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 이메일 */}
          <AdoptionEmailField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: SNS */}
          <AdoptionSnsField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 직업 */}
          <AdoptionJobField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 주 보호자 소득 */}
          <AdoptionCarerEarningField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 가족구성원 */}
          <AdoptionCarerFamilyMemberField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/* MARK: 집안 내부 사진 */}
          <AdoptionCarerHouseImageField
            carerHouseImages={carerHouseImages}
            setCarerHouseImages={setCarerHouseImages}
          />

          {/* MARK: 입양이유 */}
          <AdoptionReasonForAdoptionField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />

          {/*MARK: 그 외 하실 말씀 */}
          <AdoptionCarerCommentField
            formData={adoptionData}
            setFormData={setAdoptionData}
          />
        </form>
        <br />
        <br />
      </div>
      <AdoptionConsentsComponent
        formData={adoptionData}
        setFormData={setAdoptionData}
      />
      <div className="adoption-button-container">
        <button className="button close" onClick={onPrev}>
          이전
        </button>
        <button className="button submit" onClick={handleNext}>
          제출하기
        </button>
      </div>
    </div>
  );
};

export default AdoptionInputComponent;
