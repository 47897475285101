const companies = [
  { id: 1, name: "페스룸", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/pethroom.png" },
  { id: 2, name: "로얄캐닌", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/royalcanin.png" },
  { id: 3, name: "닥터독", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/dr-dog.png" },
  { id: 4, name: "개선장군", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/generaldog.png" },
  { id: 5, name: "아미오", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/amio.png" },
  { id: 6, name: "페스룸", donation: "반려동물 용품 기부", date: "2024년 1월", logo: "/logos/companys/pethroom.png" },
];

const CompanySponsorshipCompanys = () => {
  return (
    <section className="company-sponsorship">
      <ul className="company-sponsorship__grid">
        <header className="company-sponsorship__header">
          <h2 className="company-sponsorship__title">동물들에게 새로운 기회를 제공하는 기업의 나눔</h2>
          <p className="company-sponsorship__subtitle">감사합니다</p>
        </header>
        {companies.map((company) => (
          <li key={company.id} className="company-card">
            <div className="company-card__logo-container">
              <img src={company.logo} alt={`${company.name} 로고`} className="company-card__logo" />
            </div>
            <div className="company-card__content-container">
              <div className="company-card__content">
                <h3 className="company-card__name">
                  {company.name}에서
                  <br /> {company.donation}
                </h3>
                <p className="company-card__date">
                  기부일자 <span className="p-divide__line"></span> {company.date}
                </p>
                <p className="company-card__message">
                  기업에서 아이들의 꿈을
                  <br /> 후원해주셨습니다.
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default CompanySponsorshipCompanys;
