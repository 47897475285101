export const menuItems = [
  {
    name: "소개",
    path: "/about/intro",
    subItems: [
      { name: "포캣멍센터", path: "/about/intro" },
      { name: "조직도", path: "/about/organization" },
    ],
  },
  {
    name: "후원",
    path: "/support",
    subItems: [
      { name: "후원하기", path: "/support" },
      { name: "1:1후원", path: "/onetoone/list" },
      { name: "1:N후원", path: "/onetomanypage/list" },
    ],
  },
  {
    name: "맞춤후원",
    path: "#",
    subItems: [
      { name: "스타후원", path: "/customdonation/stardonation" },
      { name: "기업후원", path: "company-sponsorship" },
      { name: "유산기부", path: "heritage-support" },
    ],
  },
  {
    name: "참여",
    path: "#",
    subItems: [
      { name: "입양하기", path: "/AdoptionPage" },
      { name: "구조/학대제보", path: "/participate/askforhelp" },
      { name: "재능기부", path: "/TalentPage" },
      { name: "봉사활동", path: "/volunteering/beauty" },
    ],
  },
  {
    name: "소식",
    path: "#",
    subItems: [
      { name: "후원내역", path: "/uselist" },
      { name: "센터소식", path: "/news" },
      { name: "공지사항", path: "/announce/list" },
      { name: "FAQ", path: "/faq" },
      { name: "질문하기", path: "/question" },
    ],
  },
];
