import React, {useCallback, useEffect, useState} from 'react';
import "./AdminAdoption.css"
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AdminVolunteeringList = () => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [volunteeringPk, setVolunteeringPk] = useState("");
  const [kind, setKind] = useState("");
  const [title, setTitle] = useState("");
  const itemsPerPage = 18;
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate(-1); 
  };

  useEffect(() => {
    if (location.state?.item) {
      const item = location.state.item;
      setKind(item.kind || "");
      setTitle(item.title || "");
      setVolunteeringPk(item.volunteeringPk || "");
    }
  }, [location.state]);

  const fetchList = useCallback(async () => {
    if (!volunteeringPk) return;

    try {
      const response = await axios.get(
        `https://forcatmung.kr/api/volunteering/register/list?volunteeringId=${volunteeringPk}&page=${
          currentPage - 1
        }&size=${itemsPerPage}`
      );
      const {
        currentPage: apiCurrentPage,
        totalPages,
        registerList ,
      } = response.data;
      setLists(registerList);
      setCurrentPage(apiCurrentPage + 1);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching news:", error);
      setError("문의사항을 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage, volunteeringPk]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const kindMap = {
    'beauty': '미용',
    'training': '훈련',
    'walk': '산책',
    'cleaning': '청소',
    'relocation': '이동',
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={currentPage === number ? 'active' : ''}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  if (loading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <div className='admin-button-container'>
      <button onClick={handleGoBack} className="admin-regist-button">이전 페이지로</button>
        <div className="admin-adoption-table-container">
          <h2 className='admin-adoption-table'>봉사활동(종류 : {kindMap[kind]} & 제목 : {title} ) 신청인원</h2>
          <div style={{marginRight:"5%"}}>
            <div style={{borderTop: '1px solid #ccc'}}></div>
            {lists.length > 0 ? (
              lists.map((item) => (
                <div key={item.volunteeringPk} className="admin-adoption-table-row" >
                  <span className="admin-list-table-cell">이름 : {item.name}</span>
                  <span className="admin-list-table-cell">전화번호 : {item.phone}</span>
                  <span className="admin-list-table-cell">이메일 : {item.email} </span>
                  <span className="admin-list-table-cell">선택일 :  {item.selectedDate}</span>
                  <span className="admin-list-table-cell">하고싶은 말 : {item.notes} </span>
                </div>
              ))
            ) : (
              <div className="no-results">신청한 인원이 없습니다.</div>
            )}
          </div>
          {renderPagination()}
        </div>
      </div>
    </>
  )
}

export default AdminVolunteeringList;