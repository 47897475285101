import axios from "axios";

const BASE_URL = "https://forcatmung.kr/";

const getFaqDetailById = async (faqid) => {
  try {
    const response = await axios.get(`${BASE_URL}api/faq/detail?faqid=${faqid}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching animal details:", error);
    throw new Error("FAQ를 불러오는 데 실패했습니다.");
  }
};

const updateFaq = async (faqid, updatedData) => {
  try {
    const response = await axios.put(`${BASE_URL}api/faq/update?faqid=${faqid}`, updatedData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating FAQ:", error.response?.data || error.message);
    throw new Error("FAQ 업데이트에 실패했습니다.");
  }
};

export { getFaqDetailById, updateFaq };
