// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-donationterm-button-container {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
}

.admin-donationterm-button {
    width: 20%;
    padding: 5px 10px;
    font-size: 1.2rem;
}

.admin-donationterm-content-container {
    margin-top: 6vh;
}

.admin-donationterm-div {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.admin-donationterm-content-title {
    display: flex;
    flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/donation/AdminTerm.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".admin-donationterm-button-container {\n    margin-top: 5vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.admin-donationterm-button {\n    width: 20%;\n    padding: 5px 10px;\n    font-size: 1.2rem;\n}\n\n.admin-donationterm-content-container {\n    margin-top: 6vh;\n}\n\n.admin-donationterm-div {\n  display: flex;\n  justify-content: space-around;\n  width: 80%;\n}\n\n.admin-donationterm-content-title {\n    display: flex;\n    flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
