// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-detail-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000; 
}

.admin-detail-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-detail-close-button {
    background: none;
    border: none;
    font-size: 3vw;
    cursor: pointer;
}

.admin-detail-modal-header {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 20px; 
}

.admin-detail-action-button {
    background-color: #007bff; 
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px; 
    cursor: pointer;
}

.admin-detail-action-button:hover {
    background-color: #0056b3;
}

.admin-detail-title {
    margin-top: 0; 
    font-size: 2rem;
}

.admin-detail-animal-info p {
    margin: 5px 0; 
}

strong {
    color: #333; 
}

.admin-detail-image {
    width: 100%;
    max-height: 500px;
    object-fit:cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Post/AdminAniModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".admin-detail-modal {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); \n    z-index: 1000; \n}\n\n.admin-detail-modal-content {\n    background-color: white;\n    border-radius: 8px;\n    padding: 20px;\n    width: 80%;\n    max-width: 600px; \n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.admin-detail-close-button {\n    background: none;\n    border: none;\n    font-size: 3vw;\n    cursor: pointer;\n}\n\n.admin-detail-modal-header {\n    display: flex;\n    justify-content: space-between; \n    margin-bottom: 20px; \n}\n\n.admin-detail-action-button {\n    background-color: #007bff; \n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 10px 15px; \n    cursor: pointer;\n}\n\n.admin-detail-action-button:hover {\n    background-color: #0056b3;\n}\n\n.admin-detail-title {\n    margin-top: 0; \n    font-size: 2rem;\n}\n\n.admin-detail-animal-info p {\n    margin: 5px 0; \n}\n\nstrong {\n    color: #333; \n}\n\n.admin-detail-image {\n    width: 100%;\n    max-height: 500px;\n    object-fit:cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
