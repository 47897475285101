import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminAniReg.css'
import { useNavigate, useParams} from 'react-router-dom';

const AdminAnimalUpdate = () => {
  const navigate = useNavigate();
  const { animalId } = useParams(); 

  console.log(animalId)

    const [formData, setFormData] = useState({
        name: '', age: '', gender: '', majorCategory: 'dog', subCategory: '', resDate: '', resLocation: '', weight: '0.0', neutering: true,
        etc1: '', etc2: '', etc3: '', etc4: '', image: null
      });

      console.log(formData)

      const fieldLabels = {
        name: '이름',
        age: '나이',
        gender: '성별',
        majorCategory: '메인카테고리',
        subCategory: '서브카테고리',
        resDate: '구조날짜',
        resLocation: '구조장소',
        personality: '개인특징',
        needs: '건강특징',
        weight: '체중',
        neutering: '중성화여부'
      };

      const fieldLabelss = {
        etc1: '기타사항 1',
        etc2: '기타사항 2',
        etc3: '기타사항 3',
        etc4: '기타사항 4',
      };

      useEffect(() => {
        // 아이템 정보를 가져오는 API 호출
        const fetchItem = async () => {
          try {
            const response = await axios.get(`https://forcatmung.kr/api/animal/detail?animalid=${animalId}`);
            setFormData({
                name: response.data.name || '',
                age: response.data.birthDate|| '',
                gender: response.data.gender || '',
                majorCategory: response.data.majorCategory || 'dog',
                subCategory: response.data.breeds || '',
                resDate: response.data.rescueDate || '',
                resLocation: response.data.rescueLocation || '',
                personality: response.data.personality || '',
                needs: response.data.healthFeatures || '',
                weight: response.data.weight || 0.0,
                neutering: response.data.neutering || true,
                etc1: response.data.animalDetailCharc1 || '',
                etc2: response.data.animalDetailCharc2 || '',
                etc3: response.data.animalDetailCharc3 || '',
                etc4: response.data.animalDetailCharc4 || '',
                image: null,
              });
            } catch (error) {
            console.error('아이템 정보를 가져오는 데 실패했습니다:', error);
          }
        };

        fetchItem();
      }, [animalId]);

      const handleInputChangeBoolean = (e) => {
        const { name, value } = e.target;
      
        const booleanValue = value === 'true';

        // 상태 업데이트
        setFormData({ ...formData, [name]: booleanValue });
      };

    //   const handleUpdate = async () => {
    //     try {
    //       await axios.put(`https://domain/api/animal/update?animalid=${item.animalId}`, item);
    //       navigate('/mainmain/child'); 
    //     } catch (error) {
    //       console.error('수정 중 오류 발생:', error);
    //     }
    //   };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ 
          ...formData, 
          [name]: name === 'weight' ? parseFloat(value) || '' : value 
      });
  };
    
      const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const apiformData = new FormData();

        apiformData.append("imageData", formData.image);

        const jsonData = {
          name: formData.name,
          animalType: formData.majorCategory,
          gender: formData.gender,
          birthDate: formData.age,
          weight: parseFloat(formData.weight),
          breeds: formData.subCategory,
          neutered: formData.neutering,
          rescueDate : formData.resDate,
          rescueLocation : formData.resLocation,
          healthFeatures : formData.needs,
          animalDetailCharc1: formData.etc1,
          animalDetailCharc2: formData.etc2,
          animalDetailCharc3: formData.etc3,
          animalDetailCharc4: formData.etc4,
          personality: formData.personality,
        };

        apiformData.append('animal', new Blob([JSON.stringify(jsonData)], { type: "application/json" }));

        for (let [key, value] of apiformData.entries()) {
          if (value instanceof File) {
            console.log(key, ':', value.name, '(File)');
          } else if (value instanceof Blob) {
            console.log(key, ':', 'Blob data');
            // Blob 내용 읽기
            const reader = new FileReader();
            reader.onload = function(event) {
              console.log(key, 'content:', event.target.result);
            };
            reader.readAsText(value);
          } else {
            console.log(key, ':', value);
          }
        }

const animalBlob = apiformData.get('animal');
if (animalBlob instanceof Blob) {
  animalBlob.text().then(text => {
    const jsonData = JSON.parse(text);
    console.log('Animal JSON data:', jsonData);
  }).catch(error => {
    console.error('Error parsing blob:', error);
  });
}

        try {
          const response = await axios.put(`https://forcatmung.kr/api/animal/update?animalid=${animalId}`, apiformData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          });
          console.log("수정 성공", response.data);
          alert("수정되었습니다!")
          navigate(`/mainmain/child`)
        } catch (error) {
          console.error('등록 실패:', error);
        }
    };

      return (
        <div className='admin-register-container'>
        <form onSubmit={handleSubmit}>
            <h2 style={{marginBottom:"3%", fontSize:"1.5rem"}}>필수 영역</h2>
          {['name', 'age', 'gender', 'majorCategory', 'subCategory', 'resDate', 'resLocation', 'personality', 'needs', 'weight', 'neutering'].map(field => (
            <div key={field} className="required-field">
              <label htmlFor={field}>{fieldLabels[field]}:</label>
              {field === 'majorCategory' ? (
      <select
        id={field}
        name={field}
        value={formData[field] !== undefined ? formData[field] : ''}
        onChange={handleInputChange}
        required
      >
        <option value="dog">강아지</option>
        <option value="cat">고양이</option>
        <option value="etc">특수동물</option>
      </select>
    ) : field === 'neutering' ? (
      <select
        id={field}
        name={field}
        value={formData[field] === true ? 'true' : formData[field] === false ? 'false' : ''}
        onChange={handleInputChangeBoolean}
        required
      >
        <option value="true">O</option>
        <option value="false">X</option>
      </select>
    ) : field === 'weight' ? ( 
      <input
        type="number"
        id={field}
        name={field}
        value={formData[field] !== undefined ? formData[field] : ''}
        onChange={handleInputChange}
        required
      />
    ) : field === 'age' || field === 'resDate' ? ( 
      <input
        type="text"
        id={field}
        name={field}
        value={formData[field] !== undefined ? formData[field] : ''}
        onChange={handleInputChange}

        placeholder='"2025-02-19"처럼 "연도 - 월 - 일 " 형태로 입력해주세요. `-` 필수입니다'
        required
      />
    ) : (
      <input
        type="text"
        id={field}
        name={field}
        value={formData[field] !== undefined ? formData[field] : ''}
        onChange={handleInputChange}
        required
      />
    )}
  </div>
))}

            <div className='required-field'>
            <label htmlFor="image" >Image:</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              accept="image/*"
              required
            />
          </div>

        <h2 style={{marginBottom:"3%", marginTop:"3%", fontSize:"1.5rem"}}>선택 영역</h2>
          {['etc1', 'etc2', 'etc3', 'etc4'].map(field => (
            <div key={field} className="required-field">
              <label htmlFor={field}>{fieldLabelss[field]}:</label>
              <input
                type="text"
                id={field}
                name={field}
                value={formData[field] !== undefined ? formData[field] : ''}
                onChange={handleInputChange}
              />
            </div>
          ))}

          <button type="submit" className="required-button" style={{marginTop:"3%", marginBottom:"3%"}}>수정하기</button>
        </form>
        </div>
      );
    };


export default AdminAnimalUpdate;