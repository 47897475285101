import React from "react";
import AskForHelpBanner from "./AskForHelpBanner";
import AskForHelpContent from "./AskForHelpContent";
import AskForHelpSlider from "./AskForHelpSlider";
import WhatIsAnimalRescueBanner from "./WhatIsAnimalRescueBanner";
import CheckBefore from "./CheckBefore";
import "./AskForHelp.css"

function AskForHelp() {
  return (
    <div>
      <AskForHelpBanner />
      <AskForHelpContent />
      <AskForHelpSlider />
      <WhatIsAnimalRescueBanner />
      <CheckBefore />
    </div>
  );
}

export default AskForHelp;
