import React, { useState, useEffect } from 'react';
import { useParams, Link} from 'react-router-dom';
import './QuestionDetail.css';
import axios from 'axios';
import DOMPurify from "dompurify";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const QuestionDetail = () => {
  const [questionItem, setQuestionItem] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isVisible1, setIsVisible1] = useState(false); 
  const [isVisible2, setIsVisible2] = useState(false); 
  const [replyContent, setReplyContent] = useState("");
  const [replyUpdateContent, setReplyUpdateContent] = useState("");
  const [answerList, setAnswerList] = useState([]);
  const currentDate = new Date().toISOString()

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/inquiry/detail?questionId=${id}`
        );
        setQuestionItem(response.data);
        setAnswerList(response.data.answer)
      } catch (error) {
        console.error("문의사항을 가져오는 중 오류가 발생했습니다:", error);
      }
    };

    fetchNews();
  }, [id]);

  if (!questionItem) {
    return <div>해당 문의글을 찾을 수 없습니다.</div>;
  }

    const handleDelete = async () => {
      try {
        if (window.confirm("삭제하시겠습니까?")) {
          await axios.delete(
            `https://forcatmung.kr/api/inquiry/delete?questionId=${id}`
          );
          alert("삭제되었습니다.");
          navigate("/question/list");
        }
      } catch (error) {
        console.error("삭제 실패하였습니다.", error);
        alert("삭제 중 오류가 발생하였습니다.");
      }
    };

  //답글
  const handleButtonClick1 = () => {
    setIsVisible1(!isVisible1);
  };

  const handleButtonClick2 = () => {
    setIsVisible2(!isVisible2);
  };

  const handleSubmit = async () => {
    if (!replyContent.trim()) {
      alert("답글 내용을 입력해주세요.");
      return;
    }
    if (window.confirm("답변하시겠습니까?")) {
  
      const response = await axios.post("https://forcatmung.kr/api/inquiry/answer/create",
        {
          managerName : "관리자",
          answerContent : replyContent,
          userPk : questionItem.userPk 
        }, 
        {
          params: {
            questionId : id
          }
        }
      );
    alert("답변이 제출되었습니다.");
    console.log("답변이 제출되었습니다.", response.data)
    setReplyContent("");
    setIsVisible1(false);
  };
}

const handleAnswerEdit = async (id) => {
  if (!replyUpdateContent.trim()) {
    alert("답글 수정내용을 입력해주세요.");
    return;
  }
    if (window.confirm("답변을 수정하시겠습니까?")) {
      const response = await axios.put("https://forcatmung.kr/api/inquiry/answer/update",
        {
          managerName : "관리자",
          answerContent : replyUpdateContent,
          userPk : questionItem.userPk,
        }, 
        {
          params: {
            commentid : id
          }
        }
      );
    alert("답변이 제출되었습니다.");
    console.log("답변이 제출되었습니다.", response.data)
    setReplyContent("");
    setIsVisible1(false);
    
    }
  }


const handleAnswerDelete= async (id) => {
  console.log(`Deleting answer with id: ${id}`);

  if (window.confirm("삭제하시겠습니까?")) {
    await axios.delete(`https://forcatmung.kr/api/inquiry/answer/delete?commentid=${id}`)
    alert("답변이 삭제되었습니다.");
  }
}


  return (
      <div className="question-post-container">
        <div className="question-post-section">
          <h1 className="question-primary-title">문의하기</h1>
          <Link to="/question/list" className='question-action-button'>목록으로</Link>
        </div>
{/* 
      <div className="question-button-div">
        <Link
          to={`/question/write/${id}`}
          className="question-action-button"
        >
          수정
        </Link>         
        <button
          className="question-action-button"
          onClick={handleDelete}
        >
          삭제
        </button> 

        <button className="question-action-button" onClick={handleButtonClick1}>
        {isVisible1 ? "닫기" : "답글"}
        </button>       
        </div>    */}

        <div className="question-title-section">
        <h2 className="question-post-title">{questionItem.title}</h2>
      </div>

      <div className="question-meta-section">
        <span className="question-author">
          <span className="question-stress-word">작성자 : </span>
          <span className="question-author-text">{questionItem.nickname}</span>
        </span>
        <span className="question-date">
          <span className="question-stress-word">작성일 : </span>
          <span className="question-date-text">{questionItem.writeDay}</span>
        </span>
      </div>

      <div
        className="question-content-section"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(questionItem.questionContent),
        }} 
        /> 

      {isVisible1 && (
        <div style={{ marginTop: "10px" }}>
          <textarea
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder="답글 내용을 입력하세요."
           className='question-answer-container'
          />
          <button
            onClick={handleSubmit}
            className='question-answer-button'
          >
            제출
          </button>
          </div>
      )}

{answerList && answerList.length > 0 ? (
  answerList.map((answer, index) => (
  <div key={index} className='question-answer-section'>
    {/* <button onClick={handleButtonClick2}> {isVisible2 ? "수정취소" : "답변수정"}</button> <button onClick={() => handleAnswerDelete(answer.answerId)}>답변 삭제</button> */}
    
    {isVisible2 && (
        <div style={{ marginTop: "10px" }}>
          <textarea
            value={replyUpdateContent}
            onChange={(e) => setReplyUpdateContent(e.target.value)}
            placeholder="답글 내용을 입력하세요."
           className='question-answer-container'
          />
          <button
            onClick={() => handleAnswerEdit(answer.answerId)}
            className='question-answer-button'
          >
            제출
          </button>
          </div>
      )}
    <div className='question-answer-title'>
    <span className="question-answer-author">
      <span className='question-stress-word'>답변자 : </span>
      <span className='question-author-text'>{answer.managerName || '관리자'}</span>
      </span>
      <span className="question-date">
        <span className="question-stress-word" style={{marginLeft:"5px"}}>답변일 : </span>
        <span className="question-date-text">{dayjs(answer.answerDate).format('YYYY-MM-DD')}</span>
      </span>
    </div>

        <div className='question-answer-content'>
          <span className='question-answer-content-title'>답변 내용</span> <br />
          <span className='question-answer-content-content'>{answer.answerContent}</span>
        </div>
  </div>
    ))
  ) : (
    <div className='question-answer-section'>
    </div>
  )}
    </div>
  );
};

export default QuestionDetail;
