import "./News.css";
import NewsPrimaryContent from "./NewsPrimaryContent";
// import { Link } from "react-router-dom";

const NewsPrimary = ( ) => {

    const newsData = [
        { imageUrl: "/images/newsPrimary1.png", title: "만약제목이길어진다면어떻게보이냐면", date: "2024.11.11", description: "간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단  설명1간단 설명1간단 설명1간단 설명1간"  },
        { imageUrl: "/images/newsPrimary2.png", title: "1차 고양이 사료구매", date: "2024.11.12", description: "간단 설명2" },
        { imageUrl: "/images/newsPrimary3.png", title: "뉴스제목3", date: "2024.11.13", description: "간단 설명3" },
      ];

  return (
    <div className="news-primary-container">
      <div className="news-list-write-button">
      {/* <Link to='/write'
      state = {{source: "news"}}>
          <button>글쓰기</button>
        </Link> */}
      </div>
        <div className="news-primary-top-title">
            <p>주요 소식</p>
        </div>
        <div className="news-primary-content-container">

        {newsData.map((news, index) => (
        <NewsPrimaryContent
          key={index}
          imageUrl={news.imageUrl}
          title={news.title}
          date={news.date}
          description={news.description}
        />
      ))}
      </div>
    </div>
  );
};

export default NewsPrimary;
