// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}

.page-banner__contant {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.page-banner__title {
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  text-shadow: 0px 4px 19px rgb(0, 0, 0);
}

.page-banner__title--sub {
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  color: white;
  text-shadow: 0px 4px 19px rgb(0, 0, 0);
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner/PageBanner/page-banner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;EACtB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,sCAAsC;AACxC","sourcesContent":[".page-banner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-position: center;\n  background-size: cover;\n  width: 100%;\n  height: 400px;\n}\n\n.page-banner__contant {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n}\n\n.page-banner__title {\n  font-size: 1.5rem;\n  font-weight: 900;\n  color: white;\n  text-shadow: 0px 4px 19px rgb(0, 0, 0);\n}\n\n.page-banner__title--sub {\n  font-size: 0.8rem;\n  line-height: 1rem;\n  text-align: center;\n  color: white;\n  text-shadow: 0px 4px 19px rgb(0, 0, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
