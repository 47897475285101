import React, { useState } from "react";
import "./QuestionWrite.css"
import axios from "axios";
import { useNavigate } from "react-router-dom";

const QuestionAnswer = () => {

  const [content, setContent] = useState("");
  const navigator = useNavigate();

  // const handleContentUpdate = (newContent) => {
  //   setContent(newContent); // content 상태 업데이트
  // };

  const handleSubmit = async () => {

  const isConfirmed = window.confirm("작성하시겠습니까?");

  if (isConfirmed) {
    
    try {
      const response = await axios.post("baseURL/api/inquiry/create", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("게시글 작성 성공", response.data);
      navigator("/question/list");
    } catch (error) {
      console.error("작성 실패", error);
    }
  }
}

  return (
    <div className="question-write-container">
    <div className="question-inquiry-container">
      <div className="question-inquiry-title">
        <div className="question-inquiry-title-text">
          <h2>답변하기</h2>
        </div>
        <div className="question-button-section">
         <button className="question-list-button">목록으로</button>
         <button className="question-submit-button" onClick={handleSubmit}>보내기</button>
      </div>
      </div>

      <textarea
        placeholder="내용을 입력해주세요."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="question-content-textarea"
      ></textarea>
    </div>
    </div>
  );
};

  export default QuestionAnswer;