import React, { useState } from "react";
import "./AdminLogin.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigator = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      alert("이메일과 비밀번호를 입력하세요.");
      return;
    }
    const bodyData = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(
        `https://forcatmung.kr/api/user/login`,
        bodyData
      );
      const resHeaders = response.headers;

      const accessToken = resHeaders.authorization;
      const refreshToken = resHeaders["authorization-refresh"];

      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);

      navigator("/adminpage");
      window.location.reload();
    } catch (e) {
      console.error(e);
      alert("아이디, 비밀번호를 확인해주세요");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="AdminLogin-container">
      <div className="AdminLogin-box">
        <h2 className="AdminLogin-title">관리자 로그인</h2>
        <input
          type="email"
          className="AdminLogin-input"
          placeholder="이메일 입력"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="AdminLogin-input"
          placeholder="비밀번호 입력"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="AdminLogin-button" onClick={handleLogin}>
          로그인
        </button>
      </div>
    </div>
  );
};

export default AdminLogin;
