import { useNavigate } from "react-router-dom";
import OneToManyBody from "./OneToManyBody";
import OneToManyDetail from "./OneToManyDetail";
import OneToManyFooter from "./OneToManyFooter";
import SupportListOneToMany from "./SupportListOneToMany";

const OneToManyDetailPage = () => {
    const navigate = useNavigate()
    const clickHandler = () => {
        navigate('/onetomanypage/list')
        window.scrollTo(0, 0);
    }
    return (
        <div style={{ backgroundColor: '#fff' }}>
            <OneToManyDetail
                onClick={clickHandler} />
            <OneToManyBody />
            <OneToManyFooter name={"메롱이"} />
            <SupportListOneToMany onclick={clickHandler} />
        </div>
    );
}

export default OneToManyDetailPage;