import React from "react";
import "./DonationUseList.css";
import DOMPurify from "dompurify";

const DonationUseListItem = ({
  imageUrl,
  title,
  author,
  date,
  content,
  type,
}) => {
  return (
    <div className="donation-uselist-item-container">
      {imageUrl && <img src={imageUrl} alt={title} />}
      <div className="donation-uselist-item-text">
       <div className="donation-uselist-item-text-header">
        <div className="donation-uselist-item-text-title">
          {type === 1 && <span>[후원물품] </span>}
          {type === 2 && <span>[후원금사용내역] </span>}
          {title}
        </div>
        <div className="donation-uselist-item-text-author">
          <span>{author} | {date}</span>
        </div>
        </div>
        <div
          className="donation-uselist-item-text-content"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content.substring(0, 100)),
          }}
        />
      </div>
    </div>
  );
};
export default DonationUseListItem;
