import React, { useEffect, useState } from "react";
import "./AdminAnnounce.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const AdminAnnounce = () => {
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const itemsPerPage = 10;
  const group = 5;

  const navigator = useNavigate();

  const apiURL = "https://forcatmung.kr";

  useEffect(() => {
    if (search.trim() || date.trim()) {
      fetchSearchResults(currentPage);
    } else {
      fetchList(currentPage);
    }
  }, [currentPage]);

  // 데이터 받아오기
  const fetchList = async (page = 1) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/notice/list?page=${page - 1}&size=${itemsPerPage}`
      );

      if (response.status === 200) {
        const { noticeList, totalPages } = response.data;

        setList(noticeList);
        setTotalPages(totalPages);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 검색
  const fetchSearchResults = async (page = 1) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/notice/search?keyword=${encodeURIComponent(
          search
        )}&date=${date}&page=${page - 1}&size=${itemsPerPage}`
      );
      const { noticeList, totalCount } = response.data;
      setList(noticeList);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } catch (e) {
      console.error(e);
    }
  };

  // 수정페이지 이동
  const handleGoWrite = () => {
    navigator("/adminpage/write", {
      state: {
        source: "announce",
      },
    });
  };

  // 게시글 삭제
  const handleDelete = async (id) => {
    try {
      if (window.confirm("삭제하시겠습니까?")) {
        await axios.delete(
          `https://forcatmung.kr/api/notice/delete?noticeid=${id}`
        );
        alert("삭제되었습니다.");
        window.location.reload();
      }
    } catch (error) {
      console.error("삭제 실패하였습니다.", error);
      alert("삭제 중 오류가 발생하였습니다.");
    }
  };

  // 페이지렌더러
  const renderPageButton = () => {
    const currentGroup = Math.ceil(currentPage / group);
    const startPage = (currentGroup - 1) * group + 1;
    const endPage = Math.min(currentGroup * group, totalPages);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      );
    });
  };

  const handleSearchClick = () => {
    if (search.trim() || date.trim()) {
      fetchSearchResults(1);
    }
  };
  const handlePageChange = (pageNumber) => {
    if (search.trim() || date.trim()) {
      fetchSearchResults(pageNumber);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  return (
    <div className="Admin-Announce-container">
      <div className="Admin-Announce-header">
        <p>공지사항 게시판 관리</p>
        <button className="Admin-Announce-write-btn" onClick={handleGoWrite}>
          게시물 작성
        </button>
      </div>

      <div className="Admin-Announce-search">
        <input
          type="date"
          className="Admin-Announce-date-input"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <input
          type="text"
          className="Admin-Announce-search-input"
          placeholder="검색어를 입력해주세요"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          className="Admin-Announce-search-btn"
          onClick={() => handleSearchClick()}
        >
          검색
        </button>
      </div>

      <table className="Admin-Announce-table">
        <thead>
          <tr style={{ fontWeight: "bold" }}>
            <th>번호</th>
            <th>작성자 성명</th>
            <th>제목</th>
            <th>작성일자</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <tr key={item.id}>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{item.author}</td>
              <td>{item.title}</td>
              <td>{item.date}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Link
                  to={`/update/announce/${item.noticeid}`}
                  state={{ category: "announce" }}
                  className="Admin-Announce-button"
                >
                  수정
                </Link>
                <button
                  className="Admin-Announce-button"
                  onClick={() => handleDelete(item.noticeid)}
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="Admin-Announce-pagination">
        {currentPage > group && (
          <button
            onClick={() =>
              handlePageChange(
                (Math.floor((currentPage - 1) / group) - 1) * group + 1
              )
            }
          >
            이전
          </button>
        )}
        {renderPageButton()}
        {currentPage <= Math.floor(totalPages / group) * group - group && (
          <button
            onClick={() =>
              handlePageChange(Math.ceil(currentPage / group) * group + 1)
            }
          >
            다음
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminAnnounce;
