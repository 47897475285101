import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApplicantsModal.css';

const UserDetail = ({ user, placeholder }) => {
    if (!user) return <p>{placeholder}</p>;
    return (
        <div>
            <p>이름: {user.name}</p>
            <p>전화번호: {user.phone}</p>
            <p>주소: {user.address}</p>
            <p>이메일: {user.email}</p>
            <p>회원분류: {user.vip ? '정회원' : '일반회원'}</p>
            <p>전하고 싶은 말:</p>
            <div className="note-container">
                {user.notes || '작성된 내용이 없습니다.'}
            </div>
        </div>
    );
};

const ApplicantsModal = ({ isVisible, onClose, volunteeringId, volunteeringTitle, createdDate }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [applicants, setApplicants] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [applicantCategory, setApplicantCategory] = useState('전체회원');
    const [participantCategory, setParticipantCategory] = useState('전체회원');

    useEffect(() => {
        if (isVisible && volunteeringId) {
            fetchApplicants();
            fetchParticipants();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, volunteeringId]);

    const fetchRegisterList = async (selectedValue) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://forcatmung.kr/api/volunteering/register/list`, {
                params: { volunteeringId },
            });
            return response.data.filter(item => item.selected === selectedValue);
        } catch (err) {
            console.error('목록 조회 실패:', err);
            setError('목록을 불러오는데 실패했습니다.');
            return [];
        } finally {
            setLoading(false);
        }
    };

    const fetchApplicants = async () => {
        const data = await fetchRegisterList(false);
        setApplicants(data);
    };

    const fetchParticipants = async () => {
        const data = await fetchRegisterList(true);
        setParticipants(data);
    };

    const toggleSelected = async (user, isCurrentlySelected) => {
        if (!user) return;
        try {
            await axios.post(`https://forcatmung.kr/api/volunteering/admin/selected`, null, {
                params: {
                    registerPk: user.id,
                    voluteeringPk: volunteeringId,
                },
            });

            if (isCurrentlySelected) {
                setParticipants(prev => prev.filter(p => p.id !== user.id));
                setApplicants(prev => [...prev, user]);
                setSelectedParticipant(null);
            } else {
                setApplicants(prev => prev.filter(a => a.id !== user.id));
                setParticipants(prev => [...prev, user]);
                setSelectedApplicant(null);
            }
        } catch (err) {
            console.error('상태 전환 실패:', err);
            alert('상태를 전환하는 데 실패했습니다.');
        }
    };

    const handleApplicantCategoryChange = (category) => {
        setApplicantCategory(category);
        setSelectedApplicant(null);
    };

    const handleParticipantCategoryChange = (category) => {
        setParticipantCategory(category);
        setSelectedParticipant(null);
    };

    const handleSelectApplicant = (applicant) => {
        setSelectedApplicant(prev => prev?.id === applicant.id ? null : applicant);
    };

    const handleSelectParticipant = (participant) => {
        setSelectedParticipant(prev => prev?.id === participant.id ? null : participant);
    };

    const filteredApplicants = applicants.filter(applicant =>
        applicantCategory === '전체회원' || applicant.membershipType === applicantCategory
    );

    const filteredParticipants = participants.filter(participant =>
        participantCategory === '전체회원' || participant.membershipType === participantCategory
    );

    if (!isVisible) return null;

    return (
        <div className="applicants-modal__overlay">
            <div className="applicants-modal">
                <div className="applicants-modal__header">
                    <h2 className="applicants-modal__title">봉사활동신청명단</h2>
                    <button className="applicants-modal__close" onClick={onClose}>×</button>
                </div>

                <div className="applicants-modal__info">
                    <div className="applicants-modal__info-title">{volunteeringTitle || '제목 없음'}</div>
                    <div className="applicants-modal__info-date">등록일: {createdDate || '등록일 없음'}</div>
                </div>
                <div className="applicants-modal__divider" style={{ borderTop: '1px solid #ddd', margin: '10px 0', width: '100%' }} />

                <div className="applicants-modal__content">
                    <div className="applicants-modal__section">
                        <h3 className="applicants-modal__section-title">봉사활동 신청명단</h3>
                        <div className="applicants-modal__header-row">
                            <span className="applicants-modal__header-cell">회원</span>
                            <span className="applicants-modal__header-cell">이름</span>
                            <span className="applicants-modal__header-cell">신청자 정보</span>
                        </div>
                        <div className="applicants-modal__list">
                            <div className="applicants-modal__categories">
                                <button className={`applicants-modal__category ${applicantCategory === '전체회원' ? 'active' : ''}`} onClick={() => handleApplicantCategoryChange('전체회원')}>
                                    전체회원
                                </button>
                                <button className={`applicants-modal__category ${applicantCategory === '정회원' ? 'active' : ''}`} onClick={() => handleApplicantCategoryChange('정회원')}>
                                    정회원
                                </button>
                            </div>
                            <div className="applicants-modal__names">
                                {filteredApplicants.map(applicant => (
                                    <button key={applicant.id} className={`applicants-modal__name ${selectedApplicant?.id === applicant.id ? 'active' : ''}`} onClick={() => handleSelectApplicant(applicant)}>
                                        {applicant.name}
                                    </button>
                                ))}
                            </div>
                            <div className="applicants-modal__details">
                                <UserDetail user={selectedApplicant} placeholder="신청자를 선택하세요." />
                            </div>
                        </div>
                    </div>

                    <div className="applicants-modal__actions">
                        <button onClick={() => toggleSelected(selectedApplicant, false)} disabled={!selectedApplicant}>
                            추가 &gt;&gt;
                        </button>
                        <button onClick={() => toggleSelected(selectedParticipant, true)} disabled={!selectedParticipant}>
                            &lt;&lt; 제외
                        </button>
                    </div>

                    <div className="applicants-modal__section">
                        <h3 className="applicants-modal__section-title">봉사활동 참여명단</h3>
                        <div className="applicants-modal__header-row">
                            <span className="applicants-modal__header-cell">회원</span>
                            <span className="applicants-modal__header-cell">이름</span>
                            <span className="applicants-modal__header-cell">신청자 정보</span>
                        </div>
                        <div className="applicants-modal__list">
                            <div className="applicants-modal__categories">
                                <button className={`applicants-modal__category ${participantCategory === '전체회원' ? 'active' : ''}`} onClick={() => handleParticipantCategoryChange('전체회원')}>
                                    전체회원
                                </button>
                                <button className={`applicants-modal__category ${participantCategory === '정회원' ? 'active' : ''}`} onClick={() => handleParticipantCategoryChange('정회원')}>
                                    정회원
                                </button>
                            </div>
                            <div className="applicants-modal__names">
                                {filteredParticipants.map(participant => (
                                    <button key={participant.id} className={`applicants-modal__name ${selectedParticipant?.id === participant.id ? 'active' : ''}`} onClick={() => handleSelectParticipant(participant)}>
                                        {participant.name}
                                    </button>
                                ))}
                            </div>
                            <div className="applicants-modal__details">
                                <UserDetail user={selectedParticipant} placeholder="참여자를 선택하세요." />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="applicants-modal__footer">
                    봉사활동 참여 인원: {participants.length}명
                </div>
            </div>
        </div>
    );
};

export default ApplicantsModal;
