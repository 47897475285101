// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-donationday-button-container {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
}

.admin-donationday-button {
    width: 20%;
    padding: 5px 10px;
    font-size: 1.2rem;
}

.admin-donationday-content-container {
    margin-top: 6vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/donation/AdminDay.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".admin-donationday-button-container {\n    margin-top: 5vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.admin-donationday-button {\n    width: 20%;\n    padding: 5px 10px;\n    font-size: 1.2rem;\n}\n\n.admin-donationday-content-container {\n    margin-top: 6vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
