import SupportBanner from "../Banner/SupportBanner";

const AboutIntro = () => {
  return (
    <>
      <SupportBanner />
    </>
  );
};

export default AboutIntro;
