import React, { useState } from "react";
import "./CheckBefore.css";
import Modal from "../MyPage/Modal";
import AskForHelpModal from "./AskForHelpModal";
import AskForHelpFormModal from "./AskForHelpFormModal"; // 추가된 import

const CheckBefore = () => {
  const [currentModal, setCurrentModal] = useState(null); // "help" 또는 "form"

  const closeModal = () => setCurrentModal(null);
  const openHelpModal = () => setCurrentModal("help");
  const openFormModal = () => setCurrentModal("form");

  return (
    <div className="check-before-container">
      <h2 className="check-before-title">
        동물 구조 / 학대 제보 전에 <br /> 확인해주세요.
      </h2>
      <div className="check-before-box">
        <div className="check-before-text-container">
          <div className="check-before-item">○ 동물 구조가 필요한 경우</div>
          <div>
            <div className="check-before-item">
              &nbsp; • 도로 중앙에 있거나 고립된 장소에 동물이 갇혀있을 시
              구조가 필요합니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 동물이 다쳤거나 무기력, 경련, 호흡이상 등 비정상적인
              행동이 보일 시 구조가 필요합니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 동물이 심각하게 탈수된 상태거나 영양이 부족해 보일 시
              즉시 구조하여야 합니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 유기된 동물이거나 길을 잃은 동물은 구조 및 보호가
              필요합니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 동물이 학대로 인해 스트레스를 받거나 고통을 겪는 경우
              즉시 구조하여야 합니다.
            </div>
          </div>
          <br />
          <div className="check-before-item">
            ○ 동물 구조를 하지 않아야 하는 경우
          </div>
          <div>
            <div className="check-before-item">
              &nbsp; • 자연적으로 치유할 수 있는 부상이나 질병을 보이는 동물은
              관찰 후 필요시 구조하여야 합니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 동물이 길에서 살아가는 경우 상황에 따라 구조 결정을
              하여야 합니다.
            </div>
            <div className="check-before-item">
              &nbsp; &nbsp; - 털이 깨끗하거나 살이 올랐으며 해당 자리에 12시간
              이내로 있었다면 어미가 돌보는 동물로 구조하지 않아야 합니다.
            </div>
            <div className="check-before-item">
              &nbsp; &nbsp; - 길에서 자주 목격되는 동물이거나 특정 구역에서 자주
              나타나는 동물은 구조하지 않아도 될 수 있습니다.
            </div>
            <div className="check-before-item">
              &nbsp; • 야생에서 자생하는 동물 (야생동물)이나 자연 보호구역에서
              살아가는 동물은 구조하지 않아도 될 수 있습니다.
            </div>
          </div>
          <br />
          <div className="check-before-item">
            ○ 유기동물 및 유실동물 발견 시 관할 지방자치단체 또는 동물 보호
            센터에 신고할 수 있습니다.{" "}
            <span className="animal-protection-law">
              「동물보호법」 제39조 제1항
            </span>
          </div>
        </div>
        <div className="report-animal-rescue-container">
          <button
            className="report-animal-rescue-button"
            onClick={openHelpModal}
          >
            구조 제보하기
          </button>
        </div>
      </div>

      {/* 모달 컴포넌트 */}
      <Modal isOpen={currentModal === "help"} onClose={closeModal}>
        <AskForHelpModal onClose={closeModal} onNext={openFormModal} />
      </Modal>
      <Modal isOpen={currentModal === "form"} onClose={closeModal}>
        <AskForHelpFormModal onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default CheckBefore;
