// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-button {
  position: sticky;
  bottom: 1.04vw; /* 20px */
  z-index: 200;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  border-radius: 0.78vw; /* 15px */
  background-color: #005eb8;
  width: 31.67vw; /* 608px */
  height: 5.83vw; /* 112px */
  padding: 0 3.28vw; /* 63px */
  box-sizing: border-box;
}

.floating-button button {
  font-size: 1.04vw; /* 20px */
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}

.button-image {
  width: 3.18vw; /* 61px */
  height: 2.55vw; /* 49px */
}

.floating-button button:hover {
  color: lightgray;
}

.floating-link {
  margin-top: 1.04vw; /* 20px */
}

@media screen and (max-width: 767px) {
  .floating-button {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Floating/Floating.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc,EAAE,SAAS;EACzB,YAAY;EACZ,aAAa;EACb,cAAc;EACd,8BAA8B;EAC9B,qBAAqB,EAAE,SAAS;EAChC,yBAAyB;EACzB,cAAc,EAAE,UAAU;EAC1B,cAAc,EAAE,UAAU;EAC1B,iBAAiB,EAAE,SAAS;EAC5B,sBAAsB;AACxB;;AAEA;EACE,iBAAiB,EAAE,SAAS;EAC5B,6BAA6B;EAC7B,cAAc;EACd,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa,EAAE,SAAS;EACxB,cAAc,EAAE,SAAS;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB,EAAE,SAAS;AAC/B;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".floating-button {\n  position: sticky;\n  bottom: 1.04vw; /* 20px */\n  z-index: 200;\n  display: flex;\n  margin: 0 auto;\n  justify-content: space-between;\n  border-radius: 0.78vw; /* 15px */\n  background-color: #005eb8;\n  width: 31.67vw; /* 608px */\n  height: 5.83vw; /* 112px */\n  padding: 0 3.28vw; /* 63px */\n  box-sizing: border-box;\n}\n\n.floating-button button {\n  font-size: 1.04vw; /* 20px */\n  background-color: transparent;\n  color: #ffffff;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  white-space: nowrap;\n}\n\n.button-image {\n  width: 3.18vw; /* 61px */\n  height: 2.55vw; /* 49px */\n}\n\n.floating-button button:hover {\n  color: lightgray;\n}\n\n.floating-link {\n  margin-top: 1.04vw; /* 20px */\n}\n\n@media screen and (max-width: 767px) {\n  .floating-button {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
