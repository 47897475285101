import { Outlet } from "react-router-dom";
import Percent from "../../components/Main/Percent/percent";
import AboutNav from "../../components/AboutPage/AboutNav/AboutNav";
import PageBanners from "../../components/Banner/PageBanner/PageBanners";
import AboutText from "../../components/AboutPage/AboutText";
import "./About.css";

const AboutPage = () => {
  return (
    <>
      <main className="md-main">
        <PageBanners image={"/images/about-image.png"} title={"포켓멍 센터소개"} subTitle={["안락사 없는 보호소, 포캣멍센터를 소개합니다."]} />
        <AboutText />
        <AboutNav />
        <Outlet />
        <Percent />
      </main>
    </>
  );
};

export default AboutPage;
