import React, { useState, useEffect } from "react";
// import { useParams, Link, useNavigate} from 'react-router-dom';
import { useParams, Link } from "react-router-dom";
import "./News.css";
import axios from "axios";
import DOMPurify from "dompurify";

const NewsDetail = () => {
  const [newsItem, setNewsItem] = useState(null);
  const { id } = useParams();
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/news/detail?newsid=${id}`
        );
        setNewsItem(response.data);
      } catch (error) {
        console.error("센터소식을 가져오는 중 오류가 발생했습니다:", error);
      }
    };

    fetchNews();
  }, [id]);

  if (!newsItem) {
    return <div>센터소식을 찾을 수 없습니다.</div>;
  }

  const prevPost = newsItem.prevId
    ? {
        id: newsItem.prevId,
        title: newsItem.prevTitle,
        date: newsItem.prevDate,
      }
    : null;

  const nextPost = newsItem.nextId
    ? {
        id: newsItem.nextId,
        title: newsItem.nextTitle,
        date: newsItem.nextDate,
      }
    : null;

  // const handleDelete = async () => {
  //   try {
  //     if (window.confirm("삭제하시겠습니까?")) {
  //       await axios.delete(
  //         `https://forcatmung.kr/api/news/delete?newsid=${id}`
  //       );
  //       alert("삭제되었습니다.");
  //       navigate("/news/list");
  //     }
  //   } catch (error) {
  //     console.error("삭제 실패하였습니다.", error);
  //     alert("삭제 중 오류가 발생하였습니다.");
  //   }
  // };

  return (
    <div className="news-post-container">
      <div className="news-post-section">
        <h1 className="news-primary-title">센터 소식</h1>
        <Link to="/news/list" className="news-action-button">
          목록으로
        </Link>
      </div>
      <div className="donation-uselist-button-div">
        {/* 
        <Link
          to={`/update/news/${id}`}
          state={{ category: "news" }}
          className="news-action-button"
        >
          수정
        </Link>
        <button
          className="news-action-button"
          onClick={handleDelete}
        >
          삭제
        </button> */}
      </div>

      <div className="news-title-section">
        <h2 className="news-post-title">{newsItem.title}</h2>
      </div>

      <div className="news-meta-section">
        <span className="news-author">
          <span className="news-stress-word">작성자 : </span>
          <span className="news-author-text">{newsItem.author}</span>
        </span>
        <span className="news-date">
          <span className="news-stress-word">작성일 : </span>
          <span className="news-date-text">{newsItem.date}</span>
        </span>
      </div>

      <div
        className="news-content-section"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(newsItem.content),
        }}
      />

      <div className="news-attachments-section">
        {newsItem.attach ? (
          <>
            <p className="news-attachment">
              첨부파일:{" "}
              <span style={{ fontWeight: "600", color: "#979494" }}>
                {newsItem.attachName}
              </span>
            </p>
          </>
        ) : (
          <p className="news-attachment">첨부파일이 존재하지 않습니다.</p>
        )}
      </div>

      <div className="news-button-section">
        <Link to="/news/list" className="news-action-button">
          목록으로
        </Link>
      </div>

      <table className="news-navigation-table">
        <tbody>
          <tr>
            <td className="news-nav-label">
              이전글<span className="news-triangle up"></span>
            </td>
            <td className="news-nav-title">
              {prevPost ? (
                <Link
                  to={`/news/list/${prevPost.id}`}
                  style={{ color: "#979494", textDecoration: "none" }}
                >
                  {prevPost.title}
                </Link>
              ) : (
                "이전글 없음"
              )}
            </td>
            <td className="news-nav-date">{prevPost ? prevPost.date : ""}</td>
          </tr>
          <tr>
            <td className="news-nav-label">
              다음글<span className="news-triangle down"></span>
            </td>
            <td className="news-nav-title">
              {nextPost ? (
                <Link
                  to={`/news/list/${nextPost.id}`}
                  style={{ color: "#979494", textDecoration: "none" }}
                >
                  {nextPost.title}
                </Link>
              ) : (
                "다음글 없음"
              )}
            </td>
            <td className="news-nav-date">{nextPost ? nextPost.date : ""}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NewsDetail;
