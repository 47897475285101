import React, { useState } from "react";
import "./EditAccountInfo.css";
import Modal from "./Modal";
import DeleteModalComponent from "./DeleteModalComponent";
import { Link } from "react-router-dom";

const EditAccountInfo = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const [formData, setFormData] = useState({
    userName: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    gender: "",
    phone: "010-0000-0000",
    address: "",
    email: "",
    paymentMethod: "카드결제",
    agreeToReceive: "우편 수령 동의",
  });

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // 이름 입력 시 에러 메시지 처리
    if (name === "userName") {
      if (value.length < 1 || value.length > 20) {
        setNameError("1-20자의 이름을 입력해주세요.");
      } else {
        setNameError("");
      }
    }
    // 이메일 입력 시 에러 메시지 처리
    if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        setEmailError("올바른 이메일 형식을 입력해주세요.");
      } else {
        setEmailError("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "userName",
      "birthYear",
      "birthMonth",
      "birthDay",
      "phone",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("필수 항목을 모두 입력해주세요.");
      return;
    }

    console.log("Form Data:", formData);
  };

  return (
    <div className="edit-account-container">
      <h2>회원정보 수정</h2>
      <hr className="bold-hr" />
      <h3>회원 정보</h3>
      <p className="title-message">*표시는 필수 입력 항목입니다</p>
      <hr className="thin-hr" />
      <form onSubmit={handleSubmit}>
        {/* 이름 */}
        <div className="form-row">
          <label>
            이름<span className="required">*</span>
          </label>
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            placeholder="이름을 입력해 주세요"
            required
            minLength="1"
            maxLength="20"
          />
        </div>
        {nameError && <div className="error-message">{nameError}</div>}

        {/* 생년월일 */}
        <div className="form-row">
          <label>
            생년월일<span className="required">*</span>
          </label>
          <div className="date-inputs">
            <select
              name="birthYear"
              value={formData.birthYear}
              onChange={handleChange}
              required
            >
              <option value="">YYYY</option>
              {Array.from({ length: 100 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
            <select
              name="birthMonth"
              value={formData.birthMonth}
              onChange={handleChange}
              required
            >
              <option value="">MM</option>
              {Array.from({ length: 12 }, (_, i) => {
                const month = String(i + 1).padStart(2, "0");
                return (
                  <option key={month} value={month}>
                    {month}
                  </option>
                );
              })}
            </select>
            <select
              name="birthDay"
              value={formData.birthDay}
              onChange={handleChange}
              required
            >
              <option value="">DD</option>
              {Array.from({ length: 31 }, (_, i) => {
                const day = String(i + 1).padStart(2, "0");
                return (
                  <option key={day} value={day}>
                    {day}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* 성별 */}
        <div className="form-row">
          <label>성별</label>
          <div className="gender-options">
            {["남자", "여자"].map((gender) => (
              <label className="radio-label" key={gender}>
                <input
                  type="radio"
                  name="gender"
                  value={gender}
                  checked={formData.gender === gender}
                  onChange={handleChange}
                />
                {gender}
              </label>
            ))}
          </div>
        </div>

        {/* 전화번호 */}
        <div className="form-row">
          <label>전화번호</label>
          <span className="phone-text">{formData.phone}</span>
        </div>

        {/* 주소 */}
        <div className="form-row">
          <label>주소</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="주소를 입력해 주세요"
          />
        </div>

        {/* 이메일 */}
        <div className="form-row">
          <label>이메일</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="이메일을 입력해 주세요"
          />
        </div>
        {emailError && <div className="error-message">{emailError}</div>}

        {/* 결제방법 */}
        <div className="form-row">
          <label>결제방법</label>
          <div className="payment-method">{formData.paymentMethod}</div>
        </div>
        <div className="payment-method-note">
          결제방법을 변경하시려면 문의를 남겨주세요.
        </div>

        {/* 수신 동의 */}
        <div className="form-row">
          <label>수신 동의</label>
          <div className="location-preference">
            {["우편 수령 동의", "문자 수신 동의"].map((location) => (
              <label className="radio-label" key={location}>
                <input
                  type="radio"
                  name="agreeToReceive" // 변경된 name
                  value={location}
                  checked={formData.agreeToReceive === location} // 변경된 상태
                  onChange={handleChange}
                />
                {location}
              </label>
            ))}
          </div>
        </div>
        <div className="mailing-note">
          포캣멍센터에서 후원 아이들의 우편, 소식 등을 보내드립니다.
        </div>
        <Link
          className="link-style"
          onClick={openModal}
          style={{ textDecoration: "underline" }}
        >
          회원탈퇴
        </Link>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <DeleteModalComponent onClose={closeModal} />
        </Modal>
        <hr className="thin-hr" />
        <button type="submit" className="submit-button">
          수정
        </button>
      </form>
    </div>
  );
};

export default EditAccountInfo;
