import { Outlet } from "react-router-dom";
import React from 'react';
import "./News.css";
import NewsBanner from "../../components/News/NewsBanner"

const NewsPage = () => {
  return (
    <>
      <main className="news-container">
        <NewsBanner />
        <Outlet />
      </main>
    </>
  );
};
export default NewsPage;
