import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AskForHelpSlider.css";

function AskForHelpSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const cards = [
    {
      id: 1,
      title: "01",
      content: "동물 학대 시 현행법 위반행위로 엄중한 처벌을 받을 수 있습니다",
      subContent: "* 동물학대 시 3년 이하의 징역 또는 3천만원 이하의 벌금",
    },
    {
      id: 2,
      title: "02",
      content: "동물 학대 시 증거를 확보 후 112에 신고해 주세요",
      subContent: "* 확보된 사진 및 동영상은 법적 증거로 활용됩니다.",
    },
    {
      id: 3,
      title: "03",
      content: "각 지자체 동물 보호 업무를 담당하는 공무원에게 신고해주세요",
      subContent: "* 동물학대 시 3년 이하의 징역 또는 3천만원 이하의 벌금",
    },
    {
      id: 4,
      title: "04",
      content:
        "포캣멍 센터로 도움을 요청할 경우, 제보하기를 통하여 자세한 내용을 기입하여주시길 바랍니다",
    },
  ];

  return (
    <div className="ask-for-help-slider-container">
      <div className="ask-for-help-slider">
        <Slider {...settings}>
          {cards.map((card) => (
            <div key={card.id} className="ask-for-help-card">
              <div className="ask-for-help-card-content">
                <div className="ask-for-help-card-title">{card.title}</div>
                <p className="ask-for-help-card-text">{card.content}</p>
                <p className="ask-for-help-card-subtext">{card.subContent}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default AskForHelpSlider;
