import React, { useState, useEffect } from "react";
import { getFaqList, deleteFaq } from "./faqApis";
import { useNavigate } from "react-router-dom";

const FAQList = () => {
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const data = await getFaqList();
        setFaqs(data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };
    fetchFaqs();
  }, []);

  const FAQItem = ({ id, question, answer }) => {
    // const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleUpdateClick = () => {
      navigate(`/faq/update/${id}`);
    };

    const handleDelete = async (id) => {
      if (window.confirm("이 FAQ를 삭제하시겠습니까?")) {
        try {
          await deleteFaq(id);
          const updatedFaqs = await getFaqList();
          setFaqs(updatedFaqs);
          alert("FAQ가 성공적으로 삭제되었습니다.");
        } catch (err) {
          alert("FAQ 삭제에 실패했습니다.");
          console.error(err);
        }
      }
    };
    const toggleFAQ = () => {
      setIsOpen(!isOpen);
    };

    return (
      <li className="faq-item">
        <div className="faq-item__question" onClick={toggleFAQ}>
          <div className="item__content">
            <span className="item__q">Q</span>
            <p className="item__question">{question}</p>
          </div>
          <div className="faq-item-btns">
            {/* <button className="faq-item-btn" onClick={() => handleDelete(id)}>
              삭제
            </button>
            <button className="faq-item-btn" onClick={handleUpdateClick}>
              수정하기
            </button> */}
            <span className="faq-item__icon">{isOpen ? "▲" : "▼"}</span>
          </div>
        </div>
        {isOpen && (
          <div className="faq-item__answer">
            <div className="item__content">
              <span className="item__a">A</span>
              <p className="item__answer">{answer}</p>
            </div>
          </div>
        )}
      </li>
    );
  };

  if (isLoading) {
    return <p>Loading FAQs...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleWriteClick = () => {
    navigate("write");
  };

  return (
    <ul className="faq-list">
      {/* <button className="faq-item-btn" onClick={handleWriteClick}>
        FAQ 작성하기
      </button> */}
      {faqs.map((faq) => (
        <FAQItem key={faq.id} id={faq.id} question={faq.title} answer={faq.description} />
      ))}
    </ul>
  );
};

export default FAQList;
