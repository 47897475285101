import React from 'react'
import './FanClubModalFrame.css'
import axios from 'axios';

const FanClubModalFrame = ({ children, onClose, onNext, onPrev, currentStep, inputData, isChecked, isValid }) => {
    const fanClubRequestUrl = "https://forcatmung.kr/api/fanclub";
    const handleNext = async () => {
        if (currentStep === 2) {
            if (!isChecked && !isValid) {
                alert('입력해주세요');
            } else if (!isChecked) {
                alert('모두 동의해주세요');
            } else if (!isValid) {
                alert('입력값을 확인해주세요');
            } else {
                try {
                    console.log(inputData);
                    const response = await axios.post(
                        `${fanClubRequestUrl}/donate`,
                        inputData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    if (response.status === 200) {
                        console.log("서버 응답:", response.data);
                    }
                    onNext();
                } catch (error) {
                    if (error.response) {
                        // 서버에서 반환한 에러 응답
                        console.error("서버 응답 오류:", error.response.data);
                        console.error("응답 상태 코드:", error.response.status);
                    } else if (error.request) {
                        // 요청은 보냈지만 응답을 받지 못한 경우
                        console.error("서버로부터 응답을 받지 못했습니다:", error.request);
                    } else {
                        // 요청 설정 중에 발생한 에러
                        console.error("요청 설정 오류:", error.message);
                    }
                }
            }
        } else {
            onNext(); // 일반적인 단계 이동
        }
    };

    return (
        <div className='frame-container'>
            {!(currentStep === 3) && <div className='frame-header-container'>
                <div className='frame-header-image'>
                </div>
                <div className='frame-header-text'>
                    <h1>스타 팬클럽 후원이란?</h1>
                    <h3>스타의 특별한 날 동물보호에 팬클럽분들과 함께 동참하는 기념하는 후원입니다.</h3>
                </div>
            </div>}
            <div className='frame-body-container'>
                {children}
            </div>
            <div className='frame-button-container'>
                {currentStep === 1 && <button className='button close' onClick={onClose}>닫기</button>}
                {currentStep === 2 && <button className='button close' onClick={onPrev}>이전</button>}
                {currentStep !== 3 && <button className='button next' onClick={handleNext}>다음으로</button>}
            </div>
        </div>
    )
}

export default FanClubModalFrame             