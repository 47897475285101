import axios from "axios";
const BASE_URL = "https://forcatmung.kr/";

const getFaqList = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/faq/list`);
    return response.data;
  } catch (error) {
    console.error("Error fetching animal details:", error);
    throw new Error("FAQ를 불러오는 데 실패했습니다.");
  }
};

const deleteFaq = async (faqid) => {
  try {
    const response = await axios.delete(`${BASE_URL}api/faq/delete?faqid=${faqid}`);
    console.log("아이디 값" + faqid);
    return response.data;
  } catch (error) {
    console.error("Error deleting FAQ:", error);
    throw new Error("FAQ 삭제에 실패했습니다.");
  }
};

export { getFaqList, deleteFaq };
