import React, { useState } from "react";
import "./QuestionWrite.css"
import SmartEditor from "../../components/SmartEditor/SmartEditor"
import axios from "axios";
import { useNavigate } from "react-router-dom";

const QuestionWrite = () => {
  const [title, setTitle] = useState("");
  const [nickname, setNickname] = useState("");
  const [content, setContent] = useState("");
  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !nickname) {
      alert("제목, 닉네임, 내용을 모두 입력해주세요");
      return;
    }

  const isConfirmed = window.confirm("작성하시겠습니까?");

  if (isConfirmed) {
    const formData = new FormData();
    const jsonData = { inquryTitle: title, inquryContent: content, userId: 7, nickname : nickname};

    formData.append('question', new Blob([JSON.stringify(jsonData)], { type: "application/json" }));
    
    try {
      const response = await axios.post("https://forcatmung.kr/api/inquiry/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("게시글 작성 성공", response.data);
      window.alert("작성이 완료되었습니다.");
      setTimeout(() => navigator("/question/list"), 0);
    } catch (error) {
      console.error("작성 실패", error);
    }
  }
}

const handleNavigateList = () => {

  navigator("/question/list")
}

  return (
    <form onSubmit={handleSubmit}>
    <div className="question-write-container">
    <div className="question-inquiry-container">
      <div className="question-inquiry-title">
        <div className="question-inquiry-title-text">
          <h2>문의하기</h2>
        </div>
        <div className="question-button-section">
        <button type="button" className="question-list-button" onClick={(e) => {e.preventDefault(); handleNavigateList();}}>목록으로</button>
         {/* <button className="question-submit-button" onClick={handleSubmit}>보내기</button> */}
      </div>
      </div>

      <div className="question-title-divider"></div>
      <div className="question-input-section" >
      <div className="question-input-group">
      <span className="question-input-label-first">제목</span>
       <input
         type="text"
       value={title}
       onChange={(e) => setTitle(e.target.value)}
       className="question-input-box title-input"
     />
       </div>
       <div className="question-input-group">
    <span className="question-input-label-second">닉네임</span>
    <input
      type="text"
      value={nickname}
      onChange={(e) => setNickname(e.target.value)}
      className="question-input-box nickname-input"
    />
  </div>
      </div>
      <div className="editor-container">
        <SmartEditor onContentChange={setContent}  />
      </div>

      {/* <textarea
        placeholder="내용을 입력해주세요."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="question-content-textarea"
      ></textarea> */}



      <p className="question-notice-text">
        * 관리자가 답장을 하면 문의하기 게시판에 올라옵니다. <br />
        * 욕설 및 부적절한 말은 삼가해주시길 바랍니다.
      </p>
    </div>
    </div>
    </form>
  );
};

export default QuestionWrite;
