import "./page-banner.css";

const PageBanners = ({ image, title, subTitle }) => {
  return (
    <section className="page-banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="page-banner__contant">
        <h1 className="page-banner__title">{title}</h1>
        <h2 className="page-banner__title--sub">
          {subTitle.map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </h2>
      </div>
    </section>
  );
};

export default PageBanners;
