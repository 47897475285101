import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "./pages/layout";
import MainPage from "./pages/Main/MainPage";
import DonationPage from "./pages/DonationPage/DonationPage";
import Terms from "./pages/DonationPage/Terms";
import Personal from "./pages/DonationPage/Personal";
import AboutIntro from "./components/AboutPage/AboutIntro";
import AboutOrganization from "./components/AboutPage/AboutOrganization/AboutOrganization";
import AboutHistory from "./components/AboutPage/AboutHistory/AboutHistory";

import AboutPage from "./pages/About/AboutPage";
import OneToOnePage from "./pages/Support/OneToOne/OneToOnePage";
import SupportPage from "./pages/Support/SupportPage/SupportPage";
import OneToManyPage from "./pages/Support/OneToMany/OneToManyPage";
// import AdminMember from "./pages/Admin/AdminMember";
// import AdminDonation from "./pages/Admin/AdminDonation";
import Admin from "./pages/Admin/Admin";
import OneToManyPageList from "./components/Support/OneToMany/OneToManyPageList";
import OneToManyDetailPage from "./components/Support/OneToMany/OneToManyDetailPage";
import EditOneToManyPage from "./pages/Support/OneToMany/EditOneToManyPage";
import TemporaryPay from "./pages/Payment/TemporaryPay";
import RegularlyPay from "./pages/Payment/RegularlyPay";
import OneToOneDetail from "./components/Support/OneToOne/OneToOneDetail/OneToOneDetail";
import OneToOneMainPage from "./components/Support/OneToOne/OneToOneMainPage";
import NewsPage from "./pages/News/News";
import DonationUseList from "./pages/DonationUseList/DonationUseList";
import MyPage from "./pages/MyPage/MyPage";
import AnnouncePage from "./pages/Announcement/AnnouncePage";
import MyPageDeleteIdPage from "./pages/MyPage/MyPageDeleteIdPage";
import EditAccountInfo from "./components/MyPage/EditAccountInfo";
import NewsDetail from "./pages/News/NewsDetail";
import DonationUseListDetail from "./pages/DonationUseList/DonationUseListDetail";
import NewsMainPage from "./pages/News/NewsMain";
import DonationUseListMainPage from "./pages/DonationUseList/DonationUseListMainPage";
import MyPageOneToMany from "./components/MyPage/MyPageOneToMany";
import OneTimeDonation from "./components/MyPage/OneTimeDonation";
import MyActivityComponent from "./components/MyPage/MyActivityComponent";
import AnnounceDetail from "./pages/Announcement/AnnounceDetail";
import Write from "./pages/Write/Write";
import MyPageOneToOne from "./components/MyPage/MyPageOneToOne";
import SubscriptionDonation from "./components/MyPage/SubscriptionDonation";
import Volunteering from "./pages/Volunteering/Volunteering";
import VolunteeringBeauty from "./components/Volunteering/VolunteeringBeauty";
import VolunteeringTraining from "./components/Volunteering/VolunteeringTraining";
import VolunteeringWalk from "./components/Volunteering/VolunteeringWalk";
import VolunteeringCleaning from "./components/Volunteering/VolunteeringCleaning";
import VolunteeringRelocation from "./components/Volunteering/VolunteeringRelocation";
import VolunteeringPrivacyPolicy from "./components/Volunteering/VolunteeringPrivacyPolicy";
import VolunteeringTermsofUse from "./components/Volunteering/VolunteeringTermsofUse";
import FanClub from "./components/FanClub/FanClub";
import CustomDonationPage from "./pages/CustomDonation/CustomDonationPage";
import Faq from "./components/Faq/Faq";
import Heritage from "./components/Heritage/Heritage";
import TalentPage from "./pages/Talent/TalentPage";
import ParticipatePage from "./pages/Participate/ParticipatePage";
import AskForHelp from "./components/AskForHelp/AskForHelp";
import AdoptionPage from "./pages/Adoption/AdoptionPage";
import CompanySponsorship from "./components/CompanySponsorship/CompanySponsorship";
import AdminMain from "./components/Admin/AdminMain";
import AdminDay from "./components/Admin/donation/AdminDay";
import AdminAnimalRegister from "./components/Admin/Post/AdminAniReg";
import AdminAniList from "./components/Admin/Post/AdminAniList";
import AdminAdoption from "./components/Admin/Post/AdminAdoption";
import Update from "./pages/Write/Update";
import OnetoPen from "./pages/Write/OnetoPen";
import AdminAnimalUpdate from "./components/Admin/Post/AdminAniUpdate";
import AdminTerm from "./components/Admin/donation/AdminTerm";
import QuestionPage from "./pages/Question/Question";
import QuestionDetail from "./pages/Question/QuestionDetail";
import QuestionMainPage from "./pages/Question/QuestionMain";
import QuestionWrite from "./pages/Question/QuestionWrite";
import QuestionUpdate from "./pages/Question/QuestionUpdate";
import QuestionAnswer from "./pages/Question/QuestionAnswer";
import Login from "./pages/Login/Login";
import FaqWrite from "./components/Faq/FaqWrite/FaqWrite";
import FaqUpdate from "./components/Faq/FaqUpdate/FaqUpdate";
import VolunteeringCreate from "./components/Admin/Post/VolunteeringCreate";
import AdminVolunteering from "./components/Admin/Post/AdminVolunteering";
import AboutBusiness from "./components/AboutPage/AboutBusiness/AboutBusiness";
import AboutPurpose from "./components/AboutPage/AboutPurpose/AboutPurpose";
import MemberInfo from "./pages/MemberInfo/MemberInfo";
import VolunteeringUpdate from "./components/Admin/Post/VolunteeringUpdate";
import AdminVolunteeringList from "./components/Admin/Post/AdminVolunteeringList";
import AdminTalent from "./pages/Admin/AdminTalent";
import VolunteeringDetail from "./components/Admin/Post/VolunteeringDetail";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminAnnounce from "./pages/Admin/AdminAnnounce";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <MainPage />,
      },
      {
        path: "donation",
        element: <DonationPage />,
      },
      {
        path: "donation/success/:step",
        element: <DonationPage />, // 동적 파라미터 사용
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "personal",
        element: <Personal />,
      },
      {
        path: "about",
        element: <AboutPage />,
        children: [
          { path: "intro", element: <AboutIntro /> },
          { path: "organization", element: <AboutOrganization /> },
          { path: "history", element: <AboutHistory /> },
          { path: "business", element: <AboutBusiness /> },
          { path: "purpose", element: <AboutPurpose /> },
        ],
      },
      {
        path: "support",
        element: <SupportPage />,
      },
      {
        path: "onetomanypage",
        element: <OneToManyPage />,
        children: [
          { path: "detail/:id", element: <OneToManyDetailPage /> },
          { path: "list", element: <OneToManyPageList /> },
        ],
      },
      {
        path: "onetoone",
        element: <OneToOnePage />,
        children: [
          { path: "list", element: <OneToOneMainPage /> },
          { path: "list/:id", element: <OneToOneDetail /> },
        ],
      },
      {
        path: "mypage",
        element: <MyPage />,
        children: [
          { path: "myactivity", element: <MyActivityComponent /> },
          { path: "onetoone", element: <MyPageOneToOne /> },
          { path: "editaccountinfo", element: <EditAccountInfo /> },
          { path: "onetomany", element: <MyPageOneToMany /> },
          { path: "onetimedonation", element: <OneTimeDonation /> },
          { path: "onetimedonation", element: <OneTimeDonation /> },
          { path: "subdonation", element: <SubscriptionDonation /> },
        ],
      },
      {
        path: "mypage/editaccountinfo/deleteid",
        element: <MyPageDeleteIdPage />,
      },
      {
        path: "editonetomanypage",
        element: <EditOneToManyPage />,
      },
      {
        path: "adminpage",
        element: <Admin />,
        children: [
          { index: true, element: <Navigate to="main" replace /> },
          { path: "main", element: <AdminMain /> },
          // { path: "donation", element: <AdminDonation /> },
          // { path: "member", element: <AdminMember />     },
          { path: "day", element: <AdminDay /> },
          { path: "term", element: <AdminTerm /> },
          { path: "child", element: <AdminAniList /> },
          { path: "register", element: <AdminAnimalRegister /> },
          { path: "adoption", element: <AdminAdoption /> },
          { path: "update/:animalId", element: <AdminAnimalUpdate /> },
          { path: "day", element: <AdminDay /> },
          { path: "child", element: <AdminAniList /> },
          { path: "register", element: <AdminAnimalRegister /> },
          { path: "adoption", element: <AdminAdoption /> },
          { path: "volunteering", element: <AdminVolunteering /> },
          { path: "volunteering/:volunteeringId", element: <VolunteeringDetail /> },
          { path: "create", element: <VolunteeringCreate /> },
          { path: "edit", element: <VolunteeringUpdate /> },
          { path: "list", element: <AdminVolunteeringList /> },
          { path: "talent", element: <AdminTalent /> },
          { path: "announce", element: <AdminAnnounce /> },
          { path: "write", element: <Write /> },
        ],
      },
      {
        path: "admin",
        element: <AdminLogin />,
      },
      {
        path: "temporarypayment",
        element: <TemporaryPay />,
      },
      {
        path: "regularlypayment",
        element: <RegularlyPay />,
      },
      {
        path: "uselist",
        element: <DonationUseList />,
        children: [
          { index: true, element: <Navigate to="list" replace /> },
          { path: "list", element: <DonationUseListMainPage /> },
          { path: "list/:id", element: <DonationUseListDetail /> },
        ],
      },
      {
        path: "news",
        element: <NewsPage />,
        children: [
          { index: true, element: <Navigate to="list" replace /> },
          { path: "list", element: <NewsMainPage /> },
          { path: "list/:id", element: <NewsDetail /> },
        ],
      },
      {
        path: "announce/list",
        element: <AnnouncePage />,
      },
      {
        path: "announce/detail/:id",
        element: <AnnounceDetail />,
      },
      {
        path: "update/:route/:id",
        element: <Update />,
      },
      {
        path: "volunteering",
        element: <Volunteering />,
        children: [
          { path: "beauty", element: <VolunteeringBeauty /> },
          { path: "training", element: <VolunteeringTraining /> },
          { path: "walk", element: <VolunteeringWalk /> },
          { path: "cleaning", element: <VolunteeringCleaning /> },
          { path: "relocation", element: <VolunteeringRelocation /> },
          { path: "terms", element: <VolunteeringTermsofUse /> },
          { path: "privacy", element: <VolunteeringPrivacyPolicy /> },
        ],
      },
      {
        path: "customdonation",
        element: <CustomDonationPage />,
        children: [{ path: "stardonation", element: <FanClub /> }],
      },
      {
        path: "participate",
        element: <ParticipatePage />,
        children: [{ path: "askforhelp", element: <AskForHelp /> }],
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "faq/write",
        element: <FaqWrite />,
      },
      {
        path: "faq/update/:id",
        element: <FaqUpdate />,
      },
      {
        path: "TalentPage",
        element: <TalentPage />,
      },
      {
        path: "AdoptionPage",
        element: <AdoptionPage />,
      },
      { path: "heritage-support", element: <Heritage /> },
      {
        path: "company-sponsorship",
        element: <CompanySponsorship />,
      },
      {
        path: "onetopen/write",
        element: <OnetoPen />,
      },
      {
        path: "question",
        element: <QuestionPage />,
        children: [
          { index: true, element: <Navigate to="list" replace /> },
          { path: "list", element: <QuestionMainPage /> },
          { path: "list/:id", element: <QuestionDetail /> },
          { path: "write", element: <QuestionWrite /> },
          { path: "write/:id", element: <QuestionUpdate /> },
          { path: "answer/:id", element: <QuestionAnswer /> },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register/memberinfo",
        element: <MemberInfo />,
      },
    ],
  },
]);

export default router;
