// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-adoption-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(131, 124, 124, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 추가 */
}

.admin-adoption-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  position: relative; /* 부모 요소에 대해 상대적 위치 지정 */
  z-index: 1001; /* overlay보다 높은 z-index로 설정 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
  overflow-y: auto; /* 세로 스크롤바 추가 */
}
  
  .admin-adoption-modal-content h2 {
    margin-top: 0;
  }
  
  .admin-adoption-modal-content button {
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/Post/AdminAdoptionModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,0CAA0C;EAC1C,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa,EAAE,+BAA+B;AAChD;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,kBAAkB,EAAE,wBAAwB;EAC5C,aAAa,EAAE,6BAA6B;EAC5C,wCAAwC,EAAE,cAAc;EACxD,gBAAgB,EAAE,eAAe;AACnC;;EAEE;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".admin-adoption-modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(131, 124, 124, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000; /* 다른 요소들 위에 표시되도록 z-index 추가 */\n}\n\n.admin-adoption-modal-content {\n  background-color: white;\n  padding: 20px;\n  border-radius: 5px;\n  max-width: 500px;\n  width: 100%;\n  position: relative; /* 부모 요소에 대해 상대적 위치 지정 */\n  z-index: 1001; /* overlay보다 높은 z-index로 설정 */\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */\n  overflow-y: auto; /* 세로 스크롤바 추가 */\n}\n  \n  .admin-adoption-modal-content h2 {\n    margin-top: 0;\n  }\n  \n  .admin-adoption-modal-content button {\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
