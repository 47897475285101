import './OneToMany.css';
import OneToManyContent from './OneToManyContent';
import BottomButton from '../../../assets/bottom_button.png'

const firstStory = {
    title: "쿠팡 봉지에 싸여 버려진",
    catName: "모세",
    description: `비오는 날 쿠팡봉지에 버려진 채 
발견된 아이 모세입니다.
발견 당시 뒷다리에 장애를 가지고 있었고

샴 믹스인 아이입니다 느낌의 이야기`,
};

const secondStory = {
    title: "사람의 도움이 절실한",
    catName: "명길이",
    description: `형제들과 함께 구조되었던 아이인 
    명길이 입니다.

    하지만 낙상을 당하였는지
    형제들과 달리
    사람의 도움없이는 생존이 어려웠어요`,
};

const OneToMany = () => {
    return (
        <div className="one-to-many-page">
            <div className="one-to-many-header">
                <hr className='header-divider' />
                <div className="one-to-many-header-text">
                    <span className="one-to-many-small-text">아이들과 함께 해주세요</span>
                    <span className="one-to-many-large-text">포캣멍센터 아이들의 이야기</span>
                </div>
            </div>

            <OneToManyContent
                title={firstStory.title}
                catName={firstStory.catName}
                description={firstStory.description}
                imageUrl="https://picsum.photos/350"
            />

            <OneToManyContent
                title={secondStory.title}
                catName={secondStory.catName}
                description={secondStory.description}
                imageUrl="https://picsum.photos/350"
            />

            <div className="one-to-many-content-footer">
                <div className="one-to-many-content-footer-text">
                    <span className="one-to-many-small-text" style={{ fontSize: 18, marginLeft: 0 }}>
                        동물들에게 베푼 사랑은 결코 사라지지 않습니다.
                    </span>
                    <span className="one-to-many-large-text" style={{ fontSize: 30, marginLeft: 0 }}>
                        아이들에게 새로운 세상을 선물해 주세요.
                    </span>
                </div>
                <img
                    src={BottomButton}
                    alt="다른 아이들 보러 가기"
                    className="one-to-many-donate-button-img"
                />
            </div>
        </div >
    );
}

export default OneToMany;
