import { useEffect } from "react";

// 로그인 세션저장
export const useAuthToken = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");

    if (accessToken) {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      // console.log("저장 완료", accessToken);

      window.dispatchEvent(new Event("login"));

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);
};
