import React, { useEffect, useState } from "react";
import "./MemberInfo.css";
import { useAuthToken } from "../../hooks/loginHooks";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MemberInfo = () => {
  const [userName, setUserName] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [receiveMail, setReceiveMail] = useState(false);
  const [receiveTextMessage, setReceiveTextMessage] = useState(false);
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [birthday, setBirthday] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [certification, setCertification] = useState(false);
  const [certificationCode, setCertificationCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const navigate = useNavigate();

  // 생년월일 구성
  useEffect(() => {
    if (year && month && day) {
      setBirthday(`${year}-${month}-${day}`);
    } else {
      setBirthday("");
    }
  }, [year, month, day]);

  const handleBirthdayChange = (e, type) => {
    const value = e.target.value || "";
    if (type === "year") setYear(value);
    if (type === "month") setMonth(value);
    if (type === "day") setDay(value);
  };

  const accessToken = sessionStorage.getItem("accessToken");
  useAuthToken();

  // 다음 우편주소 호출
  const handleAddressSearch = () => {
    const width = 500;
    const height = 600;
    new window.daum.Postcode({
      width: width,
      height: height,
      oncomplete: function (data) {
        setAddress(data.address);
      },
    }).open({
      left: window.screen.width / 2 - width / 2,
      top: window.screen.height / 2 - height / 2,
    });
  };

  // 전화번호 인증 발송
  const handleCertification = async () => {
    if (!phone) {
      alert("전화번호를 확인해주세요");
      return;
    }

    if (phone) {
      const data = {
        phoneNumber: phone,
      };
      try {
        await axios.post(`https://forcatmung.kr/api/sms/send`, data);
        setCertification(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  // 전화번호 인증
  const handleVerifyCode = async () => {
    if (certification && phone) {
      const data = {
        phoneNumber: phone,
        value: certificationCode,
      };
      try {
        const response = await axios.post(
          `https://forcatmung.kr/api/sms/verify`,
          data
        );

        if (response.status === 200) {
          alert("인증되었습니다.");
          setIsVerified(true);
        } else {
          alert("인증에 실패하였습니다.");
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleSubmit = async () => {
    if (!userName) {
      alert("이름을 입력해 주세요");
      return;
    }
    if (!birthday) {
      alert("생년월일을 입력해 주세요");
      return;
    }
    if (!gender) {
      alert("성별을 선택해 주세요");
      return;
    }
    if (!isVerified) {
      alert("전화번호 인증이 필요합니다");
      return;
    }
    if (!phone) {
      alert("전화번호를 입력해 주세요");
      return;
    }
    if (!address || !detailAddress) {
      alert("주소를 입력해 주세요");
      return;
    }
    if (!email) {
      alert("이메일을 입력해 주세요");
      return;
    }

    try {
      const bodyData = {
        userName: userName,
        birthDay: birthday,
        gender: gender,
        phone: phone,
        address: `${address} ${detailAddress}`,
        email: email,
        receiveMail: receiveMail,
        receiveTextMessage: receiveTextMessage,
      };

      await axios.post(
        "https://forcatmung.kr/api/user/myprofile/update",
        bodyData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,

            "Content-Type": "application/json",
          },
        }
      );
      alert("회원정보가 등록되었습니다.");
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="memberInfo-main">
      <div className="memberInfo-main-title">회원정보 등록</div>
      <div className="memberInfo-content">
        <p>
          <span style={{ color: "red" }}>*</span> 표시는 필수항목입니다.
        </p>

        {/* 이름 */}
        <div className="memberInfo-name memberInfo-row">
          <label>
            이름<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              placeholder="이름을 입력해 주세요"
              required
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
        </div>

        {/* 생년월일 */}
        <div className="memberInfo-birthday memberInfo-row">
          <label>
            생년월일<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group memberInfo-birthday-select">
            <select
              required
              value={year}
              onChange={(e) => handleBirthdayChange(e, "year")}
            >
              <option value="">년</option>
              {Array.from({ length: 100 }, (_, i) => {
                const y = new Date().getFullYear() - i;
                return (
                  <option key={y} value={y}>
                    {y}
                  </option>
                );
              })}
            </select>
            <select
              required
              value={month}
              onChange={(e) => handleBirthdayChange(e, "month")}
            >
              <option value="">월</option>
              {Array.from({ length: 12 }, (_, i) => {
                const m = String(i + 1).padStart(2, "0");
                return (
                  <option key={m} value={m}>
                    {m}
                  </option>
                );
              })}
            </select>
            <select
              required
              value={day}
              onChange={(e) => handleBirthdayChange(e, "day")}
            >
              <option value="">일</option>
              {Array.from({ length: 31 }, (_, i) => {
                const d = String(i + 1).padStart(2, "0");
                return (
                  <option key={d} value={d}>
                    {d}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* 성별 */}
        <div className="memberInfo-gender memberInfo-row">
          <label>
            성별<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group memberInfo-gender-radio">
            <label>
              <input
                type="radio"
                name="gender"
                value="남자"
                checked={gender === "남자"}
                onChange={(e) => setGender(e.target.value)}
              />
              남자
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="여자"
                checked={gender === "여자"}
                onChange={(e) => setGender(e.target.value)}
              />
              여자
            </label>
          </div>
        </div>

        {/* 전화번호 */}
        <div className="memberInfo-phone memberInfo-row">
          <label>
            전화번호<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <input
              type="number"
              placeholder="숫자만 입력해 주세요"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <button onClick={handleCertification}>인증</button>
          </div>
        </div>

        {certification && (
          <div className="memberInfo-certification memberInfo-row">
            <label></label>
            <div className="certification-box">
              <input
                type="text"
                placeholder="인증번호를 입력해 주세요"
                value={certificationCode}
                onChange={(e) => setCertificationCode(e.target.value)}
              />
              <button onClick={handleVerifyCode}>확인</button>
            </div>
          </div>
        )}

        {/* 주소 */}
        <div className="memberInfo-address memberInfo-row">
          <label>
            주소<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              placeholder="주소를 입력해 주세요"
              required
              value={address}
              readOnly
            />
            <button onClick={handleAddressSearch}>검색</button>
          </div>
        </div>

        {/* 상세 주소 */}
        <div className="memberInfo-detail-address memberInfo-row">
          <label>
            상세 주소<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              placeholder="상세 주소를 입력해 주세요"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
            />
          </div>
        </div>

        {/* 이메일 */}
        <div className="memberInfo-email memberInfo-row">
          <label>
            이메일<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group">
            <input
              type="email"
              placeholder="이메일을 입력해 주세요"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        {/* 수신 동의 */}
        <div className="memberInfo-receive memberInfo-row">
          <label>수신 동의</label>
          <div className="input-group">
            <div className="memberInfo-receive-options">
              <label>
                <input
                  type="checkbox"
                  name="receive"
                  checked={receiveMail}
                  onChange={(e) => setReceiveMail(e.target.checked)}
                />
                우편 수령 동의
              </label>
              <label>
                <input
                  type="checkbox"
                  name="receive"
                  checked={receiveTextMessage}
                  onChange={(e) => setReceiveTextMessage(e.target.checked)}
                />
                문자 수신 동의
              </label>
            </div>
            <p className="memberInfo-receive-text">
              포캣밍센터에서 후원 아이들의 우편, 소식 등을 보내드립니다.
            </p>
          </div>
        </div>

        {/* 회원가입 버튼 */}
        <div className="memberInfo-submit memberInfo-row">
          <label></label>
          <div className="input-group">
            <button type="submit" onClick={handleSubmit}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberInfo;
