
import React, { useState } from 'react';
import Calendar from './Calendar'; 
import axios from 'axios'; 
import "./AdminDay.css"

const AdminDay = () => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAmount, setselectedAmount] = useState(0);
  
    const handleButtonClick = () => {
      setShowCalendar(true);
    };
  
    const handleDateSelect = (date) => {
      setSelectedDate(date);
      setShowCalendar(false);
      
      const formattedDate = date.toISOString().split('T')[0];
      const apiUrl = `https://forcatmung.kr/api/pay/termspay?start=${formattedDate}&end=${formattedDate}`;
      
      axios.get(apiUrl)
        .then(response => {
          setselectedAmount(response.data)
          console.log(selectedAmount)
        })
        .catch(error => {
          console.log("api 요청 실패", error)
        });
    };
  
    const closeCalendar = () => {
      setShowCalendar(false);
    };
  
    return (
      <>
      <div className='admin-donationday-button-container'>
        <button onClick={handleButtonClick} className='admin-donationday-button'>일자 선택</button>
        {showCalendar && (
          <Calendar
            startDate="2025-01-01"
            endDate={new Date().toISOString().split('T')[0]}
            onDateSelect={handleDateSelect}
            closeCalendar={closeCalendar}
          />
        )}
      </div>
      <div className='admin-donationday-content-container'>
      {selectedDate && (
        <p>{selectedDate.toLocaleDateString()} 당일 후원금 금액 : {selectedAmount}</p>
      )}
      </div>
      </>
    );
  };
  
export default AdminDay;

