import PageBanners from "../Banner/PageBanner/PageBanners";
import FAQList from "./FAQList";
import FaqHeader from "./FaqHeader";
import FaqMailAsk from "./FaqMailAsk";
import "./faq.css";
const Faq = () => {
  return (
    <main className="md-main">
      <PageBanners image={"/images/faq-images/faq_bg.png"} title={"FAQ"} subTitle={["궁금하신 내용에대해서 알려드립니다"]} />
      <section className="faq-section">
        <div className="page-conatiner">
          <FaqHeader />
          <FaqMailAsk />
          <FAQList />
        </div>
      </section>
    </main>
  );
};

export default Faq;
