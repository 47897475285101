import "./banner.css";

const SupportBanner = () => {
  return (
    <div className="support-container">
      <section className="intro-text">
        <div className="intro-text__container">
          <h1 className="intro-text__title">
            안녕하세요.
            <br /> 사단법인 포캣멍센터입니다.
          </h1>
          <p className="intro-text__p">
            동물들에게도 "동물 소외계층"의 아이들이 있다는 사실 알고계시나요? <br />
            <br />
            노령, 장애, 각종 질병의 아이들에게는
            <br /> 가족을 만날 수 있는 기회조차 어렵습니다.
            <br /> 아이들의 마지막 순간은 해피엔딩이 될 수 있도록 <br />
            <br /> 포캣멍센터와 동물보호 뜻을 함께해 주세요!
          </p>
        </div>
      </section>
      <section class="intro-banner">
        <div class="intro-banner__content">
          <h1 class="intro-banner__title">동물 보호의 뜻을 함께 해주세요!</h1>
          <p class="intro-banner__description">동물 소외계층 아이들에게 큰 힘이 되어주실래요?</p>
        </div>
        <div class="intro-banner__image-container">
          <img src="/images/tera-nim.png" alt="미용하는 태라님" class="intro-banner__image" />
        </div>
        <div className="intro-banner__circle-content">
          <div className="intro-banner__circle"></div>
        </div>
        <div className="intro-banner__circle-content circle-sm-contant">
          <div className="intro-banner__circle circle-sm"></div>
        </div>
      </section>
    </div>
  );
};

export default SupportBanner;
