import React, { useState } from "react";
import "./objective.css";
import Slider from "react-slick";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Objective = () => {
  const [giftDonation, setGiftDonation] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchDonation = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/dntExpense/goodsList`
        );
        setGiftDonation(response.data);
      } catch (error) {
        console.error("후원물품 리스트를 가져오는 중 오류 발생:", error);
      }
    };

    fetchDonation();
  }, []);

  return (
    <div className="objectives-slider-container">
      <div className="objectives-slider custom-slider">
        <Slider {...settings}>
          {giftDonation && giftDonation.length > 0
            ? giftDonation.map((item) => (
                <Link to={`/uselist/list/${item.id}`}>
                  <div key={item.id}>
                    <div className="objective-card">
                      <div className="objective-image">
                        <img src={item.imageUrl} alt="후원물품" />
                      </div>
                      <div className="objective-content">
                        <h3 className="objective-title">{item.title}</h3>
                        <div className="objective-donor">
                          기부자:{" "}
                          {item.donorName ? `${item.donorName}(님)` : "없음"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            : null}
        </Slider>
      </div>
    </div>
  );
};

export default Objective;
