import PageBanners from "../Banner/PageBanner/PageBanners";
import HeritageBottomBanner from "./HeritageBottomBanner";
import HeritageHowToParticipate from "./HeritageHowToParticipate";
import HeritageReward from "./HeritageReward";
import HeritageSubBanner from "./HeritageSubBanner";
import HeritageSupportType from "./HeritageSupportType";
import "./heritage.css";

const Heritage = () => {
  return (
    <main className="md-main">
      <PageBanners image={"/images/heritage-bg.png"} title={"유산기부"} subTitle={["사랑과 나눔으로 이어지는 특별한 선택"]} />
      <HeritageSubBanner />
      <HeritageSupportType />
      <HeritageHowToParticipate />
      <HeritageReward />
      <HeritageBottomBanner />
    </main>
  );
};

export default Heritage;
