import React, { useState } from "react";
import "./AskForHelpFormModal.css";
import personIcon from "./assets/ask-for-help-modal-icon.png";

function AskForHelpFormModal({ onSubmit, onClose }) {
  const [reportType, setReportType] = useState("구조 제보");
  const [name, setName] = useState("");
  const [contact, setContact] = useState({ first: "", middle: "", last: "" });
  const [email, setEmail] = useState({ username: "", domain: "" });
  const [date, setDate] = useState({ year: "", month: "", day: "" });
  const [info, setInfo] = useState("");
  const [password, setPassword] = useState("");
  const [agreement, setAgreement] = useState({ terms: false, privacy: false });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      reportType,
      name,
      contact,
      email,
      date,
      info,
      password,
      agreement,
    });
  };

  return (
    <div className="ask-for-help-form-whole">
      <div className="ask-for-help-modal-form-header-container">
        <div className="ask-for-help-modal-icon-container">
          <img
            src={personIcon}
            alt="Person Icon"
            className="ask-for-help-modal-icon"
          />
        </div>
        <div className="ask-for-help-modal-title-container">
          <h2 className="ask-for-help-modal-title">구조/학대 제보하기</h2>
          <h3 className="ask-for-help-modal-subtitle">
            구조가 필요한 아이들을 제보해주세요
          </h3>
        </div>
      </div>

      <div className="ask-for-help-form-divider" />

      <div className="ask-for-help-form-body">
        <div className="rescue-information-title">구조를 위한 정보</div>

        <form onSubmit={handleSubmit} className="ask-for-help-form">
          <div className="rescue-information-row-container">
            <label>제보 구분</label>
            <button
              type="button"
              className={`help-report-type-button ${
                reportType === "구조 제보" ? "active" : ""
              }`}
              onClick={() => setReportType("구조 제보")}
            >
              구조 제보
            </button>
            <button
              type="button"
              className={`help-report-type-button ${
                reportType === "학대 제보" ? "active" : ""
              }`}
              onClick={() => setReportType("학대 제보")}
            >
              학대 제보
            </button>
          </div>

          {/* 이름 */}
          <div className="rescue-information-row-container">
            <label>이름</label>
            <input
              type="text"
              className="name-input-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="정확하게 작성해주세요"
            />
          </div>
          {/* 연락처 */}
          <div className="rescue-information-row-container">
            <label>연락처</label>
            <div className="contact-inputs">
              <select
                className="ask-for-help-contact-input"
                value={contact.first}
                onChange={(e) =>
                  setContact({ ...contact, first: e.target.value })
                }
              >
                <option value="010">010</option>
                <option value="011">011</option>
                <option value="016">016</option>
                <option value="017">017</option>
                <option value="018">018</option>
                <option value="019">019</option>
              </select>
              -
              <input
                className="ask-for-help-contact-input"
                type="text"
                value={contact.middle}
                onChange={(e) =>
                  setContact({ ...contact, middle: e.target.value })
                }
              />
              -
              <input
                className="ask-for-help-contact-input"
                type="text"
                value={contact.last}
                onChange={(e) =>
                  setContact({ ...contact, last: e.target.value })
                }
              />
            </div>
          </div>

          {/* 이메일 */}
          <div className="rescue-information-row-container">
            <label>이메일</label>
            <div className="email-inputs">
              <input
                className="ask-for-help-email-first-input"
                type="text"
                value={email.username}
                onChange={(e) =>
                  setEmail({ ...email, username: e.target.value })
                }
                placeholder="이메일을 작성해주세요"
              />
            </div>
          </div>

          {/* 최초 발견 날짜 */}
          <div className="rescue-information-row-container">
            <label>최초 발견 날짜</label>
            <div className="form-group">
              <select
                className="first-found-date-select"
                value={date.year}
                onChange={(e) => setDate({ ...date, year: e.target.value })}
              >
                <option value="">YYYY</option>
                {Array.from({ length: 2099 - 2020 + 1 }, (_, i) => {
                  const year = 2020 + i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              <select
                className="first-found-date-select"
                value={date.month}
                onChange={(e) => setDate({ ...date, month: e.target.value })}
              >
                <option value="">MM</option>
                {Array.from({ length: 12 }, (_, i) => {
                  const month = (i + 1).toString().padStart(2, "0");
                  return (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
              <select
                className="first-found-date-select"
                value={date.day}
                onChange={(e) => setDate({ ...date, day: e.target.value })}
              >
                <option value="">DD</option>
                {Array.from({ length: 31 }, (_, i) => {
                  const day = (i + 1).toString().padStart(2, "0");
                  return (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* 구조에 필요한 정보 */}
          <div className="rescue-information-row-container">
            <label>구조에 필요한 정보</label>
            <textarea
              className="rescue-information-text-area"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              placeholder="개체, 나이, 현장상황 등 아이에 대한 정보를 기입해주세요."
            />
          </div>

          <div className="rescue-information-row-container">
            <label htmlFor="file-upload" className="upload-button">
              제보 사진
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  console.log("첨부된 파일:", file);
                }
              }}
            />
          </div>

          <div className="rescue-cooperation-row-container">
            <label>
              제보자 협력 <br />
              (중복체크 가능)
            </label>
            <div className="cooperation-options">
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value="일시 후원"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                일시 후원
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value="정기 후원"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                정기 후원
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value="구조 차량 지원"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                구조 차량 지원
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value="임시 보호"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                임시 보호
              </label>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  value="이동 차량 지원"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                이동 차량 지원
              </label>
              <label className="custom-checkbox other-option">
                <input
                  type="checkbox"
                  value="그 외"
                  onChange={(e) => console.log(e.target.value)}
                />
                <span className="checkmark"></span>
                그 외
                <input
                  type="text"
                  className="other-help-text"
                  placeholder="작성해주세요"
                  onChange={(e) => console.log(e.target.value)}
                />
              </label>
            </div>
          </div>

          {/* 비밀번호 */}
          <div className="rescue-information-row-container">
            <label>비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력해주세요"
            />
          </div>

          {/* 동의 체크박스 */}
          <div className="rescue-information-row-container">
            <label>
              <input
                type="checkbox"
                checked={agreement.terms}
                onChange={(e) =>
                  setAgreement({ ...agreement, terms: e.target.checked })
                }
              />
              [필수] 이용약관 동의
            </label>
            <label>
              <input
                type="checkbox"
                checked={agreement.privacy}
                onChange={(e) =>
                  setAgreement({ ...agreement, privacy: e.target.checked })
                }
              />
              [필수] 개인정보 처리방침 동의
            </label>
          </div>

          {/* 버튼들 */}
          <div className="form-buttons">
            <button type="button" onClick={onClose} className="prev-button">
              이전
            </button>
            <button type="submit" className="submit-button">
              제출하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AskForHelpFormModal;
