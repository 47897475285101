import React, { useState, useRef, useEffect } from 'react';
import './Volunteering.css';
import Calendar from './Calendar'; // Calendar 컴포넌트 경로에 맞게 수정
import { Link } from "react-router-dom";
import axios from 'axios';

const VolunteeringModal = ({ isOpen, onClose, startDate, endDate, kind, onOpenB }) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const inputRef = useRef(null);
    const [emailDomain, setEmailDomain] = useState('naver.com');
    const [isCustomDomain, setIsCustomDomain] = useState(false);
    const [name, setName] = useState("");
    const [contact1, setContact1] = useState("010");
    const [contact2, setContact2] = useState("");
    const [contact3, setContact3] = useState("");
    const [email1, setEmail1] = useState("");
    const [customEmailDomain, setCustomEmailDomain] = useState("");
    const [notes, setNotes] = useState("");
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [fullEmail, setFullEmail] = useState(""); // 완성된 이메일

      const handleDomainChange = (value) => {
        if (value === "직접입력") {
            setIsCustomDomain(true);
            setEmailDomain(""); // 초기화
        } else {
            setIsCustomDomain(false);
            setEmailDomain(value);
        }
    };

    const handleInputClick = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setIsCalendarOpen(false); // 날짜 선택 후 캘린더 닫기
    };

    // 외부 클릭 시 캘린더 닫기
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsCalendarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleSubmit = async () => {
        const selectedEmailDomain = isCustomDomain ? customEmailDomain : emailDomain;
        const isInputValid =
            name &&
            contact1 &&
            contact2 &&
            contact3 &&
            email1 &&
            selectedEmailDomain &&
            selectedDate &&
            notes;
        const isCheckboxValid = checkbox1 && checkbox2 && checkbox3;
        const domain = isCustomDomain ? customEmailDomain : emailDomain;
        setFullEmail(email1 && domain ? `${email1}@${domain}` : ""); 

        if (!isInputValid && !isCheckboxValid) {
            alert("모든 필수 항목을 입력하고 체크박스를 선택해주세요.");
        } else if (!isInputValid) {
            alert("모든 필수 항목을 입력해주세요.");
        } else if (!isCheckboxValid) {
            alert("모든 체크박스에 동의해주세요.");
        } else {
            const params = {
                volunteeringId: 8, //일단 강제
            };

            const body = {
                name : name,
                phone: `${contact1}${contact2}${contact3}`,
                email : fullEmail,
                selectedDate : selectedDate.toISOString().split('T')[0],
                notes : notes,
                personalCheck : true,
                termsCheck : true
            };

            try {
                const response = await axios.post(
                    "https://forcatmung.kr/api/volunteering/register/create",
                    body,
                    {
                        params: params,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                onOpenB(); // 조건 충족 시 함수 호출
            }
            
            catch (error) {
                console.error("봉사활동 신청에 실패하였습니다.", error);
                alert("신청 중 오류가 발생하였습니다.");
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="volunteering-modal">
            <div className="volunteering-modal-content">
                <div className="volunteering-title-area">
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="volunteering-title-area">
                    <img src="/images/volunteeringIcon.png" alt="이미지" className="volunteering-modal-image" />
                    <div className="volunteering-text-area">
                        <h2 className="volunteering-text1">봉사 활동 신청</h2>
                        <h3 className="volunteering-text2">포캣멍센터 동물들에게 도움의 손길을 내어주세요요</h3>
                    </div>
                </div>

                {/* 구분선 추가 */}
                <hr className="volunteering-modal-divider" />

                <div className="volunteering-form-area">
                    <p className='volunteering-form-title'>봉사자 정보</p>
                    
                    <div className='volunteering-form-area-div'>
                        <label htmlFor="field">분야</label>
                        <button id="field" placeholder={`${kind} 봉사`} className='volunteering-form-button' disabled>{kind} 봉사</button>
                    </div>

                    <div className='volunteering-form-area-div'>
                        <label htmlFor="name">이름</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='volunteering-form-input1' placeholder="정확하게 작성해주세요"/>
                    </div>

                    <div className='volunteering-form-area-div'>
                        <label htmlFor="contact">연락처</label>
                        <select value={contact1} onChange={(e) => setContact1(e.target.value)} className='volunteering-form-selecttion1'>
                            <option value="010">010</option>
                            <option value="011">011</option>
                            <option value="012">012</option>
                            <option value="013">013</option>
                            <option value="014">014</option>

                        </select>
                        <span className='volunteering-span-margin ' >-</span>
                        <input type="number" id="contact2" value={contact2} onChange={(e) => {const value = e.target.value.slice(0, 4); setContact2(value); }} className='volunteering-form-input2' />
                        <span className='volunteering-span-margin ' >-</span>
                        <input type="number" id="contact3" value={contact3} onChange={(e) => {const value = e.target.value.slice(0, 4); setContact3(value); }} className='volunteering-form-input2'/>
                    </div>

                    <div className='volunteering-form-area-div'>

                    <label htmlFor="email">이메일</label>
                        <input type="text" value={email1} onChange={(e) => setEmail1(e.target.value)} placeholder='이메일을 입력해주세요' className='volunteering-form-input1'/>
                        <span style={{margin:"0 10px"}}> @ </span>
                        {isCustomDomain ? (
                        <input
                            type="text"
                            value={customEmailDomain}
                            onChange={(e) => setCustomEmailDomain(e.target.value)}
                            className='volunteering-form-input1'
                            placeholder='직접 입력'
                        />
                        ) : (
                        <select
                            value={emailDomain}
                            onChange={(e) => handleDomainChange(e.target.value)}
                            className='volunteering-form-input1'
                        >
                            <option value="naver.com">naver.com</option>
                            <option value="google.com">google.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="직접입력">직접 입력</option>
                        </select>
                        )}
                    </div>

                    <div className='volunteering-form-area-div'>
                        <label htmlFor="schedule">신청일정</label>

                        <div ref={inputRef} className="date-selection">

                            <div className="custom-date-input" onClick={handleInputClick}>
                                <span className="date-icon"></span>
                                <div className="date-text-container">
                                    <span className="date-label">날짜를 선택해주세요</span>
                                    <span className="selected-date">{selectedDate ? selectedDate.toLocaleDateString() : ""}</span>
                                </div>
                                </div>

                            {/* 캘린더 컴포넌트 */}
                            {isCalendarOpen && (
                                <div className="calendar-container">
                                    <Calendar 
                                        startDate={startDate} 
                                        endDate={endDate} 
                                        onDateSelect={handleDateSelect} 
                                        closeCalendar={() => setIsCalendarOpen(false)}
                                    />
                                    {/* 선택된 날짜 표시 */}
                                    
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={`volunteering-form-area-div ${isCalendarOpen ? 'shifted' : ''}`}>
                        <label htmlFor="notes" >전하고 싶은 말</label>
                        <input type="text"  value={notes} onChange={(e) => setNotes(e.target.value)} className='volunteering-form-input3' placeholder='봉사활동의 경험 또는 관련 업무의 경험에 대해서 작성해주세요'/>
                    </div>
                </div>

                <div className="checkbox-area">
                    <div className="checkbox-item" style={{marginTop:"50px"}}>
                        <input type="checkbox" checked={checkbox1} onChange={(e) => setCheckbox1(e.target.checked)} />
                        <label htmlFor="checkbox1" className="volunteering-checkbox-text">[필수] 제공한 개인정보는 봉사활동진행에 사용되며, 봉사 후 6개월 내에 폐기 됩니다. </label>
                    </div>
                    </div>
                    <hr className="volunteering-modal-divider" />

                    <div className="checkbox-area">
                    <div className="checkbox-item">
                        <input type="checkbox" checked={checkbox2} onChange={(e) => setCheckbox2(e.target.checked)} />
                        <label htmlFor="checkbox2" className="volunteering-checkbox-text">[필수] 이용약관 동의</label><Link to="/terms" className="view-terms" target="_blank" rel="noopener noreferrer">[보기]</Link>
                    </div>
                    <div className="checkbox-item">
                        <input type="checkbox" checked={checkbox3} onChange={(e) => setCheckbox3(e.target.checked)} />
                        <label htmlFor="checkbox3" className="volunteering-checkbox-text" >[필수] 개인정보 처리방침 동의</label> <Link to="/personal" className="view-terms" target="_blank" rel="noopener noreferrer">[보기]</Link>
                    </div>
                </div>

                <div className="volunteering-button-area">
                    <button className='volunteering-close-button' onClick={onClose}>이전</button>
                    <button className="volunteering-next-button" onClick={handleSubmit}>제출하기</button>
                </div>

                {/* Hidden date input (optional) */}
                {/* You can remove this if not needed */}
                {/*<input type="date" id="calendar" style={{ display: 'none' }} />*/}
            </div>
        </div>
    );
};

export default VolunteeringModal;
