import React, { useState, useEffect, useCallback } from "react";
import "./DonationUseList.css";
import DonationUseListItem from "./DonationUseListItem.jsx";
import axios from "axios";
import { Link } from "react-router-dom";

const DonationUseListList = () => {
  const [donationUseList, setDonationUseList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const group = 5;

  // 디폴트 이미지 수정하기
  const defaultImage = `${process.env.PUBLIC_URL}/Forcatmung.png`;

  const fetchDonationUseList = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/dntExpense/notfanclub/list?page=${
          currentPage - 1
        }&size=${itemsPerPage}`
      );
      console.log(response.data);

      const { currentPage: apiCurrentPage, totalPages, list } = response.data;
      setDonationUseList(list);
      setCurrentPage(apiCurrentPage + 1);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching news:", error);
      setError("후원내역을 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchDonationUseList();
  }, [fetchDonationUseList]);

  const fetchSearchResults = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(
        `https://forcatmung.kr/api/notfanculb/search?keyword=${encodeURIComponent(
          search
        )}`
      );
      const { list, totalCount } = response.data;
      setDonationUseList(list);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("검색 결과를 불러오는 데 실패했습니다. 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClick = () => {
    if (search.trim()) {
      fetchSearchResults();
    }
  };

  const handlePageChange = (pageNumber) => {
    if (search.trim()) {
      fetchSearchResults();
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageButton = () => {
    const currentGroup = Math.ceil(currentPage / group);
    const startPage = (currentGroup - 1) * group + 1;
    const endPage = Math.min(currentGroup * group, totalPages);

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      );
    });
  };

  return (
    <div className="donation-uselist-list-container">
      <div className="donation-uselist-list-title">
        <div className="donation-uselist-list-title-search">
          <input
            type="search"
            placeholder="검색어를 입력하세요"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>
      {loading ? (
        <p>로딩 중...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {donationUseList.map((item) => (
            <Link
              to={`/uselist/list/${item.id}`}
              key={item.id}
              // onClick={handleNavigate}
            >
              <DonationUseListItem
                key={item.id}
                id={item.id}
                imageUrl={item.imageUrl || defaultImage}
                title={item.title}
                author={item.writer}
                date={item.donation_date}
                content={item.text}
                type={item.type}
              />
            </Link>
          ))}
        </div>
      )}
      {!loading && !error && (
        <div className="uselist-pagination">
          {currentPage > group && (
            <button onClick={() => handlePageChange(currentPage - group)}>
              이전
            </button>
          )}
          {renderPageButton()}
          {currentPage < totalPages - group + 1 && (
            <button onClick={() => handlePageChange(currentPage + group)}>
              다음
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DonationUseListList;
