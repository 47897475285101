
import React, { useState } from 'react';
import Calendar from './Calendar'; 
import axios from 'axios'; 
import "./AdminTerm.css"

const AdminTerm = () => {
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedAmount, setselectedAmount] = useState(0)
    const [dateType, setDateType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const handleStartButtonClick = () => {
        setDateType('start');
        setShowCalendar(true);
      };
    
      const handleEndButtonClick = () => {
        setDateType('end');
        setShowCalendar(true);
      };
  
    const handleDateSelect = (date) => {
        if (dateType === 'start') {
            setStartDate(date);
          } else {
            setEndDate(date);
          }

      setShowCalendar(false);
     
    };
  
    const closeCalendar = () => {
      setShowCalendar(false);
    };
    
    const handleRequest = () => {
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        const apiUrl = `https://forcatmung.kr/api/pay/termspay?start=${formattedStartDate}&end=${formattedEndDate}`;
        
        axios.get(apiUrl)
          .then(response => {
            setselectedAmount(response.data)
            console.log(selectedAmount)
          })
          .catch(error => {
            console.log("api 요청 실패", error)
          });

    }
  
    return (
      <>
      <div className='admin-donationterm-button-container'>
        <div className='admin-donationterm-div '>
        <button onClick={handleStartButtonClick} className='admin-donationterm-button'>시작일</button>
        <button onClick={handleEndButtonClick} className='admin-donationterm-button'>종료일</button>
        <button onClick={handleRequest} className='admin-donationterm-button'>보기</button>
        </div>
        {showCalendar && (
          <Calendar
            startDate="2025-01-01"
            endDate={new Date().toISOString().split('T')[0]}
            onDateSelect={handleDateSelect}
            closeCalendar={closeCalendar}
          />
        )}
      </div>
      <div className='admin-donationterm-content-container'>
        <div className='admin-donationterm-content-title'>
         {startDate && <p>선택된 시작일: {startDate.toLocaleDateString()}</p>}
         {endDate && <p style={{marginLeft:"5%"}}>선택된 종료일: {endDate.toLocaleDateString()}</p>} 
         <br /> <p style={{marginLeft:"5%"}}> 당일 후원금 금액 : {selectedAmount}</p>
        </div>
      </div>
      </>
    );
  };
  
export default AdminTerm;

