// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1025px) {
  .ask-for-help-banner {
    height: 300px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .ask-for-help-header {
    flex-direction: column;
    padding: 10px;
    gap: 30px;
  }

  .ask-for-help-text-line {
    width: 100%;
  }

  .what-is-animal-abuse {
    flex-direction: column;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    gap: 15px;
    padding: 5px;
  }

  .animal-abuse-report {
    flex-direction: column;
    margin-top: 50px;
  }

  .animal-abuse-report-text {
    margin-left: 0;
  }

  .animal-abuse-report-divider {
    display: none;
  }

  .animal-rescue-banner__content {
    padding: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .check-before-text-container {
    padding: 20px;
    line-height: 1.2;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AskForHelp/AskForHelp.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,2CAA2C;EAC7C;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,2CAA2C;IAC3C,SAAS;IACT,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,2CAA2C;EAC7C;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":["@media (max-width: 1025px) {\n  .ask-for-help-banner {\n    height: 300px;\n    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);\n  }\n\n  .ask-for-help-header {\n    flex-direction: column;\n    padding: 10px;\n    gap: 30px;\n  }\n\n  .ask-for-help-text-line {\n    width: 100%;\n  }\n\n  .what-is-animal-abuse {\n    flex-direction: column;\n    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);\n    gap: 15px;\n    padding: 5px;\n  }\n\n  .animal-abuse-report {\n    flex-direction: column;\n    margin-top: 50px;\n  }\n\n  .animal-abuse-report-text {\n    margin-left: 0;\n  }\n\n  .animal-abuse-report-divider {\n    display: none;\n  }\n\n  .animal-rescue-banner__content {\n    padding: 10px;\n    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);\n  }\n\n  .check-before-text-container {\n    padding: 20px;\n    line-height: 1.2;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
