import axios from "axios";
const BASE_URL = "https://forcatmung.kr/";
// 동물 상세 정보 가져오기
export const getAnimalDetail = async (animalid) => {
    try {
        const response = await axios.get(`${BASE_URL}api/animaldetail/onetomany/detail`, {
            params: { animalid },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching animal details:", error);
        throw error;
    }
};
