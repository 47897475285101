import React, { useState, useEffect } from "react";
import SmartEditor from "../../SmartEditor/SmartEditor";
import "./VolunteeringCreate.css";
import axios from "axios";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";

registerLocale("ko", ko);

const VolunteeringUpdate = () => {
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [content, setContent] = useState("");
  const [address, setAddress] = useState("");
  const [totalPerson, setTotalPerson] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedKind, setSelectedKind] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [volunteeringPk, setVolunteeringPk] = useState("");
  const navigator = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.item) {
      const item = location.state.item;
      setTitle(item.title || "");
      setContent(item.content || "");
      setSelectedKind(item.kind || "");
      setSelectedStatus(item.status?.toString() || "");
      setStartDate(item.startDate ? new Date(item.startDate) : null);
      setEndDate(item.endDate ? new Date(item.endDate) : null);
      setTotalPerson(item.totalPerson?.toString() || "");
      setVolunteeringPk(item.volunteeringPk || "");
      setAddress(item.address || "");
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !selectedKind || !selectedStatus || !startDate || !endDate || !totalPerson || !address) {
      alert("전부 입력해주세요");
      return;
    }

    if (!window.confirm("수정하시겠습니까?")) return;

    const formData = new FormData();
    const jsonData = {
      title,
      content,
      kind: selectedKind,
      status: parseInt(selectedStatus, 10),
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      totalPerson: parseInt(totalPerson, 10),
      address,
    };

    if (imageFile) formData.append("imageData", imageFile);
    formData.append("volunteering", new Blob([JSON.stringify(jsonData)], { type: "application/json" }));

    try {
      const response = await axios.put(
        `https://forcatmung.kr/api/volunteering/update?volunteeringId=${volunteeringPk}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert("수정이 완료되었습니다.");
      navigator("/adminpage/volunteering");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("수정 실패", error);
      alert("수정에 실패하였습니다.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <main className="volunteering-create">
        <div className="admin-adoption-header">
          <h2 className="admin-adoption-table">봉사활동 수정</h2>
          <div>
            <Link to="/adminpage/volunteering">
              <button type="button" className="admin-regist-button">목록으로</button>
            </Link>
            <button type="submit" className="admin-regist-button">수정하기</button>
          </div>
        </div>

        <div className="volunteering-create__title">
          <p>제목</p>
          <textarea value={title} onChange={(e) => setTitle(e.target.value)} placeholder="제목을 입력해주세요." />
        </div>

        <div className="volunteering-create__image">
          <p>대표 이미지 선택</p>
          <input type="file" accept="image/*" onChange={(e) => setImageFile(e.target.files[0])} />
        </div>
        <p style={{ fontSize: "12px" }}>※10MB 이하의 사진만 넣어주세요.</p>

        <div className="volunteering-create__address">
          <p>주소</p>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="ex) 경기도 안산시"
          />
        </div>

        <div className="volunteering-create__category-container">
          <div className="volunteering-create__people">
            <p>모집인원</p>
            <input
              type="number"
              value={totalPerson}
              onChange={(e) => setTotalPerson(e.target.value)}
              placeholder="ex) 8"
              style={{ height: "31px", width: "81px" }}
            />
            <p>명</p>
          </div>

          <div className="volunteering-create__people">
            <p>모집상태</p>
            <select
              className=" volunteering-create__menu"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}>
              <option value="" disabled>모집상태</option>
              <option value="0">모집 예정</option>
              <option value="1">모집 중</option>
              <option value="2">모집 마감</option>
            </select>
          </div>
        </div>

        <div className="volunteering-create__calendar-section">
          <div className="volunteering-create__calendar-wrapper">
            <p>모집 시작일</p>
            <DatePicker selected={startDate} onChange={setStartDate} inline locale="ko" />
          </div>
          <img src="/icons/right_arrow.png" alt="화살표" className="volunteering-create__calendar-arrow" />
          <div className="volunteering-create__calendar-wrapper">
            <p>모집 종료일</p>
            <DatePicker selected={endDate} onChange={setEndDate} inline locale="ko" />
          </div>
        </div>

        <div className="volunteering-create__content">
          <p>상세정보</p>
          <SmartEditor initialContent={content} onContentChange={setContent} />
        </div>
      </main>
    </form>
  );
};

export default VolunteeringUpdate;
