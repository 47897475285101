import axios from "axios";
const BASE_URL = "https://forcatmung.kr/";

// 팬 후원내역 리스트
export const getDntExpense = async (currentPage, pageSize) => {
    try {
        const response = await axios.get(`${BASE_URL}api/dntExpense/fanclub/list`, {
            params: {
                page: currentPage - 1,
                size: pageSize
            },
        });

        if (response && response.data) {
            return {
                list: response.data.fanclubList || [],
                totalPages: response.data.totalPages || 1,
            };
        } else {
            console.warn("응답 데이터가 없습니다.");
            return { list: [], totalPages: 1 };
        }
    } catch (error) {
        console.error("Error fetching donation expenses:", error);
        throw error;
    }
};

// 팬 후원내역 검색
export const searchDntExpense = async (keyword) => {
    try {
        const response = await axios.get(`${BASE_URL}api/dntExpense/fanclub/search`, {
            params: { keyword },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching animal details:", error);
        throw error;
    }
};
