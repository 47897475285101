import { useState } from "react";
import SmartEditor from "../../SmartEditor/SmartEditor";
import "./VolunteeringCreate.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);

const VolunteeringCreate = () => {
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [content, setContent] = useState("");
  const [address, setAddress] = useState("");
  const [totalPerson, setTotalPerson] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigator = useNavigate();
  const [selectedKind, setSelectedKind] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleSelectChangeKind = (e) => {
    setSelectedKind(e.target.value);
  };

  const handleSelectChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !content || !selectedKind || !selectedStatus || !startDate || !endDate || !totalPerson) {
      alert("전부 입력해주세요");
      return;
    }

    const isConfirmed = window.confirm("작성하시겠습니까?");

    if (isConfirmed) {
      const formData = new FormData();
      let jsonData = {
        title: title,
        content: content,
        kind: selectedKind,
        status: parseInt(selectedStatus, 10),
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        totalPerson: parseInt(totalPerson, 10),
        address: address,
      };

      if (imageFile) formData.append("imageData", imageFile);

      formData.append("volunteering", new Blob([JSON.stringify(jsonData)], { type: "application/json" }));

      try {
        const response = await axios.post("https://forcatmung.kr/api/volunteering/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("게시글 작성 성공", response.data);
        navigator("/mainmain/volunteering");
        window.scrollTo(0, 0);
      } catch (error) {
        console.error("작성 실패", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <main className="volunteering-create">

        <div className="volunteering-create__title">
          <p>제목</p>
          <textarea
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력해주세요."
          />
        </div>

        <div className="volunteering-create__img-select">
          <p>대표 이미지 선택</p>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            placeholder="이미지 선택"
          />
        </div>
        <p className="volunteering-create__textarea-margin" style={{ fontSize: "12px" }}>
          &#8251;10MB이하의 사진만 넣어주세요.
        </p>

        <div className="volunteering-create__address">
          <p>주소</p>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="ex) 경기도 안산시"
            type="text"
            className="volunteering-create__textarea-margin"
          />
        </div>

        <div className="volunteering-create__category-container">
          <div className="volunteering-create__people">
            <p>모집인원</p>
            <input
              value={totalPerson}
              onChange={(e) => setTotalPerson(e.target.value)}
              placeholder="ex) 8"
              type="number"
              className="volunteering-create__textarea-margin"
              onWheel={(e) => e.target.blur()}
              style={{ height: "31px", width: "81px", paddingBlock: "0" }}
            />
            <p>명</p>
          </div>

          <div className="volunteering-create__people">
            <p>모집상태</p>
            <select
              className="volunteering-create__textarea-margin volunteering-create__menu"
              value={selectedKind}
              onChange={handleSelectChangeKind}
            >
              <option value="" disabled>종류선택</option>
              <option value="beauty" className="volunteering-create__select-option">미용봉사</option>
              <option value="training" className="volunteering-create__select-option">훈련봉사</option>
              <option value="walk" className="volunteering-create__select-option">산책봉사</option>
              <option value="cleaning" className="volunteering-create__select-option">청소봉사</option>
              <option value="relocation" className="volunteering-create__select-option">이동봉사</option>
            </select>
          </div>

          <div className="volunteering-create__people">
            <p>모집분야</p>
            <select
              className="volunteering-create__menu"
              value={selectedStatus}
              onChange={handleSelectChangeStatus}
            >
              <option value="" disabled>모집상태</option>
              <option value="0" className="volunteering-create__select-option">모집 예정</option>
              <option value="1" className="volunteering-create__select-option">모집 중</option>
              <option value="2" className="volunteering-create__select-option">모집 마감</option>
            </select>
          </div>
        </div>

        <div className="volunteering-create__calendar-section">
          <div className="volunteering-create__calendar-wrapper">
            <p>모집 시작일</p>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              inline
              locale="ko"
              dateFormat="yyyy-MM-dd"
            />
          </div>

          <img src="/icons/right_arrow.png" alt="화살표" className="volunteering-create__calendar-arrow" />

          <div className="volunteering-create__calendar-wrapper">
            <p>모집 종료일</p>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              inline
              locale="ko"
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>

        <div className="volunteering-create__content">
          <p>상세정보</p>
          <SmartEditor onContentChange={setContent} />
        </div>

      </main>
    </form>
  );
};

export default VolunteeringCreate;