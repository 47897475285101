import React, { useState, useEffect } from "react";
import TalentSliderData from "./TalentSliderData";
import "./TalentSlider.css";

const TalentSlider = () => {
  const [donorCount, setDonorCount] = useState(0);
  const [isAdmin] = useState(false);
  const [slidersWithTooltips, setSlidersWithTooltips] = useState(
    TalentSliderData()
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    setDonorCount(25);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTooltipChange = (index, event) => {
    const updatedSliders = slidersWithTooltips.map((slider, i) =>
      i === index ? { ...slider, tooltip: event.target.value } : slider
    );
    setSlidersWithTooltips(updatedSliders);
  };

  const handleAddImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const newSlider = { image: reader.result, tooltip: "새로운 이미지" };
        const updatedSliders = [...slidersWithTooltips, newSlider];
        setSlidersWithTooltips(updatedSliders);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditImage = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedSliders = slidersWithTooltips.map((slider, i) =>
          i === index ? { ...slider, image: reader.result } : slider
        );
        setSlidersWithTooltips(updatedSliders);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedSliders = slidersWithTooltips.filter((_, i) => i !== index);
    setSlidersWithTooltips(updatedSliders);
  };

  const moveRight = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % slidersWithTooltips.length
    );
  };

  const moveLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slidersWithTooltips.length - 1 : prevIndex - 1
    );
  };

  const visibleSliders = isMobile ? 2 : 4;
  const currentSliders = slidersWithTooltips.slice(
    currentIndex,
    currentIndex + visibleSliders
  );
  if (currentSliders.length < visibleSliders) {
    currentSliders.push(
      ...slidersWithTooltips.slice(0, visibleSliders - currentSliders.length)
    );
  }

  return (
    <div className="my-component">
      <div className="slider-container">
        <div className="circle-container">
          <button onClick={moveLeft} className="slider-button left"></button>
          {currentSliders.map((slider, index) => (
            <div
              key={index}
              className={`circle-wrapper ${
                isMobile && index >= 2 ? "mobile-hidden" : ""
              }`}
              onMouseEnter={() => isAdmin && setHoveredIndex(index)}
              onMouseLeave={() => isAdmin && setHoveredIndex(null)}
            >
              <div className={`circle ${index === 1 ? "circle-second" : ""}`}>
                <img
                  src={slider.image}
                  alt={`Slide ${index}`}
                  className="circle-image"
                />
                {isAdmin && hoveredIndex === index && (
                  <div className="edit-overlay">
                    <textarea
                      value={slider.tooltip}
                      onChange={(event) =>
                        handleTooltipChange(currentIndex + index, event)
                      }
                      className="tooltip-textarea"
                    />
                    <label
                      htmlFor={`file-input-edit-${currentIndex + index}`}
                      className="edit-label"
                    >
                      수정
                    </label>
                    <input
                      type="file"
                      id={`file-input-edit-${currentIndex + index}`}
                      className="file-input"
                      accept="image/*"
                      onChange={(event) =>
                        handleEditImage(currentIndex + index, event)
                      }
                    />
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteImage(currentIndex + index)}
                    >
                      삭제
                    </button>
                  </div>
                )}
                <div className="tooltip">
                  <p className="tooltip-text">{slider.tooltip}</p>
                </div>
              </div>
            </div>
          ))}
          <button onClick={moveRight} className="slider-button right"></button>
          <div className="talent-text">
            <p className="talent-text-first">함께 해주신 재능기부자분</p>
            <p className="talent-text-second">{donorCount}명</p>
            <p className="talent-text-third">감사합니다.</p>
          </div>
        </div>
      </div>

      {isAdmin && (
        <div className="add-image-container">
          <label htmlFor="file-input-add" className="add-image-label">
            이미지 추가
          </label>
          <input
            type="file"
            id="file-input-add"
            className="file-input"
            accept="image/*"
            onChange={handleAddImage}
          />
        </div>
      )}
    </div>
  );
};

export default TalentSlider;
