import React, { useCallback, useEffect, useState } from "react";
import AdoptionPagenation from "./AdoptionPagenation";
import Modal from "../MyPage/Modal";
import AdoptionDetail from "./AdoptionDetail";
import "./AdoptionList.css";
import AdoptionModalFrame from "./AdoptionModalFrame";
import AdoptionCheckList from "./AdoptionCheckList";
import AdoptionInputComponent from "./AdoptionInputComponent";
import AdoptionCompleteComment from "./AdoptionCompleteComment";
import axios from "axios";

const AdoptionList = () => {
  const adoptionRequestUrl = "https://forcatmung.kr/api/adoption";
  const postsPerPage = 9;
  const [adoptionPosts, setAdoptionPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("입양가능");
  const [selectedAnimal, setSelectedAnimal] = useState("dog");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedAnimalNumber, setSelectedAnimalNumber] = useState("");

  const { currentData, currentPage, totalPages, handlePageChange } =
    AdoptionPagenation(filteredPosts, postsPerPage);

  //입양하기 동물가져오기
  useEffect(() => {
    const getAdoptionPosts = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/animal/posts`
        );

        const modifiedData = response.data.map((post) => {
          const { animalid, animaladptionstatus, ...rest } = post;
          return {
            animalNumber: animalid,
            adoptionStatus: animaladptionstatus,
            ...rest,
          };
        });

        setAdoptionPosts(modifiedData);
        setFilteredPosts(modifiedData);
      } catch (error) {
        if (error.response) {
          console.error("서버 응답 오류:", error.response.data);
          console.error("응답 상태 코드:", error.response.status);
        } else if (error.request) {
          console.error("서버로부터 응답을 받지 못했습니다:", error.request);
        } else {
          console.error("요청 설정 오류:", error.message);
        }
      }
    };
    getAdoptionPosts();
  }, []);

  const handleFilterChange = useCallback(
    (status, animal) => {
      let filtered = adoptionPosts;

      if (animal === "dog") {
        filtered = filtered.filter((post) => post.animalNumber.startsWith("D"));
      } else if (animal === "cat") {
        filtered = filtered.filter((post) => post.animalNumber.startsWith("C"));
      } else if (animal === "etc") {
        filtered = filtered.filter(
          (post) =>
            !post.animalNumber.startsWith("C") &&
            !post.animalNumber.startsWith("D")
        );
      }

      if (status) {
        filtered = filtered.filter((post) => post.adoptionStatus === status);
      }

      setFilteredPosts(filtered);
    },
    [adoptionPosts]
  );

  useEffect(() => {
    handleFilterChange(selectedStatus, selectedAnimal);
  }, [selectedStatus, selectedAnimal, handleFilterChange]);

  const handleAnimalChange = (e) => {
    const animal = e.target.value;
    setSelectedAnimal(animal);
    handleFilterChange(selectedStatus, animal);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    handleFilterChange(status, selectedAnimal);
  };

  const openModal = (post) => {
    setSelectedPost(post);
    setCurrentStep(1);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextStep = async (formDataToSend, animalNumber) => {
    if (currentStep === 1) setSelectedAnimalNumber(animalNumber);
    if (currentStep === 3) {
      try {
        // 서버로 formData를 전송
        const response = await axios.post(
          `${adoptionRequestUrl}/create`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          // console.log("서버 응답:", response.data);
        }
        setCurrentStep(4);
      } catch (error) {
        if (error.response) {
          // 서버에서 반환한 에러 응답
          console.error("서버 응답 오류:", error.response.data);
          console.error("응답 상태 코드:", error.response.status);
        } else if (error.request) {
          // 요청은 보냈지만 응답을 받지 못한 경우
          console.error("서버로부터 응답을 받지 못했습니다:", error.request);
        } else {
          // 요청 설정 중에 발생한 에러
          console.error("요청 설정 오류:", error.message);
        }
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderPosts = () => {
    return currentData.map((post) => (
      <div
        key={post.animalNumber}
        className="post-item"
        onClick={() => openModal(post)}
      >
        <img src={post.imageurl} alt={post.title} className="animal-image" />
        <div className="amimal-info-container">
          <h4>{post.animalNm}</h4>
          <div className="amimal-info">
            <p>{post.breeds} / </p>
            <p>{post.age}살 /</p>
            <p>{post.gender}</p>
          </div>
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    const visiblePageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      visiblePageNumbers.push(i);
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="arrow-button"
        >
          &laquo;
        </button>
        {visiblePageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`page-button ${number === currentPage ? "active" : ""}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="arrow-button"
        >
          &raquo;
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className="filters">
        <div className="status-buttons">
          <button
            className={`status-button ${
              selectedStatus === "입양가능" ? "active" : ""
            }`}
            onClick={() => handleStatusChange("입양가능")}
          >
            입양가능
          </button>
          <button
            className={`status-button ${
              selectedStatus === "입양준비중" ? "active" : ""
            }`}
            onClick={() => handleStatusChange("입양준비중")}
          >
            입양준비중
          </button>
          <button
            className={`status-button ${
              selectedStatus === "입양완료" ? "active" : ""
            }`}
            onClick={() => handleStatusChange("입양완료")}
          >
            입양완료
          </button>
        </div>

        <div className="animal-category">
          <label htmlFor="animal-select"></label>
          <select
            id="animal-select"
            value={selectedAnimal}
            onChange={handleAnimalChange}
            className="adoption-select"
          >
            <option value="dog">강아지</option>
            <option value="cat">고양이</option>
            <option value="etc">특수동물</option>
          </select>
        </div>
      </div>

      <div className="post-list">
        {filteredPosts.length > 0 ? (
          renderPosts()
        ) : (
          <p>해당 조건에 맞는 게시물이 없습니다.</p>
        )}
      </div>

      {renderPagination()}

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {currentStep === 1 && (
            <AdoptionDetail
              handleClose={closeModal}
              animalData={selectedPost}
              onNext={(animalNumber) => nextStep(null, animalNumber)}
            />
          )}
          {currentStep !== 4 && currentStep !== 1 && (
            <AdoptionModalFrame>
              {currentStep === 2 && (
                <AdoptionCheckList onClose={closeModal} onNext={nextStep} />
              )}
              {currentStep === 3 && (
                <AdoptionInputComponent
                  onPrev={prevStep}
                  onNext={nextStep}
                  selectedAnimalNumber={selectedAnimalNumber}
                />
              )}
            </AdoptionModalFrame>
          )}
          {currentStep === 4 && (
            <AdoptionCompleteComment onClose={closeModal} />
          )}
        </Modal>
      )}
    </div>
  );
};

export default AdoptionList;
