// src/TalentSliderData.js
const TalentSliderData = () => {
  return [
    {
      image: "",
      tooltip: "첫 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: process.env.PUBLIC_URL + "/images/kangTe-nim2.png",
      tooltip: "강아지와 강태님",
    },
    {
      image: "",
      tooltip: "세 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "네 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "다섯 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "여섯 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "일곱 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "여덟 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "아홉 번째 이미지에 대한 말풍선 내용입니다.",
    },
    {
      image: "",
      tooltip: "열 번째 이미지에 대한 말풍선 내용입니다.",
    },
  ];
};

export default TalentSliderData;
