import "./DonationUseList.css";
import DonationUseListPrimaryContent from "./DonationUseListPrimaryContent";
// import { Link } from "react-router-dom";

const DonationUseListPrimary = ( ) => {
    const newsData = [
        { imageUrl: "/images/newsPrimary1.png", title: "만약제목이길어진다면어떻게", date: "2024.11.11", description: "간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단 설명1간단  설명1간단 설명1간단 설명1간단 설명1간"  },
        { imageUrl: "/images/newsPrimary2.png", title: "후원금사용내역2", date: "2024.11.12", description: "후원금 사용내역에 대한 글을 적어주시면 됩니다!" },
        { imageUrl: "/images/newsPrimary3.png", title: "후원금사용내역3", date: "2024.11.13", description: "간단 설명3" },
      ];

  return (
    <div className="donation-uselist-primary-container">
      <div className="donation-uselist-list-write-button">
      {/* <Link to='/write'
      state = {{source: "uselist"}}>
          <button>글쓰기</button>
        </Link> */}
      </div>
        <div className="donation-uselist-primary-top-title">
            <p>주요 후원금 사용내역</p>
        </div>
        <div className="donation-uselist-primary-content_container">
        {newsData.map((news, index) => (
        <DonationUseListPrimaryContent
          key={index}
          imageUrl={news.imageUrl}
          title={news.title}
          date={news.date}
          description={news.description}
        />
      ))}
      </div>
    </div>
  );
};

export default DonationUseListPrimary;
