import React from 'react';
import './FanClubCard.css';

const FanClubCard = ({ image, starName, donationDate, message }) => {
    return (
        <div className="fan-club-card">
            <div className="fan-club-card__image">
                <img src={image} alt={`${starName}`} />
            </div>
            <div className="fan-club-card__content">
                <div className="fan-club-card__header">
                    <h2>{starName} 분들의 후원</h2>
                </div>
                <div className="fan-club-card__date">후원일시 | {donationDate}</div>
                <div className="fan-club-card__message">{message}</div>
            </div>
        </div>
    );
};

export default FanClubCard;
