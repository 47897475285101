// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

.admin-navi-bar {
  flex: 0 0 20%;
}

.admin-outlet {
  flex: 1 1;
  padding-top: 100px;
  margin: 0 50px
    /* margin-left: 100px; */
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAO;AACT;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,aAAa;IACX,wBAAwB;AAC5B","sourcesContent":[".admin-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.admin-content {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n}\n\n.admin-navi-bar {\n  flex: 0 0 20%;\n}\n\n.admin-outlet {\n  flex: 1;\n  padding-top: 100px;\n  margin: 0 50px\n    /* margin-left: 100px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
