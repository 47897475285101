import React, { useCallback, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";
// import { NavLink } from "react-router-dom";
import axios from "axios";
import { Link } from 'react-router-dom';

const Sliders = () => {
  const images = [
    {src : "/images/main-images/slider-images/main-slider1.png", link: "/about/intro"},
    {src : "/images/main-images/slider-images/main-slider2.png", link: "/AdoptionPage"},
    {src : "/images/main-images/slider-images/main-slider3.png", link: "/news/list"},
    {src : "/images/main-images/slider-images/main-slider4.png", link: "/TalentPage"},
    {src : "/images/main-images/slider-images/main-slider5.png", link: "/onetomanypage/list"}
  ];

  const [items, setItems] = useState("");

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(`https://forcatmung.kr/api/intro/sliders`);
      setItems(response.data);
    } catch (error) {
      console.error("API 호출 오류:", error);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  console.log("이미지슬라이드, 비어있어서 로그만", items);

  // const [currentSlide, setCurrentSlide] = useState(1);

  // const handleDetailButton = () => {
  //   alert(`자세히 보기 ${currentSlide} 클릭!`);
  // };

  // const handleDonateButton = () => {
  //   alert(`후원하기 ${currentSlide} 클릭!`);
  // };

  // 이미지슬라이드 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: false,
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "20%", overflow: "hidden" }}>
      <Slider {...settings} dotsClass="test-css">
        {(items && items.length > 0 ? items : images).map((item, index) => (
          <Link to={item.link} key={index}>
            <div key={index}>
              <img src={item.src} alt={`Slide ${index}`} className="image-slider-content" />
            </div>
          </Link>
        ))}
      </Slider>
      {/* 
      <div className="btn-css">
        <button
          className="btn-detail" 
          >
          입양하기
        </button>
        <button className="btn-donate"
          >
          구조학대
        </button>
      </div> */}
    </div>
  );
};
export default Sliders;
