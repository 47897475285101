import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OneToManyContent from '../../Main/OneToMany/OneToManyContent';
import OneToManyPaginationComponent from './OneToManyPaginationComponent';
import './OneToManyPageList.css';
import axios from 'axios';

const OneToManyPageList = ({ isAdmin }) => {
    const [loading, setLoading] = useState(true);
    const [oneToManyList, setOneToManyList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // 기본 페이지 크기 설정

    // 백엔드 API 호출 (데이터 가져오기)
    const fetchOneToManyList = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://forcatmung.kr/api/animaldetail/onetomany/list?page=${currentPage - 1
                }&size=${pageSize}`);
            if (response && response.data) {
                const {
                    currentPage: apiCurrentPage,
                    totalPages,
                    pageSize,
                    list,
                } = response.data;

                setOneToManyList(Array.isArray(list) ? list : []); // 리스트 데이터 저장
                setCurrentPage(apiCurrentPage + 1); // 현재 페이지 설정
                setTotalPages(totalPages); // 전체 페이지 개수 설정
                setPageSize(pageSize); // 페이지 크기 업데이트

            } else {
                console.log("응답데이터가 없습니다")
            }
        } catch (error) {
            if (error.response) {
                console.error("서버 응답 오류:", error.response.data);
                console.error("응답 상태 코드:", error.response.status);
            } else if (error.request) {
                console.error("서버로부터 응답을 받지 못했습니다:", error.request);
            } else {
                console.error("요청 설정 오류:", error.message);
            }
        } finally {
            setLoading(false);
        }
    }, [currentPage, pageSize]);

    // URL에서 페이지 정보 추출 (뒤로 가기, 새로고침 시 유지)
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageFromUrl = parseInt(queryParams.get('page')) || 1;
        setCurrentPage(pageFromUrl);
    }, [location.search]);

    // 컴포넌트가 마운트될 때
    useEffect(() => {
        fetchOneToManyList();
    }, [fetchOneToManyList]);

    // 페이지 변경 핸들러
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            navigate(`?page=${page}`);
        }
    };

    // 설명 변경 핸들러 (로컬 상태 업데이트)
    const handleDescriptionChange = (id, newDescription) => {
        setOneToManyList((prevList) =>
            prevList.map((story) =>
                story.animalid === id ? { ...story, description: newDescription } : story
            )
        );
    };

    // 설명을 서버에 저장하는 함수
    const saveDescriptionToServer = async (id, description) => {
        /*try {
             const response = await fetch(`/api/stories/${id}`, {
                 method: 'PUT',
                 headers: { 'Content-Type': 'application/json' },
                 body: JSON.stringify({ description }),
             });
 
             if (!response.ok) throw new Error('Failed to save description');
         } catch (error) {
             console.error(error);
             alert('Failed to save description.');
         }*/
    };

    return (
        <div>
            <div style={{ margin: "10% auto" }}>
                <div className='list-header'>
                    <div className='list-header-text'>
                        <span className='list-header-text-top'>치료와 교육을 받을 수 있도록 도와주세요</span>
                        <span className='list-header-text-bottom'>1:N 후원하기</span>
                    </div>
                    <hr className='list-header-divider' />
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    oneToManyList.map((story, index) => (
                        <div className="one-to-many-content-container" key={story.animalid}>
                            <OneToManyContent
                                id={story.animalid}
                                title={story.title}
                                catName={story.name}
                                description={
                                    isAdmin ? (
                                        <>
                                            <textarea
                                                className='one-to-many-content-update-textarea'
                                                value={story.description}
                                                onChange={(e) =>
                                                    handleDescriptionChange(story.animalid, e.target.value)
                                                }
                                            />
                                            <button
                                                className="save-button"
                                                onClick={() =>
                                                    saveDescriptionToServer(story.animalid, story.description)
                                                }
                                            >
                                                Save
                                            </button>
                                        </>
                                    ) : (
                                        story.description
                                    )
                                }
                                imageUrl={story.image}
                            />
                            {index < oneToManyList.length - 1 && <hr className="one-to-many-content-divider" />}
                        </div>
                    ))
                )}
            </div>

            <div className='one-to-many-pagination-container'>
                <OneToManyPaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default OneToManyPageList;
