import { useEffect, useState } from 'react';
import { getAnimalDetail } from './OneToManyDetailApi';
import './SupportListOneToMany.css';
import OneToManyPerson from './assets/OneToManyPerson.png';  // 이미지 경로
import { useParams } from 'react-router-dom';

const SupportListOneToMany = ({ onclick }) => {
    const { id } = useParams();
    const [animal, setAnimal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnimal = async () => {
            try {
                const data = await getAnimalDetail(id);
                console.log("동물 데이터:", data); // 동물 데이터 출력
                setAnimal(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAnimal();

    }, [id]);

    if (loading) return <p>로딩 중...</p>;
    if (error) return <p>에러 발생: {error}</p>;
    if (!animal) return <p>데이터 없음</p>;

    // 20개의 더미 데이터를 초기화
    const initialSupporters = Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        name: `후원자 ${index + 1}`,
        src: OneToManyPerson,
    }));

    // 7개씩 한 줄에 배치
    const rows = [];
    for (let i = 0; i < initialSupporters.length; i += 6) {
        rows.push(initialSupporters.slice(i, i + 6));
    }

    return (
        <div className='support-list-container'>
            <div className='support-list-header'>
                <p className='support-list-text'>{animal.name}의 후원자분들</p>
                <hr className='support-list-hr' />
            </div>
            <div className='support-list'>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className='support-row'>
                        {row.map((supporter) => (
                            <div className='support-item' key={supporter.id}>
                                <img
                                    src={supporter.src}
                                    alt={supporter.name}
                                    className='support-item-img'
                                />
                                <p className='support-item-name'>{supporter.name}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className='support-list-button'>
                <button
                    className="one-to-many-detail-button"
                    style={{ borderRadius: 5, boxShadow: 'none' }}
                >
                    후원하기
                </button>
                <button
                    className="one-to-many-donate-button"
                    onClick={onclick}
                    style={{ borderRadius: 5, boxShadow: 'none' }}
                >
                    목록으로
                </button>
            </div>
        </div>
    );
};

export default SupportListOneToMany;
