import React, { useState } from "react";
import "./AskForHelpContent.css";
import logo from "./assets/ask-for-help-logo.png";
import AskForHelpModal from "./AskForHelpModal";
import Modal from "../MyPage/Modal";
import AskForHelpFormModal from "./AskForHelpFormModal";

function AskForHelpContent() {
  const [currentModal, setCurrentModal] = useState(null); // "help" 또는 "form"

  const closeModal = () => setCurrentModal(null);

  const openHelpModal = () => setCurrentModal("help");

  const openFormModal = () => setCurrentModal("form");

  return (
    <div>
      <div className="ask-for-help-header">
        <div className="ask-for-help-text-line">
          <hr className="ask-for-help-header-divider" />
          <span className="ask-for-help-header-title">구조 / 학대 제보</span>
        </div>
        <button className="report-abuse-button" onClick={openHelpModal}>
          제보하기
        </button>
      </div>
      <div className="what-is-animal-abuse">
        <div className="animal-abuse-title">동물학대란?</div>
        <div className="animal-abuse-description">
          동물을 대상으로 정당한 사유 없이 불필요하거나 피할 수 있는 <br />
          신체적 고통과 스트레스를 주는 행위 및 굶주림, 질병 등에 대하여 <br />
          적절한 조치를 게을리하거나 방치하는 행위를 말한다.
        </div>
      </div>
      <div className="animal-abuse-report">
        <div className="animal-abuse-report-text">
          <div className="abuse-report-title">동물학대 신고,</div>
          <div className="abuse-report-subtitle">적절한 조치가 중요합니다</div>
          <div className="abuse-report-action">
            구조가 필요한 아이들을 위한 우리의 작은 실천
          </div>
          <button
            className="animal-abuse-report-button"
            onClick={openHelpModal}
          >
            제보하기
          </button>
        </div>
        <img src={logo} alt="로고" className="animal-abuse-report-logo" />
      </div>
      <div className="animal-abuse-report-divider"></div> {/* 구분선 추가 */}
      {/* 모달 컴포넌트 */}
      <Modal isOpen={currentModal === "help"} onClose={closeModal}>
        <AskForHelpModal onClose={closeModal} onNext={openFormModal} />
      </Modal>
      <Modal isOpen={currentModal === "form"} onClose={closeModal}>
        <AskForHelpFormModal onClose={closeModal} />
      </Modal>
    </div>
  );
}

export default AskForHelpContent;
