import "./Percent.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const Percent = () => {
  const current = 88; // 현재 값
  const goal = 100; // 목표 값
  const navigator = useNavigate();

  const progressPercentage = Math.round((current / goal) * 100);

  const handleDetailButton = () => {
    navigator("/uselist/list");
  };

  // const handleDonateButton = () => {
  // };

  return (
    <div>
      <div className="container">
        <div className="percent-container">
          <div className="percent-wrapper">
            <p className="top-text">
              후원금 사용내역을 투명하게 공개할 것을 약속합니다
            </p>
            <p className="bottom-text">
              여러분들의 후원이
              <strong className="bottom-text-strong"> 큰 힘</strong>이 됩니다
            </p>
            <div className="progress-container">
              {/* 커스텀 진행바 */}
              <div className="progress-bar">
                <div
                  className="progress-fill"
                  style={{ width: `${progressPercentage}%` }}
                >
                  {/* 진행바 안에 퍼센트 텍스트 */}
                  <span className="progress-text">{progressPercentage}%</span>
                </div>
              </div>
            </div>
            <div className="percent-btn-box">
              {/*<button className="percent-btn-donate" onClick={handleDonateButton}>후원하러 가기</button>*/}
              <button
                className="percent-btn-detail"
                onClick={handleDetailButton}
              >
                자세히 보러가기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Percent;
