import "./AskForHelpModal.css";
import personIcon from "./assets/ask-for-help-modal-icon.png";

function AskForHelpModal({ onClose, onNext }) {

  return (
    <div className="ask-for-help-modal-content">
      <div className="ask-for-help-modal-header-container">
        <div className="ask-for-help-modal-icon-container">
          <img
            src={personIcon}
            alt="Person Icon"
            className="ask-for-help-modal-icon"
          />
        </div>

        <div className="ask-for-help-modal-title-container">
          <h2 className="ask-for-help-modal-title">구조/학대 제보하기</h2>
          <h3 className="ask-for-help-modal-subtitle">
            구조가 필요한 아이들을 제보해주세요
          </h3>
        </div>
      </div>

      <div className="ask-for-help-modal-info-box">
        <div className="ask-for-help-modal-info-box-title">
          구조/학대 제보 시 유의사항
        </div>
        <div className="ask-for-help-modal-info-container">
          <h3 className="ask-for-help-modal-info-title">유의사항</h3>
          <br />
          <ul className="ask-for-help-modal-info-list">
            <li className="ask-for-help-modal-info-item">
              동물학대란 정당한 사유 없이 불필요하거나 피할 수 있는 신체적
              고통과 스트레스를 주는 <br />
              행위 및 굶주림, 질병 등에 대하여 적절한 조치를 게을리하거나
              방치하는 행위를 말한다.
            </li>
            <br />
            <li className="ask-for-help-modal-info-item">
              동물 학대시 현행법 위반 행위로 동물 학대 시 3년 이하의 징역 또는
              3천만원 이하의 벌금 등 <br /> 엄중한 처벌을 받을 수 있습니다.
            </li>
            <br />
            <li className="ask-for-help-modal-info-item">
              동물 학대시 증거를 확보 후 112에 신고: 사진, 동영상을 확보하여
              법적 증거로 활용될 수 있습니다.
            </li>
            <li className="ask-for-help-modal-info-item">
              적절한 보호 또는 조치가 취해지지 않을 경우, 각 지자체 동물 보호
              업무를 담당하는 공무원에게 <br /> 신고해주십시오 &nbsp;
              <span style={{ color: "#005eb8" }}>
                (동물보호복지콜센터 1577-0954)
              </span>
            </li>
            <br />
            <li className="ask-for-help-modal-info-item">
              포캣멍센터로 도움을 요청할 경우 제보하기를 통하여 자세한 내용을
              기입하여주시길 바랍니다.
            </li>
            <li className="ask-for-help-modal-info-item">
              포캣멍센터는 학대받는 아이들을 구조하여 치료 후 입양까지 책임져
              아이들에게 새 삶은 선물하고자 <br /> 노력하고 있으나, 한정된
              공간으로&nbsp;
              <span
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "black",
                }}
              >
                추가적인 구조 어려울 수 있습니다.
              </span>
            </li>
            <li className="ask-for-help-modal-info-item">
              <span
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "black",
                }}
              >
                제보를 검토 후 구조가 불가능한 경우 연락을 드리지 못 하는 점
                양해 부탁드립니다.
              </span>
            </li>
            <li className="ask-for-help-modal-info-item">
              구조/학대 제보는 SNS에 활용될 수 있습니다.
            </li>
          </ul>
        </div>
      </div>

      {/* 마지막 주의사항 */}
      <p className="ask-for-help-modal-warning">
        위 내용을 숙지하였으며, 신청을 원하신다면 <br /> 다음으로 버튼을
        눌러주세요
      </p>

      {/* 버튼들 */}
      <div className="ask-for-help-modal-buttons">
        <button onClick={onClose} className="ask-for-help-modal-close-button">
          닫기
        </button>
        <button onClick={onNext} className="ask-for-help-modal-next-button">
          다음으로
        </button>
      </div>
    </div>
  );
}

export default AskForHelpModal;
