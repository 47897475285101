import { Outlet } from "react-router-dom";
import React from 'react';
import "./Question.css";
import QuestionBanner from "../../components/Question/QuestionBanner";

const QuestionPage = () => {
  return (
    <>
      <main className="question-container">
        <QuestionBanner />
        <Outlet />
      </main>
    </>
  );
};
export default QuestionPage;
