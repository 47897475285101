import "./Question.css";

const QuestionBanner = () => {
  return (
    <div className="question-banner-container">
      <div className="question-banner-text">
        <p className="question-banner-main-text">문의하기</p>
        <p className="question-banner-explain-text">
          포캣멍센터에 궁금한 게 있으시다면?
        </p>
      </div>
    </div>
  );
};

export default QuestionBanner;
