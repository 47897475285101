import React, { useEffect, useState } from 'react';
import './OneToManyFooter.css';
import grid1 from './assets/grid_1.png';
import grid2 from './assets/grid_2.png';
import grid3 from './assets/grid_3.png';
import grid4 from './assets/grid_4.png';
import wave from './assets/wave.png';
import { getAnimalDetail } from './OneToManyDetailApi';
import { useParams } from 'react-router-dom';

const OneToManyFooter = ({ name }) => {
    const { id } = useParams();
    const [animal, setAnimal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnimal = async () => {
            try {
                const data = await getAnimalDetail(id);
                console.log("동물 데이터:", data); // 동물 데이터 출력
                setAnimal(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAnimal();

    }, [id]);

    if (loading) return <p>로딩 중...</p>;
    if (error) return <p>에러 발생: {error}</p>;
    if (!animal) return <p>데이터 없음</p>;

    return (
        <div
            className="one-to-many-footer"
            style={{ backgroundImage: `url(${wave})` }}
        >
            <div className="one-to-many-footer-title">
                {animal.name}(가) 후원자님을 만나면?
            </div>

            <div className="one-to-many-footer-grid">
                <div className="one-to-many-footer-grid-container">
                    <img
                        src={grid1}
                        alt="grid 1"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid2}
                        alt="grid 2"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid3}
                        alt="grid 3"
                        className="one-to-many-footer-grid-item"
                    />
                    <img
                        src={grid4}
                        alt="grid 4"
                        className="one-to-many-footer-grid-item"
                    />
                </div>
            </div>
        </div>
    );
};

export default OneToManyFooter;
