import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAnimalDetail } from "./OneToManyDetailApi"; // API 함수 가져오기
import "./OneToManyDetail.css";

import iconId from "./assets/icon_id.png";
import iconHistory from "./assets/icon_history.png";
import iconPersonality from "./assets/icon_personality.png";
import iconNeeds from "./assets/icon_needs.png";

const OneToManyDetail = ({ onClick }) => {
    const { id } = useParams();
    const [animal, setAnimal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnimal = async () => {
            try {
                const data = await getAnimalDetail(id);
                setAnimal(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAnimal();

    }, [id]);

    if (loading) return <p>로딩 중...</p>;
    if (error) return <p>에러 발생: {error}</p>;
    if (!animal) return <p>데이터 없음</p>;

    return (
        <div className="one-to-many-page">
            <div className="one-to-many-header" style={{ flexDirection: "column" }}>
                <div className="one-to-many-header-content-wrapper">
                    <div className="one-to-many-header-text">
                        <span className="one-to-many-small-text">아이들의 가족이 되어주세요</span>
                        <span className="one-to-many-large-text">1:N 후원 동물</span>
                    </div>
                    <div className="many-header-button">
                        <button className="one-to-many-detail-button" onClick={onClick} style={{ boxShadow: "none", width: 110 }}>
                            목록으로
                        </button>
                    </div>
                </div>
                <hr className="many-detail-divider" />
            </div>
            <div className="many-detail-content-wrapper">
                <div className="many-detail-images">
                    <img src={animal.image} alt={animal.name} className="animal-detail-many__image" />
                </div>
                <div className="many-detail-text">
                    <div className="many-detail-content-title">
                        <span style={{ color: "#0260BA" }}>안녕하세요!<br /></span>
                        <span>저는 {animal.name} 입니다!<br /></span>
                        <span style={{ color: "#545454", fontSize: 13, opacity: 0.49 }}>
                            {animal.description}
                        </span>
                    </div>
                    <div className="many-detail-content-body">
                        <div className="many-detail-content-body-item">
                            <img src={iconId} alt="동물번호 아이콘" className="many-detail-content-body-icon" />
                            <p><strong>동물번호:</strong> {animal.animalNumber}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconHistory} alt="히스토리 아이콘" className="many-detail-content-body-icon" />
                            <p>{animal.rescuedDate} - {animal.rescuedPlace}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconPersonality} alt="성격 아이콘" className="many-detail-content-body-icon" />
                            <p>{animal.personality}</p>
                        </div>
                        <div className="many-detail-content-body-item">
                            <img src={iconNeeds} alt="니즈 아이콘" className="many-detail-content-body-icon" />
                            <p>{animal.disability || "특이사항 없음"}</p>
                        </div>
                    </div>
                    <div className="many-detail-content-footer">
                        <div className="many-detail-content-footer-text">
                            <p>아이들에게 안정적인 하루 하루를 선물해 주세요.</p>
                            <p>저의 따뜻한 후원자님을 기다립니다.</p>
                        </div>
                        <div className="many-detail-content-footer-button">
                            <button className="one-to-many-donate-button" style={{ borderRadius: 6, width: "185px", height: "40px", boxShadow: "none" }}>
                                1:N후원하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OneToManyDetail;
