import React, { useState } from "react";
import "./WhatIsAnimalRescueBanner.css";
import Modal from "../MyPage/Modal";
import AskForHelpModal from "./AskForHelpModal";
import AskForHelpFormModal from "./AskForHelpFormModal";

const WhatIsAnimalRescueBanner = () => {
  const [currentModal, setCurrentModal] = useState(null); // "help" 또는 "form"

  const closeModal = () => setCurrentModal(null);
  const openHelpModal = () => setCurrentModal("help");
  const openFormModal = () => setCurrentModal("form");

  return (
    <div className="animal-rescue-banner">
      <div className="animal-rescue-banner__background"></div> {/* 배경만 담당 */}
      <div className="animal-rescue-banner__content">
        <p className="animal-rescue-banner__title">동물 구조란?</p>
        <p className="animal-rescue-banner__text">
          도움이 필요한 동물을 찾아내어, <br /> 그들의 생명을 구하고, 안전한 환경으로 인도하는 과정입니다.
        </p>
        <button className="animal-rescue-banner__button" onClick={openHelpModal}>
          제보하기
        </button>
      </div>

      {/* 모달 컴포넌트 */}
      <Modal isOpen={currentModal === "help"} onClose={closeModal}>
        <AskForHelpModal onClose={closeModal} onNext={openFormModal} />
      </Modal>
      <Modal isOpen={currentModal === "form"} onClose={closeModal}>
        <AskForHelpFormModal onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default WhatIsAnimalRescueBanner;
