import React, { useEffect } from "react";
import "./Admin.css";
import { Outlet, useNavigate } from "react-router-dom";
import { AdminBanner, AdminNav } from "../../components/Admin";
import { useAuthToken } from "../../hooks/loginHooks";

const Admin = () => {
  const navigator = useNavigate();
  const accessToken = sessionStorage.getItem("accessToken");

  //   토큰 없을 시 로그인창으로 이동
  useEffect(() => {
    if (!accessToken) {
      navigator("/admin", { replace: true });
    }
  }, [accessToken, navigator]);

  useAuthToken();

  return (
    <>
      <div className="admin-container">
        <AdminBanner />
        <div className="admin-content">
          <AdminNav />
          <div className="admin-outlet">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
