import { Link } from "react-router-dom";
import "./TemporaryInfo.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const TemporaryInfo = () => {
  const [amount, setAmount] = useState("");
  const [userName, setUserName] = useState();
  const [checkAge, setCheckAge] = useState(false);
  const [phoneHead, setPhoneHead] = useState();
  const [phoneNum, setPhoneNum] = useState();
  const [email, setEmail] = useState();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [checkPersonal, setCheckPersonal] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);

  const accessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        try {
          const response = await axios.get(
            `https://forcatmung.kr/api/pay/userinfo`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,

                "Content-Type": "application/json",
              },
            }
          );
          const data = response.data;
          setUserName(data.username);
          setEmail(data.email);
        } catch (e) {
          console.error(e);
        }
      }
    };
    fetchData();
  }, []);

  // 금액 선택
  const handleAmount = (value) => {
    setAmount(String(value));
  };
  const isSelectedAmount = (value) => amount === String(value);

  // 결제방법 선택
  const handlePaymentMethod = (method) => {
    setPaymentMethod(method);
  };
  const isSelectedMethod = (method) => paymentMethod === method;

  // UID 폼
  const generateMerchantUID = () => {
    const today = new Date();
    const yyMMdd = `${String(today.getFullYear()).slice(2)}${String(
      today.getMonth() + 1
    ).padStart(2, "0")}${String(today.getDate()).padStart(2, "0")}`;

    return `order_no_${yyMMdd}_${uuidv4().slice(0, 8)}`;
  };

  // 포트원 초기화
  useEffect(() => {
    if (!window.IMP) {
      console.log("포트원 SDK가 로드되지 않았습니다.");
      return;
    }
    window.IMP.init(process.env.REACT_APP_IMP_KEY);
  });
  // 포트원 다날 결제창 호출
  const handlePayment = async () => {
    if (!window.IMP) {
      alert("결제 모듈이 로드되지 않았습니다.");
      return;
    }
    if (!amount) {
      alert("후원 금액을 선택해주세요.");
      return;
    }
    if (!userName) {
      alert("이름을 입력해주세요.");
      return;
    }
    if (!phoneHead || !phoneNum) {
      alert("연락처를 입력해주세요.");
      return;
    }
    if (!email) {
      alert("이메일을 입력해주세요.");
      return;
    }

    if (!checkTerms || !checkPersonal) {
      alert("약관과 개인정보 처리방침에 동의해주세요.");
      return;
    }

    const { IMP } = window;
    const merchant_uid = generateMerchantUID();
    const data = {
      channelKey: process.env.REACT_APP_DANAL_CHANEL_KEY,
      pay_method: paymentMethod,
      merchant_uid: merchant_uid,
      name: "일시후원",
      amount: amount,
      buyer_email: email,
      buyer_name: userName,
      buyer_tel: `${phoneHead}${phoneNum}`,
    };

    IMP.request_pay(data, async (response) => {
      if (response.success) {
        try {
          const data = {
            applyNum: response.apply_num,
            bankName: response.bank_name,
            buyerEmail: response.buyer_email,
            buyerName: response.buyer_name,
            buyerTel: response.buyer_tel,
            cardName: response.card_name,
            cardNumber: response.card_number,
            cardQuota: response.card_quota,
            currency: response.currency,
            impUid: response.imp_uid,
            merchantUid: response.merchant_uid,
            name: response.name,
            paidAmount: response.paid_amount,
            payMethod: response.pay_method,
            pgProvider: response.pg_provider,
            pgTid: response.pg_tid,
            pgType: response.pg_type,
            receiptUrl: response.receipt_url,
            status: response.status,
          };
          await axios.post(`https://forcatmung.kr/api/pay/temporary`, data);
        } catch (e) {
          console.error("결제 데이터 서버 전송 실패", e);
        }
        alert("결제가 완료되었습니다."); //현재 알림창 안뜸....
      }
    });
  };

  return (
    <div className="temporary-main">
      <div className="temporary-header">
        <p className="temporary-header-text">
          한 번의 따뜻한 손 길을 내밀어주세요
          <p style={{ color: "red" }}>
            현재 테스트용 입니다. 실제로 결제가 이루어지지 않습니다
          </p>
          <span className="temporary-header-title">일시후원</span>
        </p>
      </div>

      {/* 후원정보 */}
      <div className="temporary-support-info">
        <div className="temporary-support-info-title">
          <p>후원정보</p>
        </div>
        <div className="temporary-support-info-amount">
          <div className="little-title">후원금액</div>
          <div className="temporary-support-info-amount-click">
            <button
              className={`temporary-support-info-amount-click-button temporary-support-info-amount-click-item ${
                isSelectedAmount(10000) ? "selected" : ""
              }`}
              onClick={() => handleAmount(10000)}
            >
              10,000원
            </button>
            <button
              className={`temporary-support-info-amount-click-button temporary-support-info-amount-click-item ${
                isSelectedAmount(20000) ? "selected" : ""
              }`}
              onClick={() => handleAmount(20000)}
            >
              20,000원
            </button>
            <button
              className={`temporary-support-info-amount-click-button temporary-support-info-amount-click-item ${
                isSelectedAmount(30000) ? "selected" : ""
              }`}
              onClick={() => handleAmount(30000)}
            >
              30,000원
            </button>
            <button
              className={`temporary-support-info-amount-click-button temporary-support-info-amount-click-item ${
                isSelectedAmount(50000) ? "selected" : ""
              }`}
              onClick={() => handleAmount(50000)}
            >
              50,000원
            </button>
            <input
              className="temporary-support-info-amount-click-input"
              placeholder="직접입력"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* 회원정보 */}
      <div className="temporary-user-info">
        <div className="temporary-user-info-title">
          <p>회원정보</p>
        </div>
        <div className="temporary-user-detail">
          <div className="temporary-user-detail-name">
            <div className="little-title">이름</div>
            <input
              className="temporary-user-detail-input"
              placeholder="정확하게 작성해주세요"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <input
              type="checkbox"
              className="temporary-user-detail-age-checkbox"
              value={checkAge}
              onChange={() => setCheckAge((prev) => !prev)}
            />
            <p className="temporary-user-detail-age">만 14세 이상입니다</p>
          </div>
          {/* 연락처 */}
          <div className="temporary-user-detail-phone">
            <div className="little-title">연락처</div>
            <div className="temporary-user-detail-phone-num">
              <input
                type="tel"
                list="prefixes"
                placeholder="선택"
                className="temporary-user-detail-phone-prefixes-input"
                value={phoneHead}
                onChange={(e) => setPhoneHead(e.target.value)}
              />
              <datalist id="prefixes">
                <option value="010" />
              </datalist>
              <p>-</p>
              <input
                type="tel"
                className="temporary-user-detail-phone-input"
                value={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
              />
            </div>
          </div>
          {/* 이메일 */}
          <div className="temporary-user-detail-email">
            <div className="little-title">이메일</div>
            <input
              className="temporary-user-detail-email-input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* 결제정보 */}
      <div className="temporary-support-info">
        <div className="temporary-support-info-title">
          <p>결제정보</p>
        </div>
        <div className="temporary-payment-info">
          <div className="little-title">결제방법</div>
          <div className="temporary-payment-info-click">
            <button
              className={`temporary-payment-info-click-button temporary-support-info-amount-click-item ${
                isSelectedMethod("card") ? "selected" : ""
              }`}
              onClick={() => handlePaymentMethod("card")}
            >
              카드결제
            </button>
            <button
              className={`temporary-payment-info-click-button temporary-support-info-amount-click-item ${
                isSelectedMethod("trans") ? "selected" : ""
              }`}
              onClick={() => handlePaymentMethod("trans")}
            >
              계좌이체
            </button>
            {/* <button
              className={`temporary-payment-info-click-button temporary-support-info-amount-click-item ${
                isSelectedMethod("kakaopay") ? "selected" : ""
              }`}
              onClick={() => handlePaymentMethod("kakaopay")}
            >
              카카오페이
            </button> */}
          </div>
        </div>
      </div>

      {/* 약관동의 */}
      <div className="temporary-agreement">
        <div>
          <div className="temporary-agreement-terms">
            <label>
              <input
                type="checkbox"
                className="checkpoint"
                checked={checkTerms}
                onChange={() => setCheckTerms((prev) => !prev)}
              />
              <div>
                <span className="red-span">[필수]</span> 이용약관 동의
              </div>
            </label>
            <Link to="/terms" className="link-hover">
              [보기]
            </Link>
          </div>
          <div className="temporary-agreement-personal">
            <label>
              <input
                type="checkbox"
                className="checkpoint"
                checked={checkPersonal}
                onChange={() => setCheckPersonal((prev) => !prev)}
              />
              <div>
                <span className="red-span">[필수]</span> 개인정보 처리방침 동의
              </div>
            </label>
            <Link to="/personal" className="link-hover">
              [보기]
            </Link>
          </div>
        </div>
        <button className="temporary-pay-go" onClick={handlePayment}>
          다음
        </button>
      </div>
    </div>
  );
};

export default TemporaryInfo;
