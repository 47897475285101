import React, { useCallback, useEffect, useState } from 'react';
import "./AdminAdoption.css";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminVolunteering = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const fetchItems = useCallback(async () => {
    try {
      const url = `https://forcatmung.kr/api/volunteering/admin/list`;
      const response = await axios.get(url);
      console.log("response.data:", response.data);
      const itemsArray = Array.isArray(response.data)
        ? response.data
        : response.data.list || [];
      setItems(itemsArray);
    } catch (error) {
      console.error('API 호출 오류:', error);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleRowClick = (item) => {
    navigate(`/adminpage/volunteering/${item.volunteeringPk}`);
  };

  const kindMap = {
    'beauty': '미용',
    'training': '훈련',
    'walk': '산책',
    'cleaning': '청소',
    'relocation': '이동',
  };

  const statusMap = {
    0: '모집 예정',
    1: '모집 중',
    2: '모집 마감'
  };

  return (
    <div className="admin-list-container">
      {/* 상단 헤더 영역 */}
      <div className="admin-adoption-header">
        <h2 className="admin-adoption-table">봉사활동목록</h2>
        <Link to="/adminpage/create">
          <button className="admin-regist-button">등록</button>
        </Link>
      </div>

      {/* 테이블 영역 */}
      <div className="admin-adoption-table-container">
        <div className="admin-list-table">
          {/* 헤더 행 */}
          <div className="admin-list-table-row">
            <span className="admin-list-table-header col-number">번호</span>
            <span className="admin-list-table-header col-title">제목</span>
            <span className="admin-list-table-header col-kind">봉사분야</span>
            <span className="admin-list-table-header col-date">봉사일정</span>
            <span className="admin-list-table-header col-status">모집상태</span>
            <span className="admin-list-table-header col-total">모집인원</span>
            <span className="admin-list-table-header col-current">신청인원</span>
          </div>
          <div className="table-divider"></div>
          {items.map((item, index) => (
            <div
              key={item.volunteeringPk}
              className="admin-list-table-row"
              onClick={() => handleRowClick(item)}
              style={{ cursor: 'pointer' }}
            >
              <span className="admin-list-table-cell col-number">{index + 1}</span>
              <span className="admin-list-table-cell col-title">{item.title}</span>
              <span className="admin-list-table-cell col-kind">{kindMap[item.kind]}</span>
              <span className="admin-list-table-cell col-date">{item.startDate} ~ {item.endDate}</span>
              <span className="admin-list-table-cell col-status">{statusMap[item.status]}</span>
              <span className="admin-list-table-cell col-total">{item.totalPerson}명</span>
              <span className="admin-list-table-cell col-current">{item.currentPerson}명</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminVolunteering;