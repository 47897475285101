import { Link } from "react-router-dom";
import "./Floating.css";
import firstIcon from "../../assets/Floating1.png";
import secondIcon from "../../assets/Floating2.png";
import thirdIcon from "../../assets/Floating3.png";

const Floating = () => {
  return (
    <div className="floating-button">
      <Link to="/about/intro" className="floating-link">
        <button>
          <img src={firstIcon} alt="센터소개" className="button-image" />
          센터 소개
        </button>
      </Link>
      <Link to="/uselist/list" className="floating-link">
        <button>
          <img src={secondIcon} alt="후원내역" className="button-image" />
          후원내역
        </button>
      </Link>
      <Link to="/announce/list" className="floating-link">
        <button>
          <img src={thirdIcon} alt="공지사항" className="button-image" />
          공지사항
        </button>
      </Link>
    </div>
  );
};

export default Floating;
