const FaqMailAsk = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:forcatmungcenter@naver.com?subject=문의드립니다&body=안녕하세요.";
  };
  return (
    <section className="faq-ask">
      <h2 className="faq-ask__title">찾는 내용이 없을 경우, 문의하기에서 문의바랍니다</h2>
      <div className="faq-ask__grid">
        <div className="faq-ask__grid-card" onClick={handleEmailClick}>
          <img src="/images/faq-images/faq-ask1.png" alt="메롱이" className="faq-ask__grid-image" />
          <div className="ask-grid__content">
            <div>이메일 문의하기</div>
            <h3 className="ask-grid__title">
              forcatmungcenter
              <br />
              @naver.com
            </h3>
          </div>
        </div>
        <div className="faq-ask__grid-card">
          <img src="/images/faq-images/faq-ask2.png" alt="강태님" className="faq-ask__grid-image" />
          <div className="ask-grid__content">
            <div>문의가 필요하시나요?</div>
            <h3 className="ask-grid__title">
              문의하기
              <span>
                <img src="/icons/left-arrow.svg" alt="왼쪽 화살표" />
              </span>
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqMailAsk;
