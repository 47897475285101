import React, {useCallback, useEffect, useState} from 'react';
import "./AdminAdoption.css"
import axios from 'axios';
import AdminAdoptionModal from './AdminAdoptionModal';


const AdminAdoption = () => {
  const [items, setItems] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [adoptionData, setAdoptionData] = useState(null);

    const fetchItems = useCallback(async (searchKeyword) => {
        try {
          // keyword가 비어 있으면 기본 API 호출
          const response = await axios.get(`https://forcatmung.kr/api/adoption/admin/list?keyword=${searchKeyword}`);
          setItems(response.data);
        } catch (error) {
           console.error('API 호출 오류:', error);
        } 
      }, []);
    
      useEffect(() => {
        fetchItems('');
      }, [fetchItems]);

    const handleClick = async (item) => {
        try {
            const response = await axios.get(`https://forcatmung.kr/api/adoption/detail?adoptionid=${item.adoptionId}`);
            setAdoptionData(response.data);
            setModalTitle('동물 정보');
            setModalVisible(true);
        } catch (error) {
            console.error("Error fetching adoption data:", error);
        }
    };

    const handleNameClick = async (item) => {
        try {
            const response = await axios.get(`https://forcatmung.kr/api/animal/adoption/${item.animalName}`);
            setAdoptionData(response.data);
            setModalTitle('신청서 정보');
            setModalVisible(true);
        } catch (error) {
            console.error("Error fetching adoption data:", error);
        }
    };


    return (
        <div className="admin-adoption-table-container">
            <h2 className='admin-adoption-table'>입양관리</h2>
            <div style={{marginRight:"5%"}}>
            <div style={{borderTop: '1px solid #ccc'}}></div>
            {items.map((item) => (
            <div key={item.adoptionId} className="admin-adoption-table-row">
                <span className="admin-adoption-table-cell"><img src={item.imageUrl} alt="Animal" className="admin-adoption-image" /></span>
                <span className="admin-adoption-table-cell"><button onClick={() => handleNameClick(item)}>{item.animalNumber}</button></span>
                <span className="admin-adoption-table-cell"><button onClick={() => handleClick(item)}>입양신청서 보기</button></span>
                <AdminAdoptionModal isVisible={modalVisible} onClose={() => setModalVisible(false)} data={adoptionData}>
                  <h2>{modalTitle}</h2>
                </AdminAdoptionModal>
            </div>
            ))}
            </div>
          </div>
    )
}


export default AdminAdoption;