import { Outlet } from "react-router-dom";
import Percent from "../../../components/Main/Percent/percent";
import "../../Main/Main.css";
import PageBanners from "../../../components/Banner/PageBanner/PageBanners";

const OneToOnePage = () => {
  return (
    <>
      <main className="md-main">
        <PageBanners
          image={"/images/one-to-one-banner.png"}
          title={"1:1 후원이란?"}
          subTitle={["장애 또는 중증질환을 가진 아이들 그리고 노령의 나이로", "장기간 입양이 어려운 아이들에게", "평범하고 일상이 이어갈 수 있도록", "따뜻한 하루 하루를 선물해주는 후원 방식입니다."]}
        />
        <Outlet />
        <Percent />
      </main>
    </>
  );
};
export default OneToOnePage;
