import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './VolunteeringDetail.css';
import ApplicantsModal from './ApplicantsModal';

const VolunteeringDetail = () => {
    const [volunteeringData, setVolunteeringData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showApplicantsModal, setShowApplicantsModal] = useState(false);
    const { volunteeringId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVolunteeringDetail = async () => {
            try {
                setLoading(true);
                const { data } = await axios.get('https://forcatmung.kr/api/volunteering/admin/list');
                const list = Array.isArray(data) ? data : data.list || [];
                const item = list.find(v => v.volunteeringPk === parseInt(volunteeringId));
                item ? setVolunteeringData(item) : setError('해당 봉사활동을 찾을 수 없습니다.');
            } catch (err) {
                console.error('봉사활동 상세 조회 실패:', err);
                setError('봉사활동 상세 정보를 불러오는데 실패했습니다.');
            } finally {
                setLoading(false);
            }
        };

        fetchVolunteeringDetail();
    }, [volunteeringId]);

    const handleGoBack = () => {
        navigate('/adminpage/volunteering');
    };

    const handleEdit = () => {
        navigate('/adminpage/edit', { state: { item: volunteeringData } });
    };

    const handleApplicantsList = () => {
        console.log('신청명단 보기 버튼 클릭, 봉사활동 ID:', volunteeringData.volunteeringPk);
        setShowApplicantsModal(true);
    };

    const closeApplicantsModal = () => {
        setShowApplicantsModal(false);
    };

    const kindMap = {
        'beauty': '미용',
        'training': '훈련',
        'walk': '산책',
        'cleaning': '청소',
        'relocation': '이동',
    };

    const statusMap = {
        0: '모집 예정',
        1: '모집 중',
        2: '모집 마감'
    };

    if (loading || error || !volunteeringData) {
        return (
            <div className={`volunteering__${error ? 'error' : 'loading'}`}>
                {error || '로딩 중...'}
            </div>
        );
    }

    return (
        <div className="volunteering-detail">
            {/* 상단 헤더 영역 */}
            <div className="volunteering-detail__header">
                <h2 className="volunteering-detail__header-title">봉사활동 게시글</h2>
                <div className="volunteering-detail__header-actions">
                    <button onClick={handleEdit} className="volunteering-detail__edit-button">수정하기</button>
                    <button onClick={handleGoBack} className="volunteering-detail__list-button">목록으로</button>
                </div>
            </div>

            {/* 봉사활동 주요 정보 영역 */}
            <div className="volunteering-detail__main">
                <div className="volunteering-detail__image-container">
                    {volunteeringData.imageUrl ? (
                        <img
                            src={volunteeringData.imageUrl}
                            alt={volunteeringData.title}
                            className="volunteering-detail__image"
                        />
                    ) : (
                        <div className="volunteering-detail__no-image">이미지 없음</div>
                    )}
                </div>
                <div className="volunteering-detail__info">
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">모집상태</span>
                        <span className="volunteering-detail__info-value volunteering-detail__info-value--status">{statusMap[volunteeringData.status]}</span>
                    </div>
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">제목</span>
                        <span className="volunteering-detail__info-value volunteering-detail__info-value--title">{volunteeringData.title}</span>
                    </div>
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">봉사분야</span>
                        <span className="volunteering-detail__info-value">{kindMap[volunteeringData.kind]}</span>
                    </div>
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">봉사기간</span>
                        <span className="volunteering-detail__info-value">{volunteeringData.startDate} ~ {volunteeringData.endDate}</span>
                    </div>
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">모집인원</span>
                        <span className="volunteering-detail__info-value">
                            총 {volunteeringData.totalPerson}명 중 {volunteeringData.currentPerson}명 신청
                        </span>
                    </div>
                    <div className="volunteering-detail__info-row">
                        <span className="volunteering-detail__info-label">주소지</span>
                        <span className="volunteering-detail__info-value volunteering-detail__info-value--address">
                            {volunteeringData.address || '주소 정보 없음'}
                        </span>
                    </div>
                </div>
            </div>

            {/* 신청명단 버튼 */}
            <div className="volunteering-detail__applicants-container">
                <button onClick={handleApplicantsList} className="volunteering-detail__applicants-button">
                    신청명단 보기
                </button>
            </div>

            {/* 상세 내용 영역 */}
            <div className="volunteering-detail__content">
                <div className="volunteering-detail__content-header">
                    <div className="volunteering-detail__content-header-left">
                        <h3 className="volunteering-detail__content-title">봉사활동 상세 내용</h3>
                    </div>
                    <div className="volunteering-detail__content-header-right">
                        <span className="volunteering-detail__content-date">등록일 : {volunteeringData.createdAt}</span>
                    </div>
                </div>
                <div className="volunteering-detail__content-body">
                    <div
                        className="volunteering-detail__content-html"
                        dangerouslySetInnerHTML={{ __html: volunteeringData.content }}
                    />
                </div>
            </div>

            {/* 신청자 목록 모달 */}
            <ApplicantsModal
                isVisible={showApplicantsModal}
                onClose={closeApplicantsModal}
                volunteeringId={volunteeringData?.volunteeringPk}
                volunteeringTitle={volunteeringData?.title}
                createdDate={volunteeringData?.createdAt}
            />
        </div>
    );
};

export default VolunteeringDetail;