import React from 'react'
import './OneToManyPageBanner.css'

const OneToManyPageBanner = () => {
    return (
        <div className='onetomany-banner-container'>
            <div className='banner-text'>
                <p className='banner-main-text'>1:N 후원이란?</p>
                <p className='banner-explain-text'>장기적으로 치료 또는 수술이 필요한 아이 혹은<br />
                    성격 개선과 교육 프로그램이 필요한 아이에게<br />
                    여러명의 후원자분들이 모여 아이가 안정적으로 치료와<br />
                    교육을 받을 수 있도록 도움을 주는 후원입니다.
                </p>
            </div>
        </div>
    )
}

export default OneToManyPageBanner