import React from "react";
import { NavLink } from "react-router-dom";
import "./AdminNav.css";

const AdminNav = () => {
  return (
    <div className="admin-navi-bar">
      <strong className="admin-navi-title">후원금 관리</strong>
      <ul className="admin-navi-list">
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/day"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            일자별
          </NavLink>
        </li>
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/term"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            기간별
          </NavLink>
        </li>
      </ul>
      <strong className="admin-navi-title">게시판 관리</strong>
      <ul className="admin-navi-list">
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/child"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            동물관리
          </NavLink>
        </li>
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/adoption"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            입양관리
          </NavLink>
        </li>

        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/editaccountinfo"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            후원내역
          </NavLink>
        </li>

        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/announce"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            공지사항
          </NavLink>
        </li>

        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/editaccountinfo"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            센터소식
          </NavLink>
        </li>

        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/editaccountinfo"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            문의하기
          </NavLink>
        </li>
      </ul>
      <strong className="admin-navi-title">참여 관리</strong>
      <ul className="admin-navi-list">
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/talent"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            재능기부
          </NavLink>
        </li>
        <li className="admin-navi-item">
          <NavLink
            to=""
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            입양하기
          </NavLink>
        </li>
        <li className="admin-navi-item">
          <NavLink
            to="/adminpage/volunteering"
            className={({ isActive }) =>
              isActive
                ? "admin-navi-link admin-navi-link--active"
                : "admin-navi-link"
            }
          >
            봉사활동
          </NavLink>
        </li>
      </ul>
      <strong className="admin-navi-title">
        <NavLink to="/adminpage  ">회원 관리</NavLink>
      </strong>
    </div>
  );
};

export default AdminNav;
