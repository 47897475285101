import { useLocation } from 'react-router-dom';
import './OneToManyPaginationComponent.css'
import React from 'react';

function OneToManyPaginationComponent({ currentPage, totalPages, onPageChange }) {
    const location = useLocation();
    // 특정 경로에서만 버튼 표시
    const shouldShowButton = location.pathname.startsWith('/mypage/') || location.pathname.startsWith('/customdonation/');

     // 한 번에 보여줄 페이지 버튼 개수
     const PAGE_GROUP_SIZE = 10;

     // 현재 그룹의 시작 페이지와 끝 페이지 계산
     const startPage = Math.floor((currentPage - 1) / PAGE_GROUP_SIZE) * PAGE_GROUP_SIZE + 1;
     const endPage = Math.min(startPage + PAGE_GROUP_SIZE - 1, totalPages);
    
    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
            if (!shouldShowButton) return window.scrollTo(0, 0);
        }
    };

    return (
        <div className="pagination">
            {/* 이전 그룹 버튼 */}
            {startPage > 1 && (
                <button onClick={() => handlePageClick(startPage - 1)}>
                    <span className="arrow">&lt;&lt;</span>
                </button>
            )}

            {/* 이전 페이지 버튼 */}
            {currentPage > 1 && (
                <button onClick={() => handlePageClick(currentPage - 1)}>
                    <span className='arrow'>&lt;</span>
                </button>
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageClick(startPage + index)}
                    className={currentPage === startPage + index ? 'active' : ''}
                >
                    {startPage + index}
                </button>
            ))}
            {/* 다음 페이지 버튼 */}
            {currentPage < totalPages && (
                <button onClick={() => handlePageClick(currentPage + 1)}>
                    <span className='arrow'>&gt;</span>
                </button>
            )}


            {/* 다음 그룹 버튼 */}
            {endPage < totalPages && (
                <button onClick={() => handlePageClick(endPage + 1)}>
                    <span className="arrow">&gt;&gt;</span>
                </button>
            )}
        </div>
    );
}

export default OneToManyPaginationComponent;
