import "./DonationUseList.css";

const DonationUseListBanner = () => {
  return (
    <div className="donation-uselist-banner-container">
      <div className="donation-uselist-banner-text">
        <p className="donation-uselist-banner-main-text">연간 기부금 모금액 {window.innerWidth <= 768 && <br />} 및 활용 실적</p>
        <p className="donation-uselist-banner-explain-text">후원금 사용내역을 투명하게 공개합니다</p>
      </div>
    </div>
  );
};

export default DonationUseListBanner;
