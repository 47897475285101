import "./Percent.css";
import React from "react";

const DonateGoalImage = () => {
  return (
    <div className="image-wrapper">
      <img className="responsive-image" src="/images/main-images/center-goal.png" alt="포켓멍 센터의 목표" />
    </div>
  );
};

export default DonateGoalImage;
