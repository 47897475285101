import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./AnnounceDetail.css";
import { AnnounceBanner } from "../../components/Announcement";
import axios from "axios";
import DOMPurify from "dompurify";

const AnnounceDetail = () => {
  const [announceItem, setAnnounceItem] = useState(null);
  const navigator = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    const fetchAnnounce = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/notice/detail?noticeid=${id}`
        );
        setAnnounceItem(response.data);
      } catch (error) {
        console.error("공지사항을 가져오는 중 오류 발생:", error);
      }
    };

    fetchAnnounce();
  }, [id]);

  if (!announceItem) {
    return <div>공지사항을 찾을 수 없습니다.</div>;
  }

  const prevPost = announceItem.prevId
    ? {
        id: announceItem.prevId,
        title: announceItem.prevTitle,
        date: announceItem.prevDate,
      }
    : null;

  const nextPost = announceItem.nextId
    ? {
        id: announceItem.nextId,
        title: announceItem.nextTitle,
        date: announceItem.nextDate,
      }
    : null;

  // 삭제
  const handleDelete = async () => {
    try {
      if (window.confirm("삭제하시겠습니까?")) {
        await axios.delete(
          `https://forcatmung.kr/api/notice/delete?noticeid=${id}`
        );
        alert("삭제되었습니다.");
        navigator("/announce/list");
      }
    } catch (error) {
      console.error("삭제 실패하였습니다.", error);
      alert("삭제 중 오류가 발생하였습니다.");
    }
  };
  // 파일 다운로드
  const handleDownload = async (url, filename) => {
    console.log(url);
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data]);
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("파일 다운로드 오류", error);
      alert("파일 다운로드에 실패하였습니다.");
    }
  };

  return (
    <>
      <AnnounceBanner />
      <div className="announce-post-container">
        <div className="announce-post-section">
          <h1 className="announce-primary-title">공지사항</h1>
          <Link to="/announce/list" className="announce-action-button">
            목록으로
          </Link>
        </div>
        {/* <div className="announce-button-div">
          <Link
            to={`/update/announce/${id}`}
            state={{ category: "announce" }}
            className="announce-action-button"
          >
            수정
          </Link>
          <button className="announce-action-button" onClick={handleDelete}>
            삭제
          </button>
        </div> */}
        <div className="announce-title-section">
          <h2 className="announce-post-title">{announceItem.title}</h2>
        </div>

        <div className="announce-meta-section">
          <span className="announce-author">
            <span className="announce-stress-word">작성자 : </span>
            <span className="announce-author-text">{announceItem.author}</span>
          </span>
          <span className="announce-date">
            <span className="announce-stress-word">작성일 : </span>
            <span className="announce-date-text">{announceItem.date}</span>
          </span>
        </div>

        <div
          className="announce-content-section"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(announceItem.content),
          }}
        />
        <div className="announce-attachments-section">
          {announceItem.attach ? (
            <>
              <p className="announce-attachment">
                첨부파일:{" "}
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload(
                      announceItem.attach,
                      announceItem.attachName
                    );
                  }}
                >
                  {announceItem.attachName}
                </a>
              </p>
            </>
          ) : (
            <p className="announce-attachment">첨부파일이 존재하지 않습니다.</p>
          )}
        </div>

        <div className="announce-button-section">
          <Link to="/announce/list" className="announce-action-button">
            목록으로
          </Link>
        </div>

        <table className="announce-navigation-table">
          <tbody>
            <tr>
              <td className="announce-nav-label">
                이전글<span className="announce-triangle up"></span>
              </td>
              <td className="announce-nav-title">
                {prevPost ? (
                  <Link
                    to={`/announce/detail/${prevPost.id}`}
                    style={{ color: "#979494", textDecoration: "none" }}
                  >
                    {prevPost.title}
                  </Link>
                ) : (
                  "이전글 없음"
                )}
              </td>
              <td className="announce-nav-date">
                {prevPost ? prevPost.date : ""}
              </td>
            </tr>
            <tr>
              <td className="announce-nav-label">
                다음글<span className="announce-triangle down"></span>
              </td>
              <td className="announce-nav-title">
                {nextPost ? (
                  <Link
                    to={`/announce/detail/${nextPost.id}`}
                    style={{ color: "#979494", textDecoration: "none" }}
                  >
                    {nextPost.title}
                  </Link>
                ) : (
                  "다음글 없음"
                )}
              </td>
              <td className="announce-nav-date">
                {nextPost ? nextPost.date : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnnounceDetail;
