import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFaqDetailById, updateFaq } from "./faqUpdateApi";

const FaqUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFaqDetail = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getFaqDetailById(id);
        setFormData({ title: data.title, description: data.description });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchFaqDetail();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateFaq(id, formData);
      alert("FAQ가 수정되었습니다.");
      navigate("/faq");
    } catch (error) {
      alert("FAQ 수정 중 오류 발생: " + error.message);
    }
  };

  if (loading) {
    return <p>로딩 중입니다...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="faq-write__section">
      <h2 className="faq-write__h2">FAQ 글 수정하기</h2>
      <form className="faq-write__form" onSubmit={handleSubmit}>
        <label className="faq-write__label" htmlFor="title">
          제목
          <input className="faq-write__input" name="title" id="title" type="text" placeholder="문의 제목을 입력하세요" value={formData.title} onChange={handleChange} required />
        </label>
        <label className="faq-write__label" htmlFor="description">
          내용
          <textarea className="faq-write__textarea" name="description" id="description" placeholder="답 내용을 입력하세요" value={formData.description} onChange={handleChange} required></textarea>
        </label>
        <div className="faq-write__button-container">
          <button className="faq-write__button" type="button" onClick={() => navigate(-1)}>
            취소
          </button>
          <button className="faq-write__button" type="submit">
            올리기
          </button>
        </div>
      </form>
    </section>
  );
};

export default FaqUpdate;
