// export const objectivedummydata = [
//     { id: 1, imageUrl: "https://via.placeholder.com/150?text=Pet+Clothes", title: "반려동물 의류 기부", donorName: "홍길동" },
//     { id: 2, imageUrl: "https://via.placeholder.com/150?text=Dog+Toys", title: "강아지 장난감 기부", donorName: "김철수" },
//     { id: 3, imageUrl: "https://via.placeholder.com/150?text=Cat+Food", title: "고양이 사료 기부", donorName: "이영희" },
//     { id: 4, imageUrl: "https://via.placeholder.com/150?text=Pet+Medical", title: "반려동물 의료 기부", donorName: "박민수" },
//     { id: 5, imageUrl: "https://via.placeholder.com/150?text=Stray+Cat+Care", title: "길고양이 보호 기부", donorName: "정은지" },
//     { id: 6, imageUrl: "https://via.placeholder.com/150?text=Pet+Blankets", title: "반려동물 이불 기부", donorName: "최지혜" },
//     { id: 7, imageUrl: "https://via.placeholder.com/150?text=Pet+Training", title: "반려동물 훈련 기부", donorName: "강민호" },
//     { id: 8, imageUrl: "https://via.placeholder.com/150?text=Dog+Shelter", title: "유기견 보호소 기부", donorName: "조현석" },
//     { id: 9, imageUrl: "https://via.placeholder.com/150?text=Pet+Dishes", title: "반려동물 식기 기부", donorName: "김주현" },
//     { id: 10, imageUrl: "https://via.placeholder.com/150?text=Cat+Tower", title: "고양이 캣타워 기부", donorName: "이상훈" },
//     { id: 11, imageUrl: "https://via.placeholder.com/150?text=Pet+Bath+Items", title: "반려동물 목욕 용품 기부", donorName: "유민석" },
//     { id: 12, imageUrl: "https://via.placeholder.com/150?text=Dog+Training", title: "강아지 훈련 기부", donorName: "최서연" },
//     { id: 13, imageUrl: "https://via.placeholder.com/150?text=Stray+Cat+Food", title: "길고양이 밥 기부", donorName: "박소영" },
//     { id: 14, imageUrl: "https://via.placeholder.com/150?text=Pet+Carrier", title: "반려동물 이동장 기부", donorName: "김수진" },
//     { id: 15, imageUrl: "https://via.placeholder.com/150?text=Pet+Health+Check", title: "반려동물 건강검진 기부", donorName: "정미경" },
//     { id: 16, imageUrl: "https://via.placeholder.com/150?text=Dog+Adoption", title: "유기견 입양 기부", donorName: "이재현" },
//     { id: 17, imageUrl: "https://via.placeholder.com/150?text=Cat+Litter+Box", title: "고양이 화장실 기부", donorName: "홍성준" },
//     { id: 18, imageUrl: "https://via.placeholder.com/150?text=Pet+Toys", title: "반려동물 장난감 기부", donorName: "강서윤" },
//     { id: 19, imageUrl: "https://via.placeholder.com/150?text=Pet+Dewormer", title: "반려동물 구충제 기부", donorName: "김태우" },
//     { id: 20, imageUrl: "https://via.placeholder.com/150?text=Dog+Food", title: "강아지 사료 기부", donorName: "이소라" },
// ];
