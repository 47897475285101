import React, { useState, useEffect } from "react";
import "./DonationUseList.css";
// import { useParams, Link, useNavigate} from 'react-router-dom';
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";

const DonationUseListDetail = () => {
  const [donationUseListItem, setDonationUseListItem] = useState(null);
  const { id } = useParams();
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchDonationUseListItem = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/dntExpense/detail?dntExpenseid=${id}`
        );
        console.log(response.data);
        setDonationUseListItem(response.data);
      } catch (error) {
        console.error("후원내역을 가져오는 중 오류가 발생했습니다:", error);
      }
    };

    fetchDonationUseListItem();
  }, [id]);

  if (!donationUseListItem) {
    return <div>후원내역을 찾을 수 없습니다.</div>;
  }

  const prevPost = donationUseListItem.prevId
    ? {
        id: donationUseListItem.prevId,
        title: donationUseListItem.prevTitle,
        date: donationUseListItem.prevDate,
      }
    : null;

  const nextPost = donationUseListItem.nextId
    ? {
        id: donationUseListItem.nextId,
        title: donationUseListItem.nextTitle,
        date: donationUseListItem.nextDate,
      }
    : null;

  // const handleDelete = async () => {
  //   try {
  //     if (window.confirm("삭제하시겠습니까?")) {
  //       await axios.delete(
  //         `https://forcatmung.kr/api/dntExpense/delete?dntExpenseid=${id}`
  //       );
  //       alert("삭제되었습니다.");
  //       navigate("/uselist/list");
  //     }
  //   } catch (error) {
  //     console.error("삭제 실패하였습니다.", error);
  //     alert("삭제 중 오류가 발생하였습니다.");
  //   }
  // };

  return (
    <div className="donation-uselist-post-container">
      <div className="donation-uselist-post-section">
        <h1 className="donation-uselist-primary-title">후원 내역</h1>
        <Link to="/uselist/list" className="donation-uselist-action-button">
          목록으로
        </Link>
      </div>
      <div className="donation-uselist-button-div">
        {/* 
        <Link
          to={`/update/uselist/${id}`}
          state={{ category: "uselist" }}
          className="donation-uselist-action-button"
        >
          수정
        </Link>
        <button
          className="donation-uselist-action-button"
          onClick={handleDelete}
        >
          삭제
        </button> */}
      </div>
      <div className="donation-uselist-title-section">
        <h2 className="donation-uselist-post-title">
          {donationUseListItem.type === 1 && <span>[후원물품] </span>}
          {donationUseListItem.type === 2 && <span>[후원금사용내역] </span>}
          {donationUseListItem.title}
        </h2>
      </div>

      <div className="donation-uselist-meta-section">
        <span className="donation-uselist-author">
          <span className="donation-uselist-stress-word">작성자 : </span>
          <span className="donation-uselist-author-text">
            {donationUseListItem.author}
          </span>
        </span>
        <span className="donation-uselist-date">
          <span className="donation-uselist-stress-word">작성일 : </span>
          <span className="donation-uselist-date-text">
            {donationUseListItem.date}
          </span>
        </span>
        {donationUseListItem.type === 1 && (
          <span className="donation-uselist-date">
            <span className="donation-uselist-stress-word">후원자명 : </span>
            <span className="donation-uselist-date-text">
              {donationUseListItem.donorName}
            </span>
          </span>
        )}
      </div>

      <div
        className="donation-uselist-content-section"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(donationUseListItem.content),
        }}
      />

      <div className="donation-uselist-attachments-section">
        {donationUseListItem.attach ? (
          <>
            <p className="donation-uselist-attachment">
              첨부파일:{" "}
              <span style={{ fontWeight: "600", color: "#979494" }}>
                {donationUseListItem.attachName}
              </span>
            </p>
          </>
        ) : (
          <p className="donation-uselist-attachment">
            첨부파일이 존재하지 않습니다.
          </p>
        )}
      </div>

      <div className="donation-uselist-button-section">
        <Link to="/uselist/list" className="donation-uselist-action-button">
          목록으로
        </Link>
      </div>

      <table className="donation-uselist-navigation-table">
        <tbody>
          <tr>
            <td className="donation-uselist-nav-label">
              이전글<span className="donation-uselist-triangle up"></span>
            </td>
            <td className="donation-uselist-nav-title">
              {prevPost ? (
                <Link
                  to={`/uselist/list/${prevPost.id}`}
                  style={{ color: "#979494", textDecoration: "none" }}
                >
                  {prevPost.title}
                </Link>
              ) : (
                "이전글 없음"
              )}
            </td>
            <td className="donation-uselist-nav-date">
              {prevPost ? prevPost.date : ""}
            </td>
          </tr>
          <tr>
            <td className="donation-uselist-nav-label">
              다음글<span className="donation-uselist-triangle down"></span>
            </td>
            <td className="donation-uselist-nav-title">
              {nextPost ? (
                <Link
                  to={`/uselist/list/${nextPost.id}`}
                  style={{ color: "#979494", textDecoration: "none" }}
                >
                  {nextPost.title}
                </Link>
              ) : (
                "다음글 없음"
              )}
            </td>
            <td className="donation-uselist-nav-date">
              {nextPost ? nextPost.date : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DonationUseListDetail;
