import React from "react";
import "./Main.css";
import Percent from "../../components/Main/Percent/percent";
import Sliders from "../../components/Main/ImageSlider/ImageSlider";
import DonateGoalImage from "../../components/Main/Percent/DonateGoalImage";
import OneToMany from "../../components/Main/OneToMany/OneToMany";
import AboutIntro from "../../components/AboutPage/AboutIntro";
import TalentComponents from "../../components/Main/Talent/TalentComponents";
import AboutText from "../../components/AboutPage/AboutText";
import Floating from "../../components/Floating/floating";
import { useAuthToken } from "../../hooks/loginHooks";
// import OneToOne from "../../components/Main/OneToOne/OneToOne";

const MainPage = () => {
  //로그인 세션스토리지 추가 훅
  useAuthToken();

  return (
    <div className="md-main" style={{ backgroundColor: "#ffffff" }}>
      <main>
        <Sliders />
        <AboutText />
        <AboutIntro />
        {/* <OneToOne /> */}
        <DonateGoalImage />
        <TalentComponents />
        <OneToMany />
        <Percent />
        <Floating />
      </main>
    </div>
  );
};

export default MainPage;
