import React from 'react';
import "./Question.css";
import QuestionList from '../../components/Question/QuestionList';

const QuestionMainPage = () => {
  return (
    < >
        <QuestionList />
    </>
  );
};
export default QuestionMainPage;
