import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postFaqWrite } from "./faqWriteApi";

const FaqWrite = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ title: "", description: "" });

  const handleCancelBtnClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFaqFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await postFaqWrite({ title: formData.title, description: formData.description });
      alert("FAQ 글이 작성되었습니다!");
      navigate("/faq");
    } catch (error) {
      console.error("FAQ 작성 중 오류:", error);
      alert("FAQ 작성에 실패했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <section className="faq-write__section">
      <h2 className="faq-write__h2">FAQ 글 작성하기</h2>
      <form className="faq-write__form" onSubmit={handleFaqFormSubmit}>
        <label className="faq-write__label" htmlFor="title">
          제목
          <input className="faq-write__input" name="title" id="title" type="text" placeholder="문의 제목을 입력하세요" value={formData.title} onChange={handleChange} required />
        </label>
        <label className="faq-write__label" htmlFor="description">
          내용
          <textarea className="faq-write__textarea" name="description" id="description" placeholder="답 내용을 입력하세요" value={formData.description} onChange={handleChange} required></textarea>
        </label>
        <div className="faq-write__button-container">
          <button className="faq-write__button" type="button" onClick={handleCancelBtnClick}>
            취소
          </button>
          <button className="faq-write__button" type="submit">
            올리기
          </button>
        </div>
      </form>
    </section>
  );
};

export default FaqWrite;
