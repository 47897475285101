import React from 'react';
import "./AdminAniModal.css"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminAniModal = ({ item, onClose }) => {
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
        // API 요청으로 동물 데이터 삭제
        await axios.delete(`https://forcatmung.kr/api/animal/delete?animalid=${item.animalId}`);
        onClose(); // 모달 닫기
    } catch (error) {
        console.error("삭제 중 오류 발생:", error);
    }
};

const handleEditClick = () => {
  navigate(`/mainmain/update/${item.animalId}`); // 수정 페이지로 이동
};

  return (
    <div className="admin-detail-modal" >
      <div className="admin-detail-modal-content" >
        <div className="admin-detail-modal-header">
          <button className="admin-detail-action-button" onClick={handleEditClick}>수정</button>
          <h2 className='admin-detail-title'>{item.name}</h2>
          <button className="admin-detail-action-button" onClick={handleDelete}>삭제</button>
          <button className="admin-detail-close-button" onClick={onClose}>×</button>
        </div>
        <img src={item.imageUrl} alt={item.name} className='admin-detail-image '/>
        
        <div className="admin-detail-animal-info">
          <p><strong>성별:</strong> {item.gender}</p>
          <p><strong>생일:</strong> {item.birthDate}</p>
          <p><strong>체중:</strong> {item.weight} kg</p>
          <p><strong>품종:</strong> {item.breeds}</p>
          <p><strong>중성화 여부:</strong> {item.neutered ? '예' : '아니오'}</p>
          <p><strong>구조일:</strong> {item.rescueDate}</p>
          <p><strong>구조 장소:</strong> {item.rescueLocation}</p>
          <p><strong>건강 특징:</strong> {item.healthFeatures}</p>
          <p><strong>입양 상태:</strong> {item.adoptionStatus}</p>
          <p><strong>성격:</strong> {item.personality}</p>
        </div>


        {item.animalDetailCharc1 && (
          <p><strong>특징 1:</strong> {item.animalDetailCharc1}</p>
        )}
        {item.animalDetailCharc2 && (
          <p><strong>특징 2:</strong> {item.animalDetailCharc2}</p>
        )}
        {item.animalDetailCharc3 && (
          <p><strong>특징 3:</strong> {item.animalDetailCharc3}</p>
        )}
        {item.animalDetailCharc4 && (
          <p><strong>특징 4:</strong> {item.animalDetailCharc4}</p>
        )}
      </div>
    </div>
  );
};

export default AdminAniModal;