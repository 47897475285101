// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/askforhelp_banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ask-for-help-banner {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.ask-for-help-content {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  gap: 15px;
}

.ask-for-help-content {
  left: 50%;
  align-items: center;
  width: 100%;
}

.ask-for-help-title {
  color: white;
  font-size: 2rem;
  font-weight: 600;
}

.ask-for-help-subtitle {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/AskForHelp/AskForHelpBanner.css"],"names":[],"mappings":"AAAA;EACE,yDAAqE;EACrE,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,SAAS;EACT,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".ask-for-help-banner {\n  background-image: url(\"../../../public/images/askforhelp_banner.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  position: relative;\n  width: 100%;\n  height: 400px;\n  overflow: hidden;\n}\n\n.ask-for-help-content {\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  color: white;\n  gap: 15px;\n}\n\n.ask-for-help-content {\n  left: 50%;\n  align-items: center;\n  width: 100%;\n}\n\n.ask-for-help-title {\n  color: white;\n  font-size: 2rem;\n  font-weight: 600;\n}\n\n.ask-for-help-subtitle {\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
