import React, { useState, useEffect } from "react";
import "./QuestionWrite.css"
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import SmartEditor from "../../components/SmartEditor/SmartEditor"

const QuestionWrite = () => {
  const [title, setTitle] = useState("");
  const [nickname, setNickname] = useState("");
  const [content, setContent] = useState("");
  const navigator = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`https://forcatmung.kr/api/inquiry/detail?questionId=${id}`);
        const data = response.data;

        setTitle(data.title);
        setContent(data.questionContent);
        setNickname(data.nickname);
      } catch (error) {
        console.error("데이터 가져오기 실패", error);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title ||!content ) {
      alert("제목과 내용을 모두 입력해주세요");
      return;
    }

  const isConfirmed = window.confirm("수정하시겠습니까?");

  if (isConfirmed) {
    const formData = new FormData();
    const jsonData = { inquryTitle: title, inquryContent: content, userId : 7};

    formData.append('inquiry', new Blob([JSON.stringify(jsonData)], { type: "application/json" }));
    
    try {
      const response = await axios.put(`https://forcatmung.kr/api/inquiry/update?questionid=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("문의하기 수정 성공", response.data);
      window.alert("수정이 완료되었습니다.");
      await navigator("/question/list");
    } catch (error) {
      console.error("작성 실패", error);
    }
  }
}

const handleNavigateList = () => {
  navigator("/question/list")
}

  return (
    <form onSubmit={handleSubmit}>
    <div className="question-write-container">
    <div className="question-inquiry-container">
      <div className="question-inquiry-title">
        <div className="question-inquiry-title-text">
          <h2>문의하기</h2>
        </div>
        <div className="question-button-section">
         <button className="question-list-button" onClick={handleNavigateList}>목록으로</button>
         {/* <button className="question-submit-button" onClick={handleSubmit}>보내기</button> */}
      </div>
      </div>

      <div className="question-title-divider"></div>
      <div className="question-input-section" >
      <div className="question-input-group">
      <span className="question-input-label-first">제목</span>
       <input
         type="text"
       value={title}
       onChange={(e) => setTitle(e.target.value)}
       className="question-input-box title-input"
     />
       </div>
       <div className="question-input-group">
    <span className="question-input-label-second">닉네임</span>
    <input
      type="text"
      value={nickname}
      onChange={(e) => setNickname(e.target.value)}
      className="question-input-box nickname-input"
    />
  </div>
      </div>
      <div >
        <SmartEditor onContentChange={setContent} initialContent={content}/>
      </div>

      <p className="question-notice-text">
        * 관리자가 답장을 하면 문의하기 게시판에 올라옵니다. <br />
        * 욕설 및 부적절한 말은 삼가해주시길 바랍니다.
      </p>
    </div>
    </div>
    </form>
  );
};

export default QuestionWrite;
