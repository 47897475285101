import React, { useEffect, useState } from "react";
import "./AdminTalent.css";
import axios from "axios";

const talentTypes = {
  beauty: "미용",
  design: "디자인",
  trainning: "훈련",
  photography: "촬영",
  others: "기타",
};

const AdminTalent = () => {
  const [selectedCategory, setSelectedCategory] = useState("beauty");
  const [applicants, setApplicants] = useState([]);
  const [join, setJoin] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedJoinName, setSelectedJoinName] = useState("");
  const [applicantDetails, setApplicantDetails] = useState({});
  const [joinDetails, setJoinDetails] = useState({});
  const [totalNum, setTotalNum] = useState("");

  // 신청자 리스트
  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/talent/application/list?talentType=${selectedCategory}`
        );
        console.log("신청자 리스트", response.data);

        const data = response.data;
        setApplicants(data.map((item) => item.userName));

        const details = {};
        data.forEach((item) => {
          details[item.userName] = {
            phoneNum: item.userPhone,
            birthday: item.userBirthday,
            address: item.userAddress,
            specs: item.specs,
          };
        });
        setApplicantDetails(details);
        setSelectedName(data.length > 0 ? data[0].userName : "");
      } catch (e) {
        console.error("신청자 데이터를 불러오는 중 오류 발생", e);
      }
    };

    fetchApplicants();
  }, [selectedCategory]);

  // 참여자 리스트
  useEffect(() => {
    const fetchJoin = async () => {
      try {
        const response = await axios.get(
          `https://forcatmung.kr/api/talent/join/list?talentType=${selectedCategory}`
        );
        const data = response.data.list;
        console.log("참여자 리스트", response.data);
        setJoin(data.map((item) => item.userName));

        const details = {};
        data.forEach((item) => {
          details[item.userName] = {
            userPk: item.userPk,
            phoneNum: item.userPhone,
            birthday: item.userBirthday,
            address: item.userAddress,
            specs: item.specs,
            making: item.making,
          };
        });
        setTotalNum(response.data.totalnum);
        setJoinDetails(details);
        setSelectedJoinName(data.length > 0 ? data[0].userName : "");
      } catch (e) {
        console.error("참여자 데이터를 불러오는 중 오류 발생", e);
      }
    };

    fetchJoin();
  }, [selectedCategory]);

  // 마킹 여부
  const handleMarking = async () => {
    if (!selectedJoinName || !joinDetails[selectedJoinName]?.userPk) {
      alert("유효한 참여자를 선택하세요.");
      return;
    }
    try {
      await axios.put(
        `https://forcatmung.kr/api/talent/join/marking?userpk=${joinDetails[selectedJoinName].userPk}`
      );

      setJoinDetails((prevDetails) => ({
        ...prevDetails,
        [selectedJoinName]: {
          ...prevDetails[selectedJoinName],
          making: !prevDetails[selectedJoinName].making,
        },
      }));
      alert("업데이트 완료!");
    } catch (e) {
      console.error("업데이트 실패:", e);
      alert("업데이트 중 오류가 발생했습니다.");
    }
  };

  // 신청명단 <-> 참가자 명단
  const handleMove = async () => {
    try {
      await axios.put(`https://forcatmung.kr/api/talent/move?userpk=8`);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="admin-talent-container">
      <div className="admin-talent-row">
        {/* 왼쪽 컨테이너 */}
        <div className="admin-talent-box">
          <h2>재능기부 신청명단</h2>
          <div className="admin-talent-header">
            <h3>분야</h3>
            <h3>이름</h3>
            <h3>신청자 정보</h3>
          </div>
          <div className="admin-talent-content">
            <div className="admin-talent-columns">
              <div className="admin-talent-categories">
                {Object.keys(talentTypes).map((category) => (
                  <div
                    key={category}
                    className={`category-item ${
                      selectedCategory === category ? "selected" : ""
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {talentTypes[category]}
                  </div>
                ))}
              </div>
              <div className="admin-talent-names">
                <table>
                  <tbody>
                    {applicants.length > 0 ? (
                      applicants.map((name) => (
                        <tr
                          key={name}
                          className={selectedName === name ? "selected" : ""}
                          onClick={() => setSelectedName(name)}
                        >
                          <td>{name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>신청자가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="admin-talent-info">
                {selectedName && applicantDetails[selectedName] ? (
                  <>
                    <p>
                      <strong>이름:</strong> {selectedName}
                    </p>
                    <p>
                      <strong>전화번호:</strong>{" "}
                      {applicantDetails[selectedName].phoneNum}
                    </p>
                    <p>
                      <strong>생년월일:</strong>{" "}
                      {applicantDetails[selectedName].birthday}
                    </p>
                    <p>
                      <strong>주소:</strong>{" "}
                      {applicantDetails[selectedName].address}
                    </p>
                    <p>
                      <strong>경력사항</strong>{" "}
                    </p>
                    <div className="admin-talent-specs">
                      {applicantDetails[selectedName].specs}
                    </div>
                  </>
                ) : (
                  <p>선택된 신청자가 없습니다.</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 가운데 컨테이너 */}
        <div className="admin-talent-buttons">
          <button onClick={handleMove}>추가 &gt;&gt;</button>
          <button onClick={handleMove}>&lt;&lt; 제외</button>
        </div>

        {/* 오른쪽 컨테이너 */}
        <div className="admin-talent-box">
          <h2>재능기부 참여자</h2>
          <div className="admin-talent-header">
            <h3>이름</h3>
            <h3>참여자 정보</h3>
          </div>
          <div className="admin-talent-content">
            <div className="admin-talent-columns">
              <div className="admin-talent-names">
                <table>
                  <tbody>
                    {join.length > 0 ? (
                      join.map((name) => (
                        <tr
                          key={name}
                          className={
                            selectedJoinName === name ? "selected" : ""
                          }
                          onClick={() => setSelectedJoinName(name)}
                        >
                          <td>{name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>참여자가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="admin-talent-info">
                {selectedJoinName && joinDetails[selectedJoinName] ? (
                  <>
                    <p>
                      <strong>이름:</strong> {selectedJoinName}
                      <input
                        type="checkbox"
                        checked={joinDetails[selectedJoinName]?.making || false}
                        onChange={handleMarking}
                      />
                    </p>
                    <p>
                      <strong>전화번호:</strong>{" "}
                      {joinDetails[selectedJoinName].phoneNum}
                    </p>
                    <p>
                      <strong>생년월일:</strong>{" "}
                      {joinDetails[selectedJoinName].birthday}
                    </p>
                    <p>
                      <strong>주소:</strong>{" "}
                      {joinDetails[selectedJoinName].address}
                    </p>
                    <p>
                      <strong>경력사항</strong>{" "}
                    </p>
                    <div className="admin-talent-specs">
                      {joinDetails[selectedJoinName].specs}
                    </div>
                    <p>
                      <strong>소감한마디 입력창</strong>{" "}
                    </p>
                    <input className="admin-talent-specs" />
                    <p>
                      <strong>사진 등록</strong>
                    </p>
                    <input type="file" />
                  </>
                ) : (
                  <p>선택된 참여자가 없습니다.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-talent-bottom">
        <span>재능기부 총 참여자 수</span>: {totalNum}명
      </div>
    </div>
  );
};

export default AdminTalent;
