import React from 'react';
import './VolunterringRecruitment.css';
import VolunteeringRecruitmentContent from './VolunterringRecruitmentContent';

const VolunterringRecruitment = () => {
  const componentsData = [
    { image: "/images/volunteering-recruitment-content-image1.png", state: "모집 중", kind: "미용", currentPerson: 0, totalPerson: 8, startDate: "2023-01-01", endDate: "2023-01-31" },
    { image: "/images/volunteering-recruitment-content-image2.png", state: "마감", kind: "훈련", currentPerson: 0, totalPerson: 2, startDate: "2023-02-01", endDate: "2023-02-28" },
    { image: "/images/volunteering-recruitment-content-image3.png", state: "예정", kind: "산책", currentPerson: 0, totalPerson: 3, startDate: "2023-02-01", endDate: "2023-02-28" },
    { image: "/images/volunteering-recruitment-content-image4.png", state: "예정", kind: "청소", currentPerson: 0, totalPerson: 2, startDate: "2023-02-01", endDate: "2023-02-28" },
    { image: "/images/volunteering-recruitment-content-image5.png", state: "마감", kind: "이동", currentPerson: 0, totalPerson: 5, startDate: "2023-02-01", endDate: "2023-02-28" },
  ];

  return (
  <div className="volunteering-recruitment-container">
<div className="volunteering-recruitment-blue-section">
  <div className="volunteering-recruitment-grid">
    <div className="volunteering-recruitment-left">
      <h1 className="volunteering-recruitment-large-text">포캣멍센터</h1>
      <h2 className="volunteering-recruitment-medium-text">로켓단 모집</h2>
    </div>
    <div className="volunteering-recruitment-divider"></div>
    <div className="volunteering-recruitment-right">
      <p className="volunteering-recruitment-small-text">동물들의 복지 향상과 보호소의 원활한 운영을 돕는 중요한 역할을 합니다.</p>
      <p className="volunteering-recruitment-small-text">이 봉사단은 동물들에게 필요한 돌봄을 제공하고, 보호소의 환경을 개선하며,</p>
      <p className="volunteering-recruitment-small-text">입양을 촉진하는 등 다양한 활동을 수행합니다.</p>
    </div>
  </div>
</div>
    <div className="volunteering-recruitment-white-section">
      <div className="volunteering-recruitment-content-container">
        {componentsData.map((data, index) => (
          <VolunteeringRecruitmentContent key={index} {...data} />
        ))}
        </div>
    </div>
  </div>
  );
};

export default VolunterringRecruitment;