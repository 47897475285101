import React, { useState, useEffect, useCallback, useRef } from 'react';
import FanClubCard from './FanClubCard';
import './FanClubGrid.css';
import OneToManyPaginationComponent from '../Support/OneToMany/OneToManyPaginationComponent';
import { getDntExpense, searchDntExpense } from './FanClubApi';

const FanClubGrid = () => {
    const [fanClubData, setFanClubData] = useState([]); // 후원 리스트 데이터
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 6;

    const debounceTimer = useRef(null); // 디바운스를 위한 ref

    // 후원 내역 가져오기
    const fetchFanClubData = useCallback(async () => {
        try {
            setLoading(true);
            const data = await getDntExpense(currentPage, pageSize);

            if (data) {
                const { list, totalPages } = data || {};
                setFanClubData(list);
                setTotalPages(totalPages);
            }
        } catch (error) {
            console.error("후원 데이터를 불러오는 중 오류 발생:", error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, pageSize]);

    // 검색된 후원 내역 가져오기
    const fetchSearchResults = useCallback(async (keyword) => {
        try {
            setLoading(true);
            const data = await searchDntExpense(keyword);

            if (data) {
                const { fanclubList, totalPages } = data || {};
                setFanClubData(fanclubList);
                setTotalPages(totalPages);
            }
        } catch (error) {
            console.error("검색 데이터를 불러오는 중 오류 발생:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    // 컴포넌트가 마운트되거나 currentPage가 변경될 때 API 호출
    useEffect(() => {
        if (searchQuery) {
            // 디바운스를 적용하여 일정 시간이 지난 후에 검색 API 호출
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }

            debounceTimer.current = setTimeout(() => {
                fetchSearchResults(searchQuery);
            }, 500);
        } else {
            fetchFanClubData();
        }
    }, [searchQuery, currentPage, fetchSearchResults, fetchFanClubData]);

    // 검색 필터 적용
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // 페이지 변경 핸들러
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="fan-club-grid__container">
            <hr className="fan-club-grid__divide" />
            <div className="fan-club-grid__search-container">
                <div className="fan-club-grid__search">
                    <input
                        type="search"
                        placeholder="스타 또는 팬덤이름을 검색해주세요"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button onClick={() => fetchSearchResults(searchQuery)}>검색</button>
                </div>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div className="fan-club-grid">
                        {fanClubData.length > 0 ? (
                            fanClubData.map((data, index) => {
                                return (
                                    <FanClubCard
                                        key={index}
                                        image={data.imageUrl}
                                        starName={data.star_name}
                                        donationDate={data.donation_date}
                                        message={data.message}
                                    />
                                );
                            })
                        ) : (
                            <p>검색 결과가 없습니다.</p>
                        )}
                    </div>
                    <OneToManyPaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            )}

            <hr className="fan-club-grid__divide" />
        </div>
    );
};

export default FanClubGrid;
