import axios from "axios";

const BASE_URL = "https://forcatmung.kr/";

const postFaqWrite = async ({ title, description }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}api/faq/create`,
      {
        title,
        description,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error posting FAQ:", error);
    throw new Error("FAQ를 올리는데 실패했습니다.");
  }
};

export { postFaqWrite };
